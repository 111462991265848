import React, { useState } from "react";
import { ChromePicker, ColorResult } from "react-color";
import "./BaseInputColor.css";

interface ContainerProps {
  label?: string;
  value: string;
  onValueChange: Function;
  disabled?: boolean;
  placeholder?: string;
}

const BaseInputColor: React.FC<ContainerProps> = (props) => {
  const [color, setColor] = useState<string>(props.value);

  const decimalToHex = (alpha: number | undefined) =>
    alpha === 0 ? "00" : Math.round(255 * alpha!).toString(16);
  const handleColorChange = (color: ColorResult) => {
    const hexCode = `${color.hex}${decimalToHex(color.rgb.a)}`;

    setColor(hexCode);
    props.onValueChange(hexCode);
  };
  return (
    <div className="base-input-color" data-testid="base-input-color">
      {props.label && <label>{props.label + " :"}</label>}
      <div className={"base-input-color-flex"}>
        <ChromePicker
          color={color || props.value}
          onChange={handleColorChange}
        />
      </div>
    </div>
  );
};

export default BaseInputColor;
