import classNames from "classnames";
import React from "react";
import "./BaseLabel.css";

interface ContainerProps {
  small?: boolean;
}

const BaseLabel: React.FC<ContainerProps> = (props) => {
  return (
    <label
      className={classNames({
        "base-label": true,
        "base-label--small": props.small,
      })}
      data-testid="base-label"
    >
      {props.children}
    </label>
  );
};

export default BaseLabel;
