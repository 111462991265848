import { useHistory } from "react-router-dom";
import Show from "../../../models/Show";
import ShowCardCalendar from "../ShowCardCalendar/ShowCardCalendar";
import ShowCardInfos from "../ShowCardInfos/ShowCardInfos";
import showCoverPlaceholder from "../../../assets/images/show-placeholder.png";
import "./ShowCard.css";
import {useContext} from "react";
import AppContext from "../../../contexts/app.ctx";
import classNames from "classnames";
import API from "../../../services";

interface ContainerProps {
  show: Show;
}

const ShowCard: React.FC<ContainerProps> = (props) => {
  const history = useHistory();
  const appCtx = useContext(AppContext);

  const showCopy = async () => {
    const copy = {...props.show}
    copy.name = props.show.name.includes("[COPY]") ? props.show.name : "[COPY] " + props.show.name
    API.duplicateShow(copy)
      .then((res) => {
        appCtx.toggleNotification({
          type: "success",
          content: "L'événement a été copié !"
        });
      })
      .catch((e) => console.log(e));
  }

  return (
    <div
      className="show-card"
      data-testid="show-card"
      style={{
        backgroundImage: `url(${
          props.show.coverUrl ? props.show.coverUrl : showCoverPlaceholder
        })`,
      }}
      onClick={() => {
        appCtx.loadCurrentShow(props.show.id);
        history.push(`/${props.show.id}/edit-show-infos`);
      }}
    >
      <div className="show-card__content">
        <div className="show-card__content__cal-container">
          <div className={"show-card__content__tag-container"}>
            <div
              className={classNames({
                "show-card__content__cal-container__publish-info": true,
                "show-card__content__cal-container__publish-info--published":
                  props.show.isPublished,
              })}
            >
              {props.show.isPublished ? "Publié" : "Non publié"}
            </div>
            {props.show.multiplexId && (
              <div
                className={"show-card__content__multiplex-info"}
                onClick={(event) => {
                  event.stopPropagation()
                  history.push(`/${props.show.multiplexId}/edit-multiplex-infos`)
                }}
              >
                Multiplex
              </div>
            )}
            <div
              className={"show-card__content__duplicate"}
              onClick={(event) => {
                event.stopPropagation()
                showCopy()
              }}
            >
              Dupliquer
            </div>
          </div>
          <ShowCardCalendar date={props.show.startDate} />
        </div>
        <div className="show-card_content_infos-container">
          <ShowCardInfos show={props.show} />
        </div>
      </div>
    </div>
  );
};

export default ShowCard;
