import "./PageContent.css";

interface ContainerProps {
  className?: string;
}

const PageContent: React.FC<ContainerProps> = (props) => {
  const getClassNames = () => {
    const composite = ["page-content"];
    if (props.className) {
      composite.push(props.className);
    }
    return composite.join(" ");
  };

  return (
    <section className={getClassNames()} data-testid="page-content">
      {props.children}
    </section>
  );
};

export default PageContent;
