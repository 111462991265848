import classNames from "classnames";
import React, { useContext } from "react";
import BaseCard from "../../Base/BaseCard/BaseCard";
import BaseLabel from "../../Base/BaseLabel/BaseLabel";
import "./LiveCurrentProjectSelector.css";
import API from "../../../services";
import AppContext from "../../../contexts/app.ctx";
import BaseIcon from "../../Base/BaseIcon/BaseIcon";
import { faLink } from "@fortawesome/free-solid-svg-icons";
//import buildEnv from "../../../build.env.json";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import ClientUrlConfig from "../../../configs/dons-live-client.config";

interface ContainerProps {
  gridId: string;
}

const LiveCurrentProjectSelector: React.FC<ContainerProps> = (props) => {
  const appCtx = useContext(AppContext);
  const baseUrl = ClientUrlConfig();

  const setCurrentProject = (projectId: string) => {
    let newProjectId = "";
    if (projectId !== appCtx.currentShow.currentProjectId) {
      newProjectId = projectId;
    }
    API.changeCurrentProjectId(appCtx.currentShow.id, newProjectId);
  };

  return (
    <BaseCard
      id={props.gridId}
      className={classNames({
        "live-current-project-selector": true,
        "live-current-project-selector--no-projects":
          appCtx.currentShow.hasNoProjects
      })}
      data-testid="live-current-project-selector"
      tight
    >
      <BaseLabel>Projet en cours</BaseLabel>
      <div className="live-current-project-selector__list">
        {appCtx.currentShowProjects.map((project, index) => (
          <div
            key={project.id}
            className={classNames({
              "live-current-project-selector__list__item": true,
              "live-current-project-selector__list__item--active":
                project.id === appCtx.currentShow.currentProjectId
            })}
            onClick={() => setCurrentProject(project.id)}
          >
            <a
              className="live-current-project-selector__list__item__logo"
              style={{
                backgroundImage: `url(${project.logoUrl})`
              }}
              onClick={(event) => {
                event.stopPropagation();
              }}
              href={
                `${baseUrl}${appCtx.currentShow.urlSlug}/counter?pos=${project.position}`
              }
              target="_blank"
              rel="noreferrer"
            >
              <div className="live-current-project-selector__list__item__logo__counter-link">
                <BaseIcon icon={faLink as IconProp} color="#848aae" />
              </div>
            </a>
            <div className="live-current-project-selector__list__item__infos">
              <div className="live-current-project-selector__list__item__infos__name">
                {project.name}
              </div>
              <div className="live-current-project-selector__list__item__infos__goal">
                <span
                  className={classNames({
                    "live-current-project-selector__list__item__infos__goal--reached":
                      project.totalPledgesAmount >= project.goal
                  })}
                >
                  {(project.totalPledgesAmount / 100).toLocaleString(
                    undefined,
                    { maximumFractionDigits: 0 }
                  ) +
                    " " +
                    appCtx.currentShowCurrencySymbol}
                </span>
                {" / "}
                <span>
                  {(project.goal / 100).toLocaleString(undefined, {
                    maximumFractionDigits: 0
                  }) +
                    " " +
                    appCtx.currentShowCurrencySymbol}
                </span>
              </div>
            </div>
          </div>
        ))}
      </div>
    </BaseCard>
  );
};

export default LiveCurrentProjectSelector;
