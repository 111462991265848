import { IconProp } from "@fortawesome/fontawesome-svg-core";
import {faFileImage, faTrash} from "@fortawesome/free-solid-svg-icons";
import classNames from "classnames";
import React, { useRef, useState } from "react";
import BaseIcon from "../BaseIcon/BaseIcon";
import "./BaseImageInput.css";

interface ContainerProps {
  presetValue: string;
  value: File;
  onImageSelected: Function;
  onImageDelete?: Function;
}

const BaseImageInput: React.FC<ContainerProps> = (props) => {
  const [isDragOver, setIsDragOver] = useState(false);
  const hiddenInputRef = useRef<HTMLInputElement>(null);

  const getCurrentDisplayImageUrl = () => {
    if (!props.value.size && props.presetValue) {
      return props.presetValue;
    } else if (props.value.size) {
      return URL.createObjectURL(props.value);
    }
    return "";
  };

  return (
    <div className="base-image-input" data-testid="base-image-input">
      <input
        className="base-image-input__hidden-input"
        type="file"
        ref={hiddenInputRef}
        onChange={(event) => {
          if (event?.target?.files?.length) {
            props.onImageSelected(event.target.files[0]);
          } else {
            return;
          }
        }}
      />
      <div
        className="base-image-input__visualizer"
        style={{ backgroundImage: `url(${getCurrentDisplayImageUrl()})` }}
      ></div>
      <div
        className={classNames({
          "base-image-input__input-container": true,
          "base-image-input__input-container--drag-over": isDragOver
        })}
        onDrop={(event) => {
          event.preventDefault();
          props.onImageSelected(event.dataTransfer.files[0]);
          setIsDragOver(false);
        }}
        onDragOver={(event) => {
          event.preventDefault();
          setIsDragOver(true);
        }}
        onDragLeave={(event) => {
          event.preventDefault();
          setIsDragOver(false);
        }}
      >
        <BaseIcon icon={faFileImage as IconProp} size="32px" />
        <span className="base-image-input__input-container__text">
          <span
            className="base-image-input__input-container__text__action"
            onClick={() => hiddenInputRef.current?.click()}
          >
            Charger une image
          </span>{" "}
          ou déposez la ici
        </span>
      </div>

      {props.onImageDelete && (
        <div className="base-image-input__delete-container"
             onClick={() => {
               props.onImageDelete!()
             }}
        >
          <BaseIcon icon={faTrash as IconProp} size="32px" />
          <span className="base-image-input__delete-container__text">
            Supprimer l'image
        </span>
        </div>
      )}
    </div>
  );
};

export default BaseImageInput;
