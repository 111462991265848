import React, { useContext, useEffect, useState } from "react";
import Show from "../models/Show";
import API from "../services";
import AppContext from "./app.ctx";

const EditShowMediasContext = React.createContext({
  editedShow: {} as Show,
  isLoading: false,
  resetContext: () => {},
  existingCoverUrl: "",
  existingCoverUrlSetter: (value: string) => {},
  existingLogoUrl: "",
  existingLogoUrlSetter: (value: string) => {},
  pendingCoverFile: new File([], ""),
  pendingCoverFileInputHandler: (file: File) => {},
  pendingLogoFile: new File([], ""),
  pendingLogoFileInputHandler: (file: File) => {},
  isStreamingEnabled: false,
  isStreamingEnabledInputHandler: (value: boolean) => {},
  youtubeStreamId: "",
  youtubeStreamIdInputHandler: (value: string) => {},
  submitUpdate: () => {},
});

export const EditShowMediasContextProvider: React.FC = (props) => {
  const [editedShow, setEditedShow] = useState({} as Show);
  const [isLoading, setIsLoading] = useState(false);
  const [existingCoverUrl, setExistingCoverUrl] = useState("");
  const [existingLogoUrl, setExistingLogoUrl] = useState("");
  const [pendingCoverFile, setPendingCoverFile] = useState<File>(
    new File([], "")
  );
  const [pendingLogoFile, setPendingLogoFile] = useState<File>(
    new File([], "")
  );
  const [isStreamingEnabled, setIsStreamingEnabled] = useState(false);
  const [youtubeStreamId, setYoutubeStreamId] = useState("");

  const appCtx = useContext(AppContext);

  const resetContext = () => {
    setEditedShow({} as Show);
    setIsLoading(false);
    setExistingCoverUrl("");
    setExistingLogoUrl("");
    setPendingCoverFile(new File([], ""));
    setPendingLogoFile(new File([], ""));
    setIsStreamingEnabled(false);
    setYoutubeStreamId("");
  };

  useEffect(() => {
    if (appCtx.currentShow.id) {
      setEditedShow(appCtx.currentShow);
      setIsLoading(false);
      setExistingCoverUrl(appCtx.currentShow.coverUrl);
      setExistingLogoUrl(appCtx.currentShow.logoUrl);
      setPendingCoverFile(new File([], ""));
      setPendingLogoFile(new File([], ""));
      setIsStreamingEnabled(appCtx.currentShow.isStreamingEnabled);
      setYoutubeStreamId(appCtx.currentShow.youtubeStreamId);
    }
  }, [appCtx.currentShow]);

  const existingCoverUrlSetter = (value: string) => {
    setExistingCoverUrl(value);
  };

  const existingLogoUrlSetter = (value: string) => {
    setExistingLogoUrl(value);
  };

  const pendingCoverFileInputHandler = (file: File) => {
    setPendingCoverFile(file);
  };

  const pendingLogoFileInputHandler = (file: File) => {
    setPendingLogoFile(file);
  };

  const isStreamingEnabledInputHandler = (value: boolean) => {
    setIsStreamingEnabled(value);
  };

  const youtubeStreamIdInputHandler = (value: string) => {
    setYoutubeStreamId(value);
  };

  const submitUpdate = () => {
    setIsLoading(true);
    API.updateShowMedias(
      editedShow.id,
      existingCoverUrl,
      existingLogoUrl,
      pendingCoverFile,
      pendingLogoFile,
      isStreamingEnabled,
      youtubeStreamId
    )
      .then((status) => {
        setIsLoading(false);
        appCtx.toggleNotification({
          type: "success",
          content: "Mise à jour de l'événement réussie.",
        });
      })
      .catch((e) => {
        setIsLoading(false);
        appCtx.toggleNotification({
          type: "error",
          content: "Une erreur est survenue.",
        });
      });
  };

  return (
    <EditShowMediasContext.Provider
      value={{
        editedShow,
        isLoading,
        resetContext,
        existingCoverUrl,
        existingCoverUrlSetter,
        existingLogoUrl,
        existingLogoUrlSetter,
        pendingCoverFile,
        pendingCoverFileInputHandler,
        pendingLogoFile,
        pendingLogoFileInputHandler,
        isStreamingEnabled,
        isStreamingEnabledInputHandler,
        youtubeStreamId,
        youtubeStreamIdInputHandler,
        submitUpdate,
      }}
    >
      {props.children}
    </EditShowMediasContext.Provider>
  );
};
export default EditShowMediasContext;
