import { IconProp } from "@fortawesome/fontawesome-svg-core";
import classNames from "classnames";
import React from "react";
import BaseIcon from "../../Base/BaseIcon/BaseIcon";
import "./MenuItem.css";

interface ContainerProps {
  icon: IconProp;
  text: string;
  onClick: Function;
  solid?: boolean;
  topSeparator?: boolean;
  condensed?: boolean;
  color?: string;
  muted?: boolean;
}

const MenuItem: React.FC<ContainerProps> = (props) => {
  return (
    <div
      onClick={() => props.onClick()}
      className={classNames({
        "menu-item": true,
        "menu-item--top-separator": props.topSeparator,
        "menu-item--muted": props.muted,
        "menu-item__condensed": props.condensed,
        "menu-item__condensed--solid": props.solid,
      })}
      data-testid="menu-item"
    >
      <div
        className="menu-item__icon"
        style={{
          borderColor: props.color,
          backgroundColor: props.solid ? props.color : undefined,
        }}
      >
        <BaseIcon
          icon={props.icon}
          color={props.solid ? "white" : props.color || "white"}
          size={props.condensed ? "14px" : "16px"}
        />
      </div>
      <div className="menu-item__text">{props.text}</div>
    </div>
  );
};

export default MenuItem;
