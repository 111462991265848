import React, { useContext } from "react";
import EditShowInfosContext from "../../../contexts/edit-show-infos.ctx";
import BaseCard from "../../Base/BaseCard/BaseCard";
import BaseLabel from "../../Base/BaseLabel/BaseLabel";
import BaseToggle from "../../Base/BaseToggle/BaseToggle";
import "./OnPlaceSelector.css";

interface ContainerProps {
  gridId: string;
}

const OnPlaceSelector: React.FC<ContainerProps> = (props) => {
  const editShowInfosCtx = useContext(EditShowInfosContext);

  return (
    <BaseCard id={props.gridId} className="on-place-selector">
      <BaseLabel>Accessible sur place</BaseLabel>
      <BaseToggle
        value={editShowInfosCtx.isOnPlace}
        onClick={() =>
          editShowInfosCtx.isOnPlaceInputHandler(!editShowInfosCtx.isOnPlace)
        }
      />
    </BaseCard>
  );
};

export default OnPlaceSelector;
