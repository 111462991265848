import { faFileCsv } from "@fortawesome/free-solid-svg-icons";
import classNames from "classnames";
import React, { useRef, useState } from "react";
import BaseIcon from "../BaseIcon/BaseIcon";
import Papa from "papaparse";
import "./BaseCsvLoader.css";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

interface ContainerProps {
  onCsvLoaded: Function;
}

const BaseCsvLoader: React.FC<ContainerProps> = (props) => {
  const [isDragOver, setIsDragOver] = useState(false);
  const hiddenInputRef = useRef<HTMLInputElement>(null);

  const onCsvSelected = (inputFile: File) => {
    Papa.parse(inputFile, {
      delimiter: ";",
      complete: (result) => {
        if (result.errors.length) {
          return;
        } else {
          props.onCsvLoaded(result.data);
        }
      },
    });
  };

  return (
    <div className="base-csv-loader" data-testid="base-csv-loader">
      <input
        className="base-csv-loader__hidden-input"
        type="file"
        ref={hiddenInputRef}
        onChange={(event) => {
          if (event?.target?.files?.length) {
            onCsvSelected(event.target.files[0]);
          } else {
            return;
          }
        }}
      />
      <div
        className={classNames({
          "base-csv-loader__input-container": true,
          "base-csv-loader__input-container--drag-over": isDragOver
        })}
        onDrop={(event) => {
          event.preventDefault();
          onCsvSelected(event.dataTransfer.files[0]);
          setIsDragOver(false);
        }}
        onDragOver={(event) => {
          event.preventDefault();
          setIsDragOver(true);
        }}
        onDragLeave={(event) => {
          event.preventDefault();
          setIsDragOver(false);
        }}
      >
        <BaseIcon icon={faFileCsv as IconProp} size="32px" />
        <span className="base-csv-loader__input-container__text">
          <span
            className="base-csv-loader__input-container__text__action"
            onClick={() => hiddenInputRef.current?.click()}
          >
            Charger un fichier
          </span>{" "}
          ou déposez le ici
        </span>
      </div>
    </div>
  );
};

export default BaseCsvLoader;
