import React, { useContext, useEffect, useState } from "react";
import Show from "../models/Show";
import API from "../services";
import AppContext from "./app.ctx";

export type CustomUserPledgeAmounts = {
  email: string;
  firstAmount: number;
  secondAmount: number;
  thirdAmount: number;
  fourthAmount: number;
  freeAmount: boolean;
};

const EditShowPledgesContext = React.createContext({
  editedShow: {} as Show,
  isLoading: false,
  resetContext: () => {},
  hasPledgesPresets: true,
  hasPledgesPresetsInputHandler: (value: boolean) => {},
  firstPledgeAmount: "",
  firstPledgeAmountInputHandler: (value: string) => {},
  secondPledgeAmount: "",
  secondPledgeAmountInputHandler: (value: string) => {},
  thirdPledgeAmount: "",
  thirdPledgeAmountInputHandler: (value: string) => {},
  fourthPledgeAmount: "",
  fourthPledgeAmountInputHandler: (value: string) => {},
  pledgesCurrency: "",
  pledgesCurrencyInputHandler: (value: string) => {},
  hasFreePledgeAmount: false,
  hasFreePledgeAmountInputHandler: (value: boolean) => {},
  hasTaxReceiptAvailable: false,
  hasTaxReceiptAvailableInputHandler: (value: boolean) => {},
  hasCustomPledgeAmounts: false,
  hasCustomPledgeAmountsInputHandler: (value: boolean) => {},
  customPledgeAmountsList: [] as CustomUserPledgeAmounts[],
  customPledgeAmountsListInputHandler: (value: CustomUserPledgeAmounts[]) => {},
  submitUpdate: () => {},
});

export const EditShowPledgesContextProvider: React.FC = (props) => {
  const [editedShow, setEditedShow] = useState({} as Show);
  const [isLoading, setIsLoading] = useState(false);
  const [hasPledgesPresets, setHasPledgesPresets] = useState(true);
  const [firstPledgeAmount, setFirstPledgeAmount] = useState("");
  const [secondPledgeAmount, setSecondPledgeAmount] = useState("");
  const [thirdPledgeAmount, setThirdPledgeAmount] = useState("");
  const [fourthPledgeAmount, setFourthPledgeAmount] = useState("");
  const [pledgesCurrency, setPledgesCurrency] = useState("EUR");
  const [hasFreePledgeAmount, setHasFreePledgeAmount] = useState(false);
  const [hasTaxReceiptAvailable, setHasTaxReceiptAvailable] = useState(false);
  const [hasCustomPledgeAmounts, setHasCustomPledgeAmounts] = useState(false);
  const [customPledgeAmountsList, setCustomPledgeAmountsList] = useState(
    [] as CustomUserPledgeAmounts[]
  );

  const appCtx = useContext(AppContext);

  const resetContext = () => {
    setEditedShow({} as Show);
    setIsLoading(false);
    setHasPledgesPresets(true);
    setFirstPledgeAmount("");
    setSecondPledgeAmount("");
    setThirdPledgeAmount("");
    setFourthPledgeAmount("");
    setPledgesCurrency("");
    setHasFreePledgeAmount(false);
    setHasTaxReceiptAvailable(false);
    setHasCustomPledgeAmounts(false);
    setCustomPledgeAmountsList([]);
  };

  useEffect(() => {
    if (appCtx.currentShow.id) {
      setEditedShow(appCtx.currentShow);
      setIsLoading(false);
      setHasPledgesPresets(appCtx.currentShow.hasPledgesPresets);
      setFirstPledgeAmount(
        (appCtx.currentShow.firstPledgeAmount / 100).toString()
      );
      setSecondPledgeAmount(
        (appCtx.currentShow.secondPledgeAmount / 100).toString()
      );
      setThirdPledgeAmount(
        (appCtx.currentShow.thirdPledgeAmount / 100).toString()
      );
      setFourthPledgeAmount(
        (appCtx.currentShow.fourthPledgeAmount / 100).toString()
      );
      setPledgesCurrency(appCtx.currentShow.pledgesCurrency);
      setHasFreePledgeAmount(appCtx.currentShow.hasFreePledgeAmount);
      setHasTaxReceiptAvailable(appCtx.currentShow.hasTaxReceiptAvailable);
      setHasCustomPledgeAmounts(appCtx.currentShow.hasCustomPledgeAmounts);
      setCustomPledgeAmountsList([]);
    }
  }, [appCtx.currentShow]);

  const hasPledgesPresetsInputHandler = (value: boolean) => {
    setHasPledgesPresets(value);
  };
  const firstPledgeAmountInputHandler = (value: string) => {
    setFirstPledgeAmount(value);
  };
  const secondPledgeAmountInputHandler = (value: string) => {
    setSecondPledgeAmount(value);
  };
  const thirdPledgeAmountInputHandler = (value: string) => {
    setThirdPledgeAmount(value);
  };
  const fourthPledgeAmountInputHandler = (value: string) => {
    setFourthPledgeAmount(value);
  };
  const pledgesCurrencyInputHandler = (value: string) => {
    setPledgesCurrency(value);
  };
  const hasFreePledgeAmountInputHandler = (value: boolean) => {
    setHasFreePledgeAmount(value);
  };
  const hasTaxReceiptAvailableInputHandler = (value: boolean) => {
    setHasTaxReceiptAvailable(value);
  };
  const hasCustomPledgeAmountsInputHandler = (value: boolean) => {
    setHasCustomPledgeAmounts(value);
  };
  const customPledgeAmountsListInputHandler = (
    value: CustomUserPledgeAmounts[]
  ) => {
    setCustomPledgeAmountsList(value);
  };

  const submitUpdate = () => {
    setIsLoading(true);
    API.updateShowPledgesConfig(
      editedShow.id,
      hasPledgesPresets,
      firstPledgeAmount,
      secondPledgeAmount,
      thirdPledgeAmount,
      fourthPledgeAmount,
      pledgesCurrency,
      hasFreePledgeAmount,
      hasTaxReceiptAvailable,
      hasCustomPledgeAmounts,
      customPledgeAmountsList,
      customPledgeAmountsList.length !== 0
        ? customPledgeAmountsList.length
        : editedShow.customPledgeAmountsCount
    )
      .then((status) => {
        setIsLoading(false);
        appCtx.toggleNotification({
          type: "success",
          content: "Mise à jour de l'événement réussie.",
        });
      })
      .catch((e) => {
        setIsLoading(false);
        appCtx.toggleNotification({
          type: "error",
          content: "Une erreur est survenue.",
        });
      });
  };

  return (
    <EditShowPledgesContext.Provider
      value={{
        editedShow,
        isLoading,
        resetContext,
        hasPledgesPresets,
        hasPledgesPresetsInputHandler,
        firstPledgeAmount,
        firstPledgeAmountInputHandler,
        secondPledgeAmount,
        secondPledgeAmountInputHandler,
        thirdPledgeAmount,
        thirdPledgeAmountInputHandler,
        fourthPledgeAmount,
        fourthPledgeAmountInputHandler,
        pledgesCurrency,
        pledgesCurrencyInputHandler,
        hasFreePledgeAmount,
        hasFreePledgeAmountInputHandler,
        hasTaxReceiptAvailable,
        hasTaxReceiptAvailableInputHandler,
        hasCustomPledgeAmounts,
        hasCustomPledgeAmountsInputHandler,
        customPledgeAmountsList,
        customPledgeAmountsListInputHandler,
        submitUpdate,
      }}
    >
      {props.children}
    </EditShowPledgesContext.Provider>
  );
};
export default EditShowPledgesContext;
