import { faSave } from "@fortawesome/free-solid-svg-icons";
import React, { useContext, useState } from "react";
import BaseCard from "../../Base/BaseCard/BaseCard";
import BaseInput from "../../Base/BaseInput/BaseInput";
import PageContent from "../../Layout/PageContent/PageContent";
import PageHeader from "../../Layout/PageHeader/PageHeader";
import API from "../../../services";
import "./NewShowPage.css";
import AppContext from "../../../contexts/app.ctx";
import { useHistory } from "react-router";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

interface ContainerProps {}

export const newShowPageRoute = "/new-show";

const NewShowPage: React.FC<ContainerProps> = (props) => {
  const [newShowName, setNewShowName] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const appCtx = useContext(AppContext);

  const history = useHistory();

  return (
    <div className="new-show-page" data-testid="new-show-page">
      <PageHeader
        title="Créer un événement"
        actions={[
          {
            name: "Créer",
            action: () => {
              setIsLoading(true);
              API.createShow(newShowName)
                .then((res) => {
                  setIsLoading(false);
                  appCtx.toggleNotification({
                    type: "success",
                    content: "L'événement a été créé !"
                  });
                  history.push(`/${res.id}/edit-show-infos`);
                })
                .catch((e) => console.log(e));
            },
            color: "#2CA795",
            icon: faSave as IconProp,
            isLoading: isLoading
          }
        ]}
      />
      <PageContent>
        <div className="new-show-page__content">
          <BaseCard>
            <div className="new-show-page__content__input">
              <BaseInput
                label="Nom de l'événement"
                value={newShowName}
                onValueChange={(value: string) => setNewShowName(value)}
              />
            </div>
          </BaseCard>
        </div>
      </PageContent>
    </div>
  );
};

export default NewShowPage;
