import "./Menu.css";
import BaseLogo from "../../Base/BaseLogo/BaseLogo";
import MenuItem from "../MenuItem/MenuItem";
import { useContext, useState } from "react";
import classNames from "classnames";
import {
  faArrowLeft,
  faBars,
  faBroadcastTower,
  faEllipsisH,
  faHandHoldingUsd,
  faIdCard,
  faInfo,
  faList, faPercent,
  faPhotoVideo,
  faPlus,
  faProjectDiagram,
  faSignOutAlt,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import BaseIcon from "../../../components/Base/BaseIcon/BaseIcon";
import { useHistory, useLocation } from "react-router-dom";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import AuthContext from "../../../contexts/auth.ctx";

interface ContainerProps {}

const Menu: React.FC<ContainerProps> = (props) => {
  const authCtx = useContext(AuthContext);

  type MenusConfigurationType = Record<
    string,
    {
      name: string;
      icon: IconProp;
      action: Function;
      color: string;
      solid: boolean;
      topSeparator: boolean;
      routeActivation: string;
    }[]
  >;

  const [mobileMenuIsOn, setMobileMenuIsOn] = useState(false);
  const location = useLocation();
  const history = useHistory();

  const currentMenu = () => {
    const urlParts = location.pathname.split("/")
    const currentpath = urlParts.pop() || ""

    switch (currentpath) {
      case "":
        return "ROOT";
      case "new-show":
        return "CREATE_SHOW";
      case "multiplexes":
        return "ROOT";
      case "new-multiplex":
        return "CREATE_MULTIPLEX";
      default:
        if (currentpath.includes("edit-show")) return "EDIT_SHOW"
        if (currentpath.includes("manager")) return "EDIT_SHOW"
        return "EDIT_MULTIPLEX";
    }
  };

  const itemIsMuted = (routeActivation: string) => {
    if (
      location.pathname.split("/")[location.pathname.split("/").length - 1] ===
      routeActivation
    ) {
      return false;
    } else {
      return true;
    }
  };

  const menuNavigationHandler = (target: string) => {
    setMobileMenuIsOn(false);
    history.push(target);
  };

  const menusConfiguration: MenusConfigurationType = {
    ROOT: [
      {
        name: "Mes événements",
        icon: faList as IconProp,
        action: () => menuNavigationHandler("/"),
        color: "white",
        solid: false,
        topSeparator: false,
        routeActivation: ""
      },
      {
        name: "Créer un événement",
        icon: faPlus as IconProp,
        action: () => menuNavigationHandler("/new-show"),
        color: "white",
        solid: false,
        topSeparator: false,
        routeActivation: "new-show"
      },
      {
        name: "Multiplexes",
        icon: faList as IconProp,
        action: () => menuNavigationHandler("/multiplexes"),
        color: "white",
        solid: false,
        topSeparator: true,
        routeActivation: "multiplexes"
      },
      {
        name: "Créer un multiplex",
        icon: faPlus as IconProp,
        action: () => menuNavigationHandler("/new-multiplex"),
        color: "white",
        solid: false,
        topSeparator: false,
        routeActivation: "new-multiplex"
      }
    ],
    CREATE_SHOW: [
      {
        name: "Annuler la création",
        icon: faArrowLeft as IconProp,
        action: () => menuNavigationHandler("/"),
        color: "#848AAE",
        solid: true,
        topSeparator: false,
        routeActivation: ""
      }
    ],
    CREATE_MULTIPLEX: [
      {
        name: "Annuler la création",
        icon: faArrowLeft as IconProp,
        action: () => menuNavigationHandler("/multiplexes"),
        color: "#848AAE",
        solid: true,
        topSeparator: false,
        routeActivation: ""
      }
    ],
    EDIT_SHOW: [
      {
        name: "Revenir à la liste",
        icon: faArrowLeft as IconProp,
        action: () => menuNavigationHandler("/"),
        color: "#848AAE",
        solid: true,
        topSeparator: false,
        routeActivation: ""
      },
      {
        name: "Informations de l'événement",
        icon: faInfo as IconProp,
        action: () => menuNavigationHandler("edit-show-infos"),
        color: "white",
        solid: false,
        topSeparator: false,
        routeActivation: "edit-show-infos"
      },
      {
        name: "Médias de l'événement",
        icon: faPhotoVideo as IconProp,
        action: () => menuNavigationHandler("edit-show-medias"),
        color: "white",
        solid: false,
        topSeparator: false,
        routeActivation: "edit-show-medias"
      },
      {
        name: "Configration des promesses",
        icon: faHandHoldingUsd as IconProp,
        action: () => menuNavigationHandler("edit-show-pledges"),
        color: "white",
        solid: false,
        topSeparator: false,
        routeActivation: "edit-show-pledges"
      },
      {
        name: "Projets de l'événement",
        icon: faProjectDiagram as IconProp,
        action: () => menuNavigationHandler("edit-show-projects"),
        color: "white",
        solid: false,
        topSeparator: false,
        routeActivation: "edit-show-projects"
      },
      {
        name: "Informations d'inscription",
        icon: faIdCard as IconProp,
        action: () => menuNavigationHandler("edit-show-registrations"),
        color: "white",
        solid: false,
        topSeparator: false,
        routeActivation: "edit-show-registrations"
      },
      {
        name: "Régie Live",
        icon: faBroadcastTower as IconProp,
        action: () => menuNavigationHandler("live-manager"),
        color: "#2CA795",
        solid: false,
        topSeparator: true,
        routeActivation: "live-manager"
      },
      {
        name: "Régie Multiplex",
        icon: faBroadcastTower as IconProp,
        action: () => menuNavigationHandler("multiplex-manager"),
        color: "#aa98ff",
        solid: false,
        topSeparator: true,
        routeActivation: "multiplex-manager"
      }
    ],
    EDIT_MULTIPLEX: [
      {
        name: "Revenir à la liste",
        icon: faArrowLeft as IconProp,
        action: () => menuNavigationHandler("/multiplexes"),
        color: "#848AAE",
        solid: true,
        topSeparator: false,
        routeActivation: ""
      },
      {
        name: "Informations du multiplex",
        icon: faInfo as IconProp,
        action: () => menuNavigationHandler("edit-multiplex-infos"),
        color: "white",
        solid: false,
        topSeparator: false,
        routeActivation: "edit-multiplex-infos"
      },
      {
        name: "Compteurs",
        icon: faPercent as IconProp,
        action: () => menuNavigationHandler("dashboards"),
        color: "white",
        solid: false,
        topSeparator: false,
        routeActivation: "dashboards"
      }
    ]
  };

  return (
    <div
      className={classNames({
        menu: true,
        "menu--active": mobileMenuIsOn
      })}
      data-testid="menu"
    >
      <div className="menu-desktop">
        <div className="menu-desktop__logo-container">
          <BaseLogo />
        </div>
        <div className="menu-desktop__navigation-container">
          {menusConfiguration[currentMenu()].map((menuItem, index) => {
            return (
              <MenuItem
                key={`desktop-menu-item__${index}`}
                icon={menuItem.icon}
                text={menuItem.name}
                color={menuItem.color}
                onClick={menuItem.action}
                solid={menuItem.solid}
                topSeparator={menuItem.topSeparator}
                condensed
                muted={
                  menuItem.solid ? false : itemIsMuted(menuItem.routeActivation)
                }
              />
            );
          })}
        </div>
        <div className="menu-desktop__quit-container">
          <MenuItem
            icon={faSignOutAlt as IconProp}
            text="Me déconnecter"
            condensed
            color="#ce193d"
            onClick={() => authCtx.signOutHandler()}
          />
        </div>
      </div>
      <div
        className={classNames({
          "menu-mobile": true,
          "menu-mobile--active": mobileMenuIsOn
        })}
      >
        {mobileMenuIsOn ? (
          <div className="menu-mobile--active__content">
            <div className="menu-mobile--active__content__header">
              <div
                className="menu-mobile--active__content__header__close-button"
                onClick={() => setMobileMenuIsOn(!mobileMenuIsOn)}
              >
                <BaseIcon
                  icon={faTimes as IconProp}
                  color="white"
                  size="32px"
                />
              </div>
            </div>
            <div className="menu-mobile--active__content__menu-items">
              <div className="menu-mobile--active__content__menu-items__navigation-container">
                {menusConfiguration[currentMenu()].map((menuItem, index) => {
                  return (
                    <MenuItem
                      key={`mobile-menu-item__${index}`}
                      icon={menuItem.icon}
                      text={menuItem.name}
                      color={menuItem.color}
                      onClick={menuItem.action}
                      topSeparator={menuItem.topSeparator}
                      muted={
                        menuItem.solid
                          ? false
                          : itemIsMuted(menuItem.routeActivation)
                      }
                    />
                  );
                })}
              </div>
              <div className="menu-mobile--active__content__menu-items__quit-container">
                <MenuItem
                  icon={faSignOutAlt as IconProp}
                  text="Me déconnecter"
                  color="#ce193d"
                  onClick={() => authCtx.signOutHandler()}
                />
              </div>
            </div>
          </div>
        ) : (
          <div className="menu-mobile__content">
            <div
              className="menu-mobile__content__hamburger"
              onClick={() => setMobileMenuIsOn(!mobileMenuIsOn)}
            >
              <BaseIcon
                icon={
                  currentMenu() !== "ROOT"
                    ? (faEllipsisH as IconProp)
                    : (faBars as IconProp)
                }
                color="white"
                size="32px"
              />
            </div>
            <BaseLogo />
          </div>
        )}
      </div>
    </div>
  );
};

export default Menu;
