import React, { useContext } from "react";
import BaseCard from "../../Base/BaseCard/BaseCard";
import BaseLabel from "../../Base/BaseLabel/BaseLabel";
import BaseToggle from "../../Base/BaseToggle/BaseToggle";
import EditShowMediasContext from "../../../contexts/edit-show-medias.ctx";
import "./StreamConfigurator.css";
import BaseInput from "../../Base/BaseInput/BaseInput";

interface ContainerProps {
  gridId: string;
}

const StreamConfigurator: React.FC<ContainerProps> = (props) => {
  const editShowMediasCtx = useContext(EditShowMediasContext);

  return (
    <BaseCard
      id={props.gridId}
      className="stream-configurator"
      data-testid="stream-configurator"
    >
      <BaseLabel>Streaming vidéo</BaseLabel>
      <div className="stream-configurator__form">
        <div className="stream-configurator__form__stream-activator">
          <BaseLabel small>Activer le streaming</BaseLabel>
          <BaseToggle
            value={editShowMediasCtx.isStreamingEnabled}
            onClick={() =>
              editShowMediasCtx.isStreamingEnabledInputHandler(
                !editShowMediasCtx.isStreamingEnabled
              )
            }
          />
        </div>
        <BaseInput
          label="ID Vidéo Youtube"
          placeholder={"Exemple : dQw4w9WgXcQ"}
          disabled={!editShowMediasCtx.isStreamingEnabled}
          value={editShowMediasCtx.youtubeStreamId}
          onValueChange={(value: string) =>
            editShowMediasCtx.youtubeStreamIdInputHandler(value)
          }
        />
        <div className="stream-configurator__form__stream-visualizer">
          <BaseLabel small>Aperçu vidéo : </BaseLabel>
          <div className="stream-configurator__form__stream-visualizer__visualizer">
            {editShowMediasCtx.isStreamingEnabled &&
              editShowMediasCtx.youtubeStreamId.length === 11 && (
                <iframe
                  width="100%"
                  height="100%"
                  src={`https://www.youtube.com/embed/${editShowMediasCtx.youtubeStreamId}`}
                  title="Stream Preview"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                ></iframe>
              )}
          </div>
        </div>
      </div>
    </BaseCard>
  );
};

export default StreamConfigurator;
