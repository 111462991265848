import React from "react";
import "./BaseSelectInput.css";

interface ContainerProps {
  value: string;
  onChangeValue: Function;
  options: { name: string; value: string }[];
  label?: string;
  default?: string;
}

const BaseSelectInput: React.FC<ContainerProps> = (props) => {
  return (
    <div className="base-select-input" data-testid="base-select-input">
      {props.label && <label>{props.label + " :"}</label>}
      <select
        className="base-select-input"
        data-testid="base-select-input"
        onChange={(event) => {
          props.onChangeValue(event.target.value);
        }}
        value={props.value}
      >
        {props.default && (<option disabled selected value={""}> {props.default} </option>)}
        {props.options.map((opt) => {
          return (
            <option value={opt.value} key={"select-val-" + opt.value}>
              {opt.name}
            </option>
          );
        })}
      </select>
    </div>
  );
};

export default BaseSelectInput;
