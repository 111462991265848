import Papa from "papaparse";
import { ShowProject } from "../contexts/edit-show-projects.ctx";
import Show from "../models/Show";
import API from "../services";
import slugify from "slugify";

const exportShowPledges = (
  showName: string,
  pledges: {
    id: string;
    projectId: string;
    amount: number;
    email: string;
    isAdmin: boolean;
    date: Date;
    additionalInformations: Record<any, any>;
  }[],
  projects: ShowProject[],
  registrationsList: {
    key: string;
    name: string;
  }[]
) => {
  const dataModel: Record<string, any>[] = [];
  const dataColumn: { key: string; name: string; custom?: boolean }[] = [
    { key: "email", name: "Email" },
    { key: "amount", name: "Montant" },
    { key: "onPlace", name: "Sur Place" },
    { key: "date", name: "Date" },
    { key: "time", name: "Heure" },
    { key: "projet", name: "Projet" },
    { key: "allowShare", name: "Partage" },
    { key: "askReceipt", name: "R.Fiscal" },
    { key: "isAdmin", name: "Administrateur" },
  ];

  registrationsList.map((reg) => {
    return dataColumn.push(reg);
  });

  pledges.map((pledge: Record<string, any>) => {
    let entry = {};
    dataColumn.map((col) => {
      if (col.custom) {
        entry = {
          ...entry,
          [col.name]: pledge.additionalInformations?.infos[col.key],
        };
      } else {
        switch (col.key) {
          case "onPlace":
            entry = {
              ...entry,
              [col.name]: pledge.additionalInformations?.onPlace,
            };
            break;
          case "allowShare":
            entry = {
              ...entry,
              [col.name]: pledge.additionalInformations?.allowShare,
            };
            break;
          case "askReceipt":
            entry = {
              ...entry,
              [col.name]: pledge.additionalInformations?.askReceipt,
            };
            break;
          case "amount":
            entry = {
              ...entry,
              [col.name]: (pledge.amount / 100).toFixed(0),
            };
            break;
          case "date":
            entry = {
              ...entry,
              [col.name]: pledge.date.toLocaleDateString("fr"),
            };
            break;
          case "time":
            entry = {
              ...entry,
              [col.name]: pledge.date.toLocaleTimeString("fr"),
            };
            break;
          case "projet":
            entry = {
              ...entry,
              [col.name]: projects.find((p) => p.id === pledge.projectId)?.name,
            };
            break;
          default:
            return (entry = { ...entry, [col.name]: pledge[col.key] });
        }
      }
      return entry;
    });
    return dataModel.push(entry);
  });
  let csv = Papa.unparse(dataModel, { delimiter: ";" });
  let csvData = new Blob([csv]);
  let a = window.document.createElement("a");
  a.href = window.URL.createObjectURL(csvData);
  document.body.appendChild(a);
  a.download = "[PROMESSES] " + showName + ".csv";
  a.click();
  document.body.removeChild(a);
};

const exportUsers = (
  showName: string,
  users: {
    id: string;
    allowShare: boolean;
    askReceipt: boolean;
    onPlace: boolean;
    infos: Record<any, any>[];
  }[],
  registrationsList: {
    key: string;
    name: string;
  }[]
) => {
  const dataModel: Record<string, any>[] = [];
  const dataColumn: { key: string; name: string; custom?: boolean }[] = [
    { key: "email", name: "Email" },
    { key: "onPlace", name: "Sur Place" },
    { key: "allowShare", name: "Partage" },
    { key: "askReceipt", name: "R.Fiscal" },
  ];

  registrationsList.map((reg) => {
    return dataColumn.push(reg);
  });

  users.map((user: Record<string, any>) => {
    let entry = {};
    dataColumn.map((col) => {
      if (col.custom) {
        entry = {
          ...entry,
          [col.name]: user.infos[col.key],
        };
      } else {
        switch (col.key) {
          case "email":
            entry = {
              ...entry,
              [col.name]: user.id,
            };
            break;
          case "onPlace":
            entry = {
              ...entry,
              [col.name]: user.onPlace,
            };
            break;
          case "allowShare":
            entry = {
              ...entry,
              [col.name]: user.allowShare,
            };
            break;
          case "askReceipt":
            entry = {
              ...entry,
              [col.name]: user.askReceipt,
            };
            break;
          default:
            return (entry = { ...entry, [col.name]: user[col.key] });
        }
      }
      return entry;
    });
    return dataModel.push(entry);
  });
  let csv = Papa.unparse(dataModel, { delimiter: ";" });
  let csvData = new Blob([csv]);
  let b = window.document.createElement("a");
  b.href = window.URL.createObjectURL(csvData);
  document.body.appendChild(b);
  b.download = "[PARTICIPANTS] " + showName + ".csv";
  b.click();
  document.body.removeChild(b);
};

const exportChatMessages = (
  showName: string,
  messages: {
    email: string;
    username: string;
    content: string;
    date: Date;
    pledgeAmount: string;
  }[]
) => {
  const dataModel: Record<string, any>[] = [];
  const dataColumn: { key: string; name: string; custom?: boolean }[] = [
    { key: "email", name: "Email" },
    { key: "username", name: "Pseudo" },
    { key: "content", name: "Message" },
    { key: "date", name: "Date" },
    { key: "time", name: "Heure" },
    { key: "pledgeAmount", name: "Promesse" },
  ];

  messages.map((message: Record<string, any>) => {
    let entry = {};
    dataColumn.map((col) => {
      if (col.custom) {
        entry = {
          ...entry,
          [col.name]: message.infos[col.key],
        };
      } else {
        switch (col.key) {
          case "date":
            entry = {
              ...entry,
              [col.name]: message.date.toLocaleDateString("fr"),
            };
            break;
          case "time":
            entry = {
              ...entry,
              [col.name]: message.date.toLocaleTimeString("fr"),
            };
            break;
          default:
            return (entry = { ...entry, [col.name]: message[col.key] });
        }
      }
      return entry;
    });
    return dataModel.push(entry);
  });
  let csv = Papa.unparse(dataModel, { delimiter: ";" });
  let csvData = new Blob([csv]);
  let c = window.document.createElement("a");
  c.href = window.URL.createObjectURL(csvData);
  document.body.appendChild(c);
  c.download = "[MESSAGES] " + showName + ".csv";
  c.click();
  document.body.removeChild(c);
};

const exportShowData = async (show: Show, showProjects: ShowProject[]) => {
  try {
    const pledgesData = await API.getAllShowPledgesByShowId(show.id);
    const usersData = await API.getAllShowUsersByShowId(show.id);
    const chatMessages = await API.getAllShowChatMessages(show.id);
    const registrationData = [];
    if (show.isFirstnamePresetOn) {
      registrationData.push({ key: "firstname", name: "Prénom", custom: true });
    }
    if (show.isLastnamePresetOn) {
      registrationData.push({ key: "lastname", name: "Nom", custom: true });
    }
    if (show.isPhonePresetOn) {
      registrationData.push({ key: "phone", name: "Téléphone", custom: true });
    }
    if (show.isAddressFirstLinePresetOn) {
      registrationData.push({
        key: "address_first_line",
        name: "Adresse 1",
        custom: true,
      });
    }
    if (show.isAddressSecondLinePresetOn) {
      registrationData.push({
        key: "address_second_line",
        name: "Adresse 2",
        custom: true,
      });
    }
    if (show.isZipcodePresetOn) {
      registrationData.push({
        key: "zipcode",
        name: "Code Postal",
        custom: true,
      });
    }
    if (show.isCityPresetOn) {
      registrationData.push({ key: "city", name: "Ville", custom: true });
    }
    if (show.isCountryPresetOn) {
      registrationData.push({ key: "country", name: "Pays", custom: true });
    }
    if (show.isPseudoPresetOn) {
      registrationData.push({ key: "pseudo", name: "Pseudo", custom: true });
    }
    show.customRegistrationsList.map((reg: Record<any, any>) => {
      const slug = slugify(reg.name, {
        replacement: "_",
        lower: true,
        strict: true,
        locale: "en",
        trim: true,
      });
      return registrationData.push({ key: slug, name: reg.name, custom: true });
    });
    exportShowPledges(show.name, pledgesData, showProjects, registrationData);
    exportUsers(show.name, usersData, registrationData);
    exportChatMessages(show.name, chatMessages);
  } catch (err) {
    console.log(err);
  }
};
export default exportShowData;
