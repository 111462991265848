import React, { useContext } from "react";
import BaseCard from "../../Base/BaseCard/BaseCard";
import BaseLabel from "../../Base/BaseLabel/BaseLabel";
import BaseImageInput from "../../Base/BaseImageInput/BaseImageInput";
import "./CoverSelector.css";
import EditShowMediasContext from "../../../contexts/edit-show-medias.ctx";

interface ContainerProps {
  gridId: string;
}

const CoverSelector: React.FC<ContainerProps> = (props) => {
  const editShowMediasCtx = useContext(EditShowMediasContext);

  return (
    <BaseCard
      id={props.gridId}
      className="cover-selector"
      data-testid="cover-selector"
    >
      <BaseLabel>Image de couverture</BaseLabel>
      <BaseImageInput
        presetValue={editShowMediasCtx.existingCoverUrl}
        value={editShowMediasCtx.pendingCoverFile}
        onImageSelected={editShowMediasCtx.pendingCoverFileInputHandler}
      />
    </BaseCard>
  );
};

export default CoverSelector;
