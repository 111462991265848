import "./DashboardsPage.css"

import React, { useContext } from "react";
import PageContent from "../../Layout/PageContent/PageContent";
import PageHeader from "../../Layout/PageHeader/PageHeader";
import AppContext from "../../../contexts/app.ctx";
import BaseLoadingCurtain from "../../Base/BaseLoadingCurtain/BaseLoadingCurtain";
import DashboardsConfigurator from "../../MultiplexDashboards/DasboardsConfigurator/DashboardsConfigurator";
import {faSave} from "@fortawesome/free-solid-svg-icons";
import EditMultiplexDashboardsContext from "../../../contexts/edit-multiplex-dashboards.ctx";
import {IconProp} from "@fortawesome/fontawesome-svg-core";

export const dashboardsPageRoute = "/:multiplexId/dashboards";

interface ContainerProps {}

const DashboardsPage: React.FC<ContainerProps> = (props) => {
  const appCtx = useContext(AppContext);
  const editMultiplexDashboardsCtx = useContext(EditMultiplexDashboardsContext);

  return(
    <>
      {appCtx.isLoading ? <BaseLoadingCurtain /> : null}
      <div
        className="edit-multiplex-counter-page"
        data-testid="edit-show-projects-page"
      >
        <PageHeader
          title="Compteurs du multiplex"
          actions={[
            {
              name: "Enregistrer l'ordre",
              action: () => editMultiplexDashboardsCtx.submitUpdate(),
              color: "#2CA795",
              icon: faSave as IconProp,
              isLoading: editMultiplexDashboardsCtx.isLoading
            }
          ]}
        />
        <PageContent>
          <div className="edit-show-projects-page__grid">
            <DashboardsConfigurator gridId={"dashboards-configurator"} />
          </div>
        </PageContent>
      </div>
    </>
  )
}

export default DashboardsPage