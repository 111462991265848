import React, { useContext } from "react";
import EditShowInfosContext from "../../../contexts/edit-show-infos.ctx";
import BaseCard from "../../Base/BaseCard/BaseCard";
import BaseInput from "../../Base/BaseInput/BaseInput";
import BaseTextArea from "../../Base/BaseTextArea/BaseTextArea";
import BaseDatetimePicker from "../../Base/BaseDatetimePicker/BaseDatetimePicker";

import "./ShowInfosForm.css";
import BaseInputColor from "../../Base/BaseInputColor/BaseInputColor";

interface ContainerProps {
  gridId: string;
}

const ShowInfosForm: React.FC<ContainerProps> = (props) => {
  const editShowInfosCtx = useContext(EditShowInfosContext);

  return (
    <BaseCard
      id={props.gridId}
      className="show-infos-form"
      data-testid="show-infos-form"
    >
      <BaseInput
        label="Nom de l'événement"
        value={editShowInfosCtx.name}
        onValueChange={(value: string) =>
          editShowInfosCtx.nameInputHandler(value)
        }
      />
      <BaseInput
        label="Lieu de l'événement"
        value={editShowInfosCtx.place}
        onValueChange={(value: string) =>
          editShowInfosCtx.placeInputHandler(value)
        }
      />
      <BaseInput
        label="Sous-titre"
        value={editShowInfosCtx.subtitle}
        onValueChange={(value: string) =>
          editShowInfosCtx.subtitleInputHandler(value)
        }
      />
      <BaseInput
        label="Chemin d'accès URL"
        value={editShowInfosCtx.urlSlug}
        onValueChange={(value: string) =>
          editShowInfosCtx.urlSlugInputHandler(value)
        }
      />
      <BaseDatetimePicker
        label="Date et heure de début"
        value={editShowInfosCtx.startDate}
        onValueChange={(value: Date) =>
          editShowInfosCtx.startDateInputHandler(value)
        }
      />
      <BaseDatetimePicker
        label="Date et heure de fin"
        value={editShowInfosCtx.endDate}
        onValueChange={(value: Date) =>
          editShowInfosCtx.endDateInputHandler(value)
        }
      />
      <BaseTextArea
        label="Description de l'événement"
        value={editShowInfosCtx.description}
        onValueChange={(value: string) =>
          editShowInfosCtx.descriptionInputHandler(value)
        }
      />
      <BaseTextArea
        label="Message de fin d'événement"
        value={editShowInfosCtx.endMessage}
        onValueChange={(value: string) =>
          editShowInfosCtx.endMessageInputHandler(value)
        }
      />
      <BaseInput
        label="Texte du bouton événement à venir"
        value={editShowInfosCtx.comingButtonText}
        onValueChange={(value: string) =>
          editShowInfosCtx.comingButtonTextInputHandler(value)
        }
      />
      <BaseInput
        label="Lien du bouton événement à venir"
        value={editShowInfosCtx.comingButtonUrl}
        onValueChange={(value: string) =>
          editShowInfosCtx.comingButtonUrlInputHandler(value)
        }
      />
      <BaseInput
        label="Texte du bouton événement passé"
        value={editShowInfosCtx.pastButtonText}
        onValueChange={(value: string) =>
          editShowInfosCtx.pastButtonTextInputHandler(value)
        }
      />
      <BaseInput
        label="Lien du bouton événement passé"
        value={editShowInfosCtx.pastButtonUrl}
        onValueChange={(value: string) =>
          editShowInfosCtx.pastButtonUrlInputHandler(value)
        }
      />
      <BaseInput
        label="Texte du bouton de fin d'événement"
        value={editShowInfosCtx.endButtonText}
        onValueChange={(value: string) =>
          editShowInfosCtx.endButtonTextInputHandler(value)
        }
      />
      <BaseInput
        label="Lien du bouton de fin d'événement"
        value={editShowInfosCtx.endButtonUrl}
        onValueChange={(value: string) =>
          editShowInfosCtx.endButtonUrlInputHandler(value)
        }
      />
      <BaseInput
        label="Objectif de l'événement"
        value={editShowInfosCtx.globalShowGoal}
        type="number"
        onValueChange={(value: string) =>
          editShowInfosCtx.globalShowGoalInputHandler(value)
        }
      />
      <BaseInput
        label="Montant forcé après événement"
        value={editShowInfosCtx.forcedEndingAmount}
        type="number"
        onValueChange={(value: string) =>
          editShowInfosCtx.forcedEndingAmountInputHandler(value)
        }
      />
      <BaseInputColor
        label="Couleur de fond"
        value={editShowInfosCtx.backgroundColor}
        onValueChange={(value: string) =>
          editShowInfosCtx.backgroundColorHandler(value)
        }
      />
      <BaseInputColor
        label="Couleur de texte"
        value={editShowInfosCtx.fontColor}
        onValueChange={(value: string) =>
          editShowInfosCtx.fontColorHandler(value)
        }
      />
    </BaseCard>
  );
};

export default ShowInfosForm;
