import { IconProp } from "@fortawesome/fontawesome-svg-core";
import BaseButton from "../../Base/BaseButton/BaseButton";
import "./PageHeader.css";

export type PageHeaderAction = {
  name: string;
  action: Function;
  color: string;
  icon?: IconProp;
  isLoading?: boolean;
};
interface ContainerProps {
  title: string;
  actions?: PageHeaderAction[];
}

const PageHeader: React.FC<ContainerProps> = (props) => {
  return (
    <div className="page-header" data-testid="page-header">
      <div className="page-header__content">
        <span>{props.title}</span>
        <div className="page-header__content__actions">
          {props.actions?.map((action, index) => {
            return (
              <BaseButton
                key={"page-header__content__button__" + index}
                name={action.name}
                onClick={action.action}
                color={action.color}
                margin={"0 0 0 16px"}
                icon={action.icon}
                mobileCondensed
                isLoading={action.isLoading}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default PageHeader;
