import buildEnvConfig from "../build.env.json";
const emulators = {
  auth: {
    url: "http://localhost:9099",
  },
  functions: {
    host: "localhost",
    port: 5001,
  },
  firestore: {
    host: "localhost",
    port: 8080,
  },
  storage: {
    host: "localhost",
    port: 9199,
  },
};

const devConfig = {
  envName: "DEV",
  functionsEntryPoint: "https://us-central1-donslive3-dev.cloudfunctions.net/",
  sdkCredentials: {
    apiKey: "AIzaSyC94GWfPKkTXLVurbe5pKoCxHvf986KVmg",
    authDomain: "donslive3-dev.firebaseapp.com",
    projectId: "donslive3-dev",
    storageBucket: "donslive3-dev.appspot.com",
    messagingSenderId: "837139452126",
    appId: "1:837139452126:web:d66de2bdb3d4d2eff6b24f",
    measurementId: "G-CG7G8MM8B6",
  },
  emulators: { ...emulators },
};
const prodConfig = {
  envName: "PROD",
  functionsEntryPoint: "https://us-central1-donslive3-prod.cloudfunctions.net/",
  sdkCredentials: {
    apiKey: "AIzaSyBIztVoTbiOHrrR5LYt-9HrvI-Kx--CI5c",
    authDomain: "donslive3-prod.firebaseapp.com",
    projectId: "donslive3-prod",
    storageBucket: "donslive3-prod.appspot.com",
    messagingSenderId: "220599824117",
    appId: "1:220599824117:web:f51e600b1eb957d2b81fbc",
    measurementId: "G-48G2028N97",
  },
  emulators: { ...emulators },
};
const localConfig = {
  envName: "LOCAL",
  functionsEntryPoint: "http://127.0.0.1:5001/eneance-dons-live-emulator/us-central1/",
  sdkCredentials: {
    apiKey: "AIzaSyDbxBveyXv9ZZJz-6U6duYTMAcEOV_sPXQ",
    authDomain: "eneance-dons-live-emulator.firebaseapp.com",
    projectId: "eneance-dons-live-emulator",
    storageBucket: "eneance-dons-live-emulator.appspot.com",
    messagingSenderId: "457063876050",
    appId: "1:457063876050:web:6e10229bdb38efd829b40b"
  },
  emulators: { ...emulators },
};

const FirebaseConfig = () => {
  if (buildEnvConfig.BUILD_ENV === "PROD") {
    return prodConfig;
  } else if (buildEnvConfig.BUILD_ENV === "DEV") {
    return devConfig;
  } else if (buildEnvConfig.BUILD_ENV === "LOCAL") {
    return localConfig;
  }
  throw new Error('Unknown BUILD_ENV, should be one of : PROD | DEV | LOCAL in build.env.json')
};

export default FirebaseConfig;
