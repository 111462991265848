import React, {useContext, useEffect, useRef} from "react";
import {useParams} from "react-router-dom";
import AppContext from "../../../contexts/app.ctx";
import BaseLoadingCurtain from "../../Base/BaseLoadingCurtain/BaseLoadingCurtain";
import PageHeader from "../../Layout/PageHeader/PageHeader";
import {faSave} from "@fortawesome/free-solid-svg-icons";
import {IconProp} from "@fortawesome/fontawesome-svg-core";
import PageContent from "../../Layout/PageContent/PageContent";
import "./EditMultiplexInfosPage.css";
import MultiplexInfosForm from "../../MultiplexInfos/MultiplexInfosForm/MultiplexInfosForm";
import EditMultiplexInfosCtx from "../../../contexts/edit-multiplex-infos.ctx";
import DeleteMultiplexAction from "../../MultiplexInfos/DeleteMultiplexAction/DeleteMultiplexAction";

interface ContainerProps {}

export const editMultiplexInfosPageRoute = "/:multiplexId/edit-multiplex-infos";

const EditMultiplexInfosPage: React.FC<ContainerProps> = (props) => {
  const { multiplexId } = useParams<Record<string, string>>();
  const appCtx = useContext(AppContext);
  const appCtxRef = useRef(useContext(AppContext));
  const editMultiplexInfosCtx = useContext(EditMultiplexInfosCtx);

  useEffect(() => {
    appCtxRef.current.loadCurrentMultiplex(multiplexId);
  }, [multiplexId]);

  return (
    <>
      {appCtx.isLoading ? <BaseLoadingCurtain /> : null}
      <div className="edit-multiplex-infos-page">
        <PageHeader
          title="Informations du multiplex"
          actions={[
            {
              name: "Enregistrer",
              action: () => editMultiplexInfosCtx.submitUpdate(),
              color: "#2CA795",
              icon: faSave as IconProp,
              isLoading: editMultiplexInfosCtx.isLoading
            }
          ]}
        />
        <PageContent>
          <div className="edit-multiplex-infos-page__grid">
            <MultiplexInfosForm gridId="multiplex-infos-form" />
            <DeleteMultiplexAction gridId="delete-multiplex-action" />
          </div>
        </PageContent>
      </div>
    </>
  )
}

export default EditMultiplexInfosPage