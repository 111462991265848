import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./BaseIcon.css";

interface ContainerProps {
  icon: IconProp;
  color?: string;
  size?: string;
}

const BaseIcon: React.FC<ContainerProps> = (props) => {
  return (
    <FontAwesomeIcon
      icon={props.icon}
      color={props.color}
      style={{ fontSize: props.size || "24px" }}
    />
  );
};

export default BaseIcon;
