import React, { useContext } from "react";
import EditShowPledgesContext from "../../../contexts/edit-show-pledges.ctx";
import BaseCard from "../../Base/BaseCard/BaseCard";
import BaseLabel from "../../Base/BaseLabel/BaseLabel";
import BaseToggle from "../../Base/BaseToggle/BaseToggle";
import BaseCsvLoader from "../../Base/BaseCsvLoader/BaseCsvLoader";
import "./PledgesImportModule.css";

interface ContainerProps {
  gridId: string;
}

const PledgesImportModule: React.FC<ContainerProps> = (props) => {
  const editShowPledgesCtx = useContext(EditShowPledgesContext);

  const csvLoadingHandler = (data: []) => {
    const userAmountsList = data.map((row: []) => {
      const customUserAmounts = {
        email: "",
        firstAmount: 0,
        secondAmount: 0,
        thirdAmount: 0,
        fourthAmount: 0,
        freeAmount: false,
      };
      row.map((item, index) => {
        switch (index) {
          case 0:
            customUserAmounts.email = item;
            break;
          case 1:
            customUserAmounts.firstAmount = item;
            break;
          case 2:
            customUserAmounts.secondAmount = item;
            break;
          case 3:
            customUserAmounts.thirdAmount = item;
            break;
          case 4:
            customUserAmounts.fourthAmount = item;
            break;
          case 5:
            customUserAmounts.freeAmount = +item === 0 ? false : true;
            break;
          default:
            return item;
        }
        return item;
      });
      return customUserAmounts;
    });
    editShowPledgesCtx.customPledgeAmountsListInputHandler(userAmountsList);
  };
  return (
    <BaseCard
      id={props.gridId}
      className="pledges-import-module"
      data-testid="pledges-import-module"
    >
      <BaseLabel>Promesses personnalisées</BaseLabel>
      <div className="pledges-import-module__form">
        <div className="pledges-import-module__form__toggle">
          <BaseLabel small>Activer</BaseLabel>
          <BaseToggle
            value={editShowPledgesCtx.hasCustomPledgeAmounts}
            onClick={() =>
              editShowPledgesCtx.hasCustomPledgeAmountsInputHandler(
                !editShowPledgesCtx.hasCustomPledgeAmounts
              )
            }
          />
        </div>
        <div className="pledges-import-module__form__module">
          <BaseLabel small>Charger un fichier CSV</BaseLabel>
          <BaseCsvLoader onCsvLoaded={(data: []) => csvLoadingHandler(data)} />
          <div className="pledges-import-module__form__module__counter">
            <span>
              {editShowPledgesCtx.customPledgeAmountsList.length
                ? editShowPledgesCtx.customPledgeAmountsList.length
                : editShowPledgesCtx.editedShow.customPledgeAmountsCount || 0}
            </span>
            <span>Profils chargés</span>
          </div>
        </div>
      </div>
    </BaseCard>
  );
};

export default PledgesImportModule;
