import React, { useContext, useEffect, useRef } from "react";
import PageHeader from "../../Layout/PageHeader/PageHeader";
import PageContent from "../../Layout/PageContent/PageContent";
import PublishedSelector from "../../ShowInfos/PublishedSelector/PublishedSelector";
import OnPlaceSelector from "../../ShowInfos/OnPlaceSelector/OnPlaceSelector";
import OnRemoteSelector from "../../ShowInfos/OnRemoteSelector/OnRemoteSelector";
import "./EditShowInfosPage.css";
import { faSave } from "@fortawesome/free-solid-svg-icons";
import ShowInfosForm from "../../ShowInfos/ShowInfosForm/ShowInfosForm";
import DeleteShowAction from "../../ShowInfos/DeleteShowAction/DeleteShowAction";
import EditShowInfosContext from "../../../contexts/edit-show-infos.ctx";
import { useParams } from "react-router-dom";
import AppContext from "../../../contexts/app.ctx";
import BaseLoadingCurtain from "../../Base/BaseLoadingCurtain/BaseLoadingCurtain";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import ShowColorPledgeForm from "../../ShowInfos/ShowColorPledgeForm/ShowColorPledgeForm";
import PreviewCounter from "../../ShowInfos/PreviewCounter/PreviewCounter";

interface ContainerProps {}

export const editShowInfosPageRoute = "/:showId/edit-show-infos";

const EditShowInfosPage: React.FC<ContainerProps> = (props) => {
  const { showId } = useParams<Record<string, string>>();
  const appCtx = useContext(AppContext);
  const appCtxRef = useRef(useContext(AppContext));
  const editShowInfosCtx = useContext(EditShowInfosContext);

  useEffect(() => {
    appCtxRef.current.loadCurrentShow(showId);
  }, [showId]);

  return (
    <>
      {appCtx.isLoading ? <BaseLoadingCurtain /> : null}
      <div className="edit-show-infos-page" data-testid="edit-show-infos-page">
        <PageHeader
          title="Informations de l'événement"
          actions={[
            {
              name: "Enregistrer",
              action: () => editShowInfosCtx.submitUpdate(),
              color: "#2CA795",
              icon: faSave as IconProp,
              isLoading: editShowInfosCtx.isLoading
            }
          ]}
        />
        <PageContent>
          <div className="edit-show-infos-page__grid">
            <PublishedSelector gridId="published-selector" />
            <OnPlaceSelector gridId="on-place-selector" />
            <OnRemoteSelector gridId="on-remote-selector" />
            <ShowInfosForm gridId="show-infos-form" />
            <ShowColorPledgeForm gridId="show-color-pledge-form" />
            <PreviewCounter gridId="preview-counter" />
            {/* <IsLnbcSelector gridId="is-lnbc-selector" /> */}
            <DeleteShowAction gridId="delete-show-action" />
          </div>
        </PageContent>
      </div>
    </>
  );
};

export default EditShowInfosPage;
