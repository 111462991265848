import React from "react";
import "./BaseToggle.css";

interface ContainerProps {
  value: boolean;
  onClick: Function;
}

const BaseToggle: React.FC<ContainerProps> = (props) => {
  return (
    <div className="base-toggle" data-testid="base-toggle">
      <input
        className="base-toggle__checkbox"
        type="checkbox"
        checked={props.value}
        onClick={() => props.onClick()}
        onChange={() => {}}
      />
      <div className="base-toggle__toggler">
        <div className="base-toggle__toggler__dot" />
      </div>
    </div>
  );
};

export default BaseToggle;
