import React, { useContext } from "react";
import EditShowInfosContext from "../../../contexts/edit-show-infos.ctx";
import BaseCard from "../../Base/BaseCard/BaseCard";
import BaseLabel from "../../Base/BaseLabel/BaseLabel";
import BaseToggle from "../../Base/BaseToggle/BaseToggle";
import "./OnRemoteSelector.css";

interface ContainerProps {
  gridId: string;
}

const OnRemoteSelector: React.FC<ContainerProps> = (props) => {
  const editShowInfosCtx = useContext(EditShowInfosContext);

  return (
    <BaseCard id={props.gridId} className="on-remote-selector">
      <BaseLabel>Accessible à distance</BaseLabel>
      <BaseToggle
        value={editShowInfosCtx.isOnRemote}
        onClick={() =>
          editShowInfosCtx.isOnRemoteInputHandler(!editShowInfosCtx.isOnRemote)
        }
      />
    </BaseCard>
  );
};

export default OnRemoteSelector;
