import buildEnvConfig from "../build.env.json";

const localUrl = `http://localhost:3001/`
const devUrl = `https://donslive3-dev.firebaseapp.com/`
const prodUrl = `https://donslive3-prod.firebaseapp.com/`

const ClientUrlConfig = () => {
  if (buildEnvConfig.BUILD_ENV === "PROD") {
    return prodUrl;
  } else if (buildEnvConfig.BUILD_ENV === "DEV") {
    return devUrl;
  } else if (buildEnvConfig.BUILD_ENV === "LOCAL") {
    return localUrl;
  }
};

export default ClientUrlConfig;