import React, { useContext } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import Menu from "./components/Layout/Menu/Menu";
import HomePage, { homePageRoute } from "./components/Pages/HomePage/HomePage";
import NewShowPage, {
  newShowPageRoute,
} from "./components/Pages/NewShowPage/NewShowPage";
import EditShowInfosPage, {
  editShowInfosPageRoute,
} from "./components/Pages/EditShowInfosPage/EditShowInfosPage";
import EditShowMediasPage, {
  editShowMediasPageRoute,
} from "./components/Pages/EditShowMediasPage/EditShowMediasPage";
import EditShowPledgesPage, {
  editShowPledgesPageRoute,
} from "./components/Pages/EditShowPledgesPage/EditShowPledgesPage";
import EditShowProjectsPage, {
  editShowProjectsPageRoute,
} from "./components/Pages/EditShowProjectsPage/EditShowProjectsPage";
import EditShowRegistrationsPage, {
  editShowRegistrationsPageRoute,
} from "./components/Pages/EditShowRegistrationsPage/EditShowRegistrationsPage";
import LiveManagerPage, {
  liveManagerPageRoute,
} from "./components/Pages/LiveManagerPage/LiveManagerPage";

import "./App.css";
import { EditShowInfosContextProvider } from "./contexts/edit-show-infos.ctx";
import { EditShowRegistrationsContextProvider } from "./contexts/edit-show-registrations.ctx";
import { EditShowProjectsContextProvider } from "./contexts/edit-show-projects.ctx";
import { EditShowPledgesContextProvider } from "./contexts/edit-show-pledges.ctx";
import BaseNotification from "./components/Base/BaseNotification/BaseNotification";
import AppContext from "./contexts/app.ctx";
import { EditShowMediasContextProvider } from "./contexts/edit-show-medias.ctx";
import LoginPage from "./components/Pages/LoginPage/LoginPage";
import AuthContext, { AuthStates } from "./contexts/auth.ctx";
import NewMultiplexPage, {newMultiplexPageRoute} from "./components/Pages/NewMultiplexPage/NewMultiplexPage";
import MultiplexeManagerPage, {
  multiplexManagerPageRoute
} from "./components/Pages/MultiplexeManagerPage/MultiplexeManagerPage";
import MultiplexesPage, {multiplexesPageRoute} from "./components/Pages/MultiplexPage/MultiplexesPage";
import DashboardsPage, {dashboardsPageRoute} from "./components/Pages/DashboardPage/DashboardsPage";
import EditMultiplexInfosPage, {editMultiplexInfosPageRoute} from "./components/Pages/EditMultiplexInfosPage/EditMultiplexInfosPage";
import {EditMultiplexInfosContextProvider} from "./contexts/edit-multiplex-infos.ctx";
import {EditMultiplexDashboardsContextProvider} from "./contexts/edit-multiplex-dashboards.ctx";

function App() {
  const appCtx = useContext(AppContext);
  const authCtx = useContext(AuthContext);
  return (
    <div className="app">
      <Router>
        {authCtx.authState === AuthStates.AUTH && <Menu />}
        <BaseNotification
          isActive={appCtx.notificationIsOn}
          type={appCtx.notificationSetup.type}
          content={appCtx.notificationSetup.content}
        />
        <main className="app-content__main">
          <Switch>
            <Route exact path="/login">
              <LoginPage />
            </Route>
            <Route path={liveManagerPageRoute}>
              {authCtx.authState !== AuthStates.AUTH ? (
                <Redirect to="/login" />
              ) : (
                <LiveManagerPage />
              )}
            </Route>
            <Route path={editShowRegistrationsPageRoute}>
              {authCtx.authState !== AuthStates.AUTH ? (
                <Redirect to="/login" />
              ) : (
                <EditShowRegistrationsContextProvider>
                  <EditShowRegistrationsPage />
                </EditShowRegistrationsContextProvider>
              )}
            </Route>
            <Route path={editShowProjectsPageRoute}>
              {authCtx.authState !== AuthStates.AUTH ? (
                <Redirect to="/login" />
              ) : (
                <EditShowProjectsContextProvider>
                  <EditShowProjectsPage />
                </EditShowProjectsContextProvider>
              )}
            </Route>
            <Route path={editShowPledgesPageRoute}>
              {authCtx.authState !== AuthStates.AUTH ? (
                <Redirect to="/login" />
              ) : (
                <EditShowPledgesContextProvider>
                  <EditShowPledgesPage />
                </EditShowPledgesContextProvider>
              )}
            </Route>
            <Route path={editShowMediasPageRoute}>
              {authCtx.authState !== AuthStates.AUTH ? (
                <Redirect to="/login" />
              ) : (
                <EditShowMediasContextProvider>
                  <EditShowMediasPage />
                </EditShowMediasContextProvider>
              )}
            </Route>
            <Route path={editShowInfosPageRoute}>
              {authCtx.authState !== AuthStates.AUTH ? (
                <Redirect to="/login" />
              ) : (
                <EditShowInfosContextProvider>
                  <EditShowInfosPage />
                </EditShowInfosContextProvider>
              )}
            </Route>
            <Route path={newShowPageRoute}>
              {authCtx.authState !== AuthStates.AUTH ? (
                <Redirect to="/login" />
              ) : (
                <NewShowPage />
              )}
            </Route>
            <Route path={multiplexesPageRoute}>
              {authCtx.authState !== AuthStates.AUTH ? (
                <Redirect to="/login" />
              ) : (
                <MultiplexesPage />
              )}
            </Route>
            <Route path={newMultiplexPageRoute}>
              {authCtx.authState !== AuthStates.AUTH ? (
                <Redirect to="/login" />
              ) : (
                <NewMultiplexPage />
              )}
            </Route>
            <Route path={editMultiplexInfosPageRoute}>
              {authCtx.authState !== AuthStates.AUTH ? (
                <Redirect to="/login" />
              ) : (
                <EditMultiplexInfosContextProvider>
                  <EditMultiplexInfosPage />
                </EditMultiplexInfosContextProvider>
              )}
            </Route>
            <Route path={dashboardsPageRoute}>
              {authCtx.authState !== AuthStates.AUTH ? (
                <Redirect to="/login" />
              ) : (
                <EditMultiplexDashboardsContextProvider>
                  <DashboardsPage />
                </EditMultiplexDashboardsContextProvider>
              )}
            </Route>
            <Route path={multiplexManagerPageRoute}>
              {authCtx.authState !== AuthStates.AUTH ? (
                <Redirect to="/login" />
              ) : (
                <MultiplexeManagerPage />
              )}
            </Route>
            <Route path={homePageRoute}>
              {authCtx.authState !== AuthStates.AUTH ? (
                <Redirect to="/login" />
              ) : (
                <HomePage />
              )}
            </Route>
          </Switch>
        </main>
      </Router>
    </div>
  );
}

export default App;
