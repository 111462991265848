import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import React, { useContext } from "react";
import { useHistory } from "react-router";
import AppContext from "../../../contexts/app.ctx";
import BaseButton from "../../Base/BaseButton/BaseButton";
import BaseCard from "../../Base/BaseCard/BaseCard";
import BaseLabel from "../../Base/BaseLabel/BaseLabel";
import "./DeleteMultiplexAction.css";

interface ContainerProps {
  gridId: string;
}

const DeleteMultiplexAction: React.FC<ContainerProps> = (props) => {
  const appCtx = useContext(AppContext);
  const history = useHistory();

  const deleteMultiplex = () => {
    const deletionConfirmation = window.confirm(
      "ATTENTION : Souhaitez vous réellement supprimer ce multiplex ?"
    );
    if (deletionConfirmation) {
      const secondDeletionConfirmation = window.confirm(
        "ATTENTION : Vraiment ? Tous les compteurs et configurations vont-être effacés !"
      );
      if (secondDeletionConfirmation) {
        appCtx.deleteCurrentMultiplex();
        history.push("/multiplexes");
      }
    }
  };

  return (
    <BaseCard
      id={props.gridId}
      className="delete-multiplex-action"
      data-testid="delete-multiplex-action"
    >
      <BaseLabel>
        <span style={{ color: "#CE193D" }}>Supprimer le multiplex</span>
      </BaseLabel>
      <BaseButton
        name="Supprimer"
        onClick={() => deleteMultiplex()}
        color="#CE193D"
        mobileCondensed
        icon={faTrash as IconProp}
      />
    </BaseCard>
  );
};

export default DeleteMultiplexAction;
