import React, { useContext, useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import Show from "../models/Show";
import API from "../services";
import AppContext from "./app.ctx";

export type ShowProject = {
  id: string;
  position: number;
  name: string;
  goal: number;
  logoUrl: string;
  totalPledgesAmount: number;
  backgroundColorLogo: string;
  parentShowId?: string
  place?: string
};

const EditShowProjectsContext = React.createContext({
  editedShow: {} as Show,
  isLoading: false,
  pendingProjectIsLoading: false,
  resetContext: () => {},
  hasNoProjects: false,
  hasNoProjectsInputHandler: (value: boolean) => {},
  projectsList: [] as ShowProject[],
  projectsListSetter: (value: ShowProject[]) => {},
  pendingProject: {} as ShowProject,
  pendingProjectSetter: (project: ShowProject) => {},
  pendingProjectName: "",
  pendingProjectNameInputHandler: (value: string) => {},
  pendingProjectPosition: -1,
  pendingProjectPositionSetter: (value: number) => {},
  pendingProjectGoal: "",
  pendingProjectGoalInputHandler: (value: string) => {},
  pendingProjectPendingLogoFile: new File([], ""),
  pendingProjectPendingLogoFileInputHandler: (file: File) => {},
  pendingProjectLogoUrl: "",
  pendingProjectLogoUrlSetter: (value: string) => {},
  pendingProjectTotalPledgesAmount: 0,
  pendingProjectTotalPledgesAmountSetter: (value: number) => {},
  backgroundColorLogoHandler: (value: string) => {},
  backgroundColorLogo: "",
  submitProjectWrite: () => {},
  triggerListMode: false,
  deleteProject: (project: ShowProject) => {},
  submitUpdate: () => {}
});

export const EditShowProjectsContextProvider: React.FC = (props) => {
  const [editedShow, setEditedShow] = useState({} as Show);
  const [isLoading, setIsLoading] = useState(false);
  const [pendingProjectIsLoading, setPendingProjectIsLoading] = useState(false);
  const [hasNoProjects, setHasNoProjects] = useState(false);
  const [projectsList, setProjectsList] = useState([] as ShowProject[]);
  const [pendingProject, setPendingProject] = useState({} as ShowProject);
  const [pendingProjectName, setPendingProjectName] = useState("");
  const [pendingProjectPosition, setPendingProjectPosition] = useState(-1);
  const [pendingProjectGoal, setPendingProjectGoal] = useState("");
  const [pendingProjectPendingLogoFile, setPendingProjectPendingLogoFile] =
    useState<File>(new File([], ""));
  const [pendingProjectLogoUrl, setPendingProjectLogoUrl] = useState("");
  const [
    pendingProjectTotalPledgesAmount,
    setPendingProjectTotalPledgesAmount
  ] = useState(0);
  const [triggerListMode, setTriggerListMode] = useState(false);
  const [backgroundColorLogo, setBackgroundColorLogo] = useState("");
  const appCtx = useContext(AppContext);

  useEffect(() => {
    if (appCtx.currentShow.id) {
      setEditedShow(appCtx.currentShow);
      setIsLoading(false);
      setHasNoProjects(appCtx.currentShow.hasNoProjects);
    }
  }, [appCtx.currentShow]);

  useEffect(() => {
    if (projectsList.length) {
      setHasNoProjects(false);
    } else {
      setHasNoProjects(true);
    }
  }, [projectsList]);

  const resetContext = () => {
    setEditedShow({} as Show);
    setIsLoading(false);
    setPendingProjectIsLoading(false);
    setHasNoProjects(false);
    setProjectsList([] as ShowProject[]);
    setPendingProject({} as ShowProject);
    setPendingProjectName("");
    setPendingProjectPosition(-1);
    setPendingProjectGoal("");
    setPendingProjectPendingLogoFile(new File([], ""));
    setPendingProjectLogoUrl("");
    setPendingProjectTotalPledgesAmount(0);
    setBackgroundColorLogo("#FFFFFF");
  };

  const submitProjectWrite = () => {
    setPendingProjectIsLoading(true);
    const writeProjectId = pendingProject.id ? pendingProject.id : uuidv4();
    API.writeShowProject(
      editedShow.id,
      writeProjectId,
      pendingProjectName,
      pendingProjectPosition,
      pendingProjectGoal,
      pendingProjectPendingLogoFile,
      pendingProjectLogoUrl,
      pendingProjectTotalPledgesAmount,
      backgroundColorLogo
    )
      .then((status) => {
        setPendingProjectIsLoading(false);
        appCtx.toggleNotification({
          type: "success",
          content: "Mise à jour du projet réussie."
        });
        setTriggerListMode(!triggerListMode);
      })
      .catch((e) => {
        setPendingProjectIsLoading(false);
        appCtx.toggleNotification({
          type: "error",
          content: "Une erreur est survenue."
        });
      });
  };

  const deleteProject = (project: ShowProject) => {
    API.deleteShowProject(
      editedShow.id,
      project.id,
      projectsList.length - 1 <= 0
    )
      .then((status) => {
        setPendingProjectIsLoading(false);
        appCtx.toggleNotification({
          type: "success",
          content: "Projet supprimé."
        });
        setTriggerListMode(!triggerListMode);
      })
      .catch((e) => {
        appCtx.toggleNotification({
          type: "error",
          content: "Une erreur est survenue."
        });
      });
  };

  const submitUpdate = () => {
    setIsLoading(true);
    const correctedPositionsProjectsList: ShowProject[] = [];
    [...projectsList]
      .sort((a, b) => (a.position > b.position ? 1 : -1))
      .map((project, index) => {
        const projectCorrected = project;
        projectCorrected.position = index;
        return correctedPositionsProjectsList.push(projectCorrected);
      });
    API.updateProjectsConfig(editedShow.id, correctedPositionsProjectsList)
      .then((status) => {
        setIsLoading(false);
        appCtx.toggleNotification({
          type: "success",
          content: "Mise à jour des projets réussie."
        });
      })
      .catch((e) => {
        setIsLoading(false);
        appCtx.toggleNotification({
          type: "error",
          content: "Une erreur est survenue."
        });
      });
  };

  const hasNoProjectsInputHandler = (value: boolean) => {
    setHasNoProjects(value);
  };

  const projectsListSetter = (value: ShowProject[]) => {
    setProjectsList(value);
  };

  const pendingProjectSetter = (project: ShowProject) => {
    setPendingProject(project);
  };

  const pendingProjectNameInputHandler = (value: string) => {
    setPendingProjectName(value);
  };

  const pendingProjectPositionSetter = (value: number) => {
    setPendingProjectPosition(value);
  };

  const pendingProjectGoalInputHandler = (value: string) => {
    setPendingProjectGoal(value);
  };

  const pendingProjectPendingLogoFileInputHandler = (file: File) => {
    setPendingProjectPendingLogoFile(file);
  };

  const pendingProjectLogoUrlSetter = (value: string) => {
    setPendingProjectLogoUrl(value);
  };

  const pendingProjectTotalPledgesAmountSetter = (value: number) => {
    setPendingProjectTotalPledgesAmount(value);
  };

  const backgroundColorLogoHandler = (value: string) => {
    setBackgroundColorLogo(value || "#FFFFFF");
  };

  return (
    <EditShowProjectsContext.Provider
      value={{
        editedShow,
        isLoading,
        pendingProjectIsLoading,
        resetContext,
        hasNoProjects,
        hasNoProjectsInputHandler,
        projectsList,
        projectsListSetter,
        pendingProject,
        pendingProjectSetter,
        pendingProjectName,
        pendingProjectNameInputHandler,
        pendingProjectPosition,
        pendingProjectPositionSetter,
        pendingProjectGoal,
        pendingProjectGoalInputHandler,
        pendingProjectPendingLogoFile,
        pendingProjectPendingLogoFileInputHandler,
        pendingProjectLogoUrl,
        pendingProjectLogoUrlSetter,
        pendingProjectTotalPledgesAmount,
        pendingProjectTotalPledgesAmountSetter,
        backgroundColorLogoHandler,
        backgroundColorLogo,
        submitProjectWrite,
        triggerListMode,
        deleteProject,
        submitUpdate
      }}
    >
      {props.children}
    </EditShowProjectsContext.Provider>
  );
};
export default EditShowProjectsContext;
