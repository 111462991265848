import { faDownload, faLink } from "@fortawesome/free-solid-svg-icons";
import classNames from "classnames";
import React, { useContext, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import AppContext from "../../../contexts/app.ctx";
import exportShowData from "../../../helpers/exportShowData";
import BaseLoadingCurtain from "../../Base/BaseLoadingCurtain/BaseLoadingCurtain";
import PageContent from "../../Layout/PageContent/PageContent";
import PageHeader from "../../Layout/PageHeader/PageHeader";
import LiveChatMonitor from "../../LiveManager/LiveChatMonitor/LiveChatMonitor";
import LiveClosingAction from "../../LiveManager/LiveClosingAction/LiveClosingAction";
import LiveCurrentProjectSelector from "../../LiveManager/LiveCurrentProjectSelector/LiveCurrentProjectSelector";
import LivePledgesMonitor from "../../LiveManager/LivePledgesMonitor/LivePledgesMonitor";
import LiveStats from "../../LiveManager/LiveStats/LiveStats";
import ClientUrlConfig from "../../../configs/dons-live-client.config";
import "./LiveManagerPage.css";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import useDocumentTitle from "../../../helpers/changeTabName";

interface ContainerProps {}

export const liveManagerPageRoute = "/:showId/live-manager";

const LiveManagerPage: React.FC<ContainerProps> = (props) => {
  const appCtx = useContext(AppContext);
  const appCtxRef = useRef(useContext(AppContext));
  const { showId } = useParams<Record<string, string>>();
  const baseUrl = ClientUrlConfig();

  useEffect(() => {
    appCtxRef.current.loadCurrentShow(showId);
  }, [showId]);

  const handleCounterOpening = () => {
    window.open(
      `${baseUrl}${appCtx.currentShow.urlSlug}/counter`
    );
  };

  const handleChatOpening = () => {
    window.open(
        `${baseUrl}${appCtx.currentShow.id}/livechat`,
      "_blank"
    );
  };

  useDocumentTitle("Régie Live - Dons.live")

  return (
    <>
      {appCtx.isLoading ? <BaseLoadingCurtain /> : null}
      <div className="live-manager-page" data-testid="live-manager-page">
        <PageHeader
          title="Régie Live"
          actions={[
            {
              name: "Exporter les données",
              action: () =>
                exportShowData(appCtx.currentShow, appCtx.currentShowProjects),
              color: "#2CA795",
              icon: faDownload as IconProp
            },
            {
              name: "Compteur live",
              action: () => handleCounterOpening(),
              color: "#848AAE",
              icon: faLink as IconProp
            },
            {
              name: "Chat live",
              action: () => handleChatOpening(),
              color: "#848AAE",
              icon: faLink as IconProp
            }
          ]}
        />
        <PageContent>
          <div
            className={classNames({
              "live-manager-page__grid": true,
              "live-manager-page__grid--no-projects":
                appCtx.currentShow.hasNoProjects
            })}
          >
            <LiveStats gridId="live-stats" />
            {!appCtx.currentShow.hasNoProjects && (
              <LiveCurrentProjectSelector gridId="live-current-project-selector" />
            )}
            <LivePledgesMonitor gridId="live-pledges-monitor" />
            <LiveChatMonitor gridId="live-chat-monitor" />
            <LiveClosingAction gridId="live-closing-action" />
          </div>
        </PageContent>
      </div>
    </>
  );
};

export default LiveManagerPage;
