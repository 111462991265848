import { faSave } from "@fortawesome/free-solid-svg-icons";
import React, { useContext, useEffect, useRef } from "react";
import PageContent from "../../Layout/PageContent/PageContent";
import PageHeader from "../../Layout/PageHeader/PageHeader";
import RegistrationsCustomConfigurator from "../../ShowRegistrations/RegistrationsCustomConfigurator/RegistrationsCustomConfigurator";
import RegistrationsPresetSelector from "../../ShowRegistrations/RegistrationsPresetSelector/RegistrationsPresetSelector";

import "./EditShowRegistrationsPage.css";
import { useParams } from "react-router-dom";
import AppContext from "../../../contexts/app.ctx";
import BaseLoadingCurtain from "../../Base/BaseLoadingCurtain/BaseLoadingCurtain";
import EditShowRegistrationsContext from "../../../contexts/edit-show-registrations.ctx";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

interface ContainerProps {}

export const editShowRegistrationsPageRoute =
  "/:showId/edit-show-registrations";

const EditShowRegistrationsPage: React.FC<ContainerProps> = (props) => {
  const { showId } = useParams<Record<string, string>>();
  const appCtx = useContext(AppContext);
  const appCtxRef = useRef(useContext(AppContext));
  const editShowRegistrationsCtx = useContext(EditShowRegistrationsContext);

  useEffect(() => {
    appCtxRef.current.loadCurrentShow(showId);
  }, [showId]);

  return (
    <>
      {appCtx.isLoading ? <BaseLoadingCurtain /> : null}
      <div
        className="edit-show-registrations-page"
        data-testid="edit-show-registrations-page"
      >
        <PageHeader
          title="Informations d'inscription"
          actions={[
            {
              name: "Enregistrer",
              action: () => editShowRegistrationsCtx.submitUpdate(),
              color: "#2CA795",
              icon: faSave as IconProp,
              isLoading: editShowRegistrationsCtx.isLoading
            }
          ]}
        />
        <PageContent>
          <div className="edit-show-registrations-page__grid">
            <RegistrationsPresetSelector gridId="registrations-preset-selector" />
            <RegistrationsCustomConfigurator gridId="registrations-custom-configurator" />
          </div>
        </PageContent>
      </div>
    </>
  );
};

export default EditShowRegistrationsPage;
