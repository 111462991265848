import React from "react";
import BaseLoadingCircle from "../BaseLoadingCircle/BaseLoadingCircle";
import "./BaseLoadingCurtain.css";

interface ContainerProps {}

const BaseLoadingCurtain: React.FC<ContainerProps> = (props) => {
  return (
    <div className="base-loading-curtain" data-testid="base-loading-curtain">
      <BaseLoadingCircle />
    </div>
  );
};

export default BaseLoadingCurtain;
