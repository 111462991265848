import React, { useContext } from "react";
import EditShowMediasContext from "../../../contexts/edit-show-medias.ctx";
import BaseCard from "../../Base/BaseCard/BaseCard";
import BaseImageInput from "../../Base/BaseImageInput/BaseImageInput";
import BaseLabel from "../../Base/BaseLabel/BaseLabel";
import "./LogoSelector.css";

interface ContainerProps {
  gridId: string;
}

const LogoSelector: React.FC<ContainerProps> = (props) => {
  const editShowMediasCtx = useContext(EditShowMediasContext);
  return (
    <BaseCard
      id={props.gridId}
      className="logo-selector"
      data-testid="logo-selector"
    >
      <BaseLabel>Logo de l'événement</BaseLabel>
      <BaseImageInput
        presetValue={editShowMediasCtx.existingLogoUrl}
        value={editShowMediasCtx.pendingLogoFile}
        onImageSelected={editShowMediasCtx.pendingLogoFileInputHandler}
      />
    </BaseCard>
  );
};

export default LogoSelector;
