import React, { useContext } from "react";
import PageHeader from "../../Layout/PageHeader/PageHeader";
import PageContent from "../../Layout/PageContent/PageContent";
import BaseLoadingCurtain from "../../Base/BaseLoadingCurtain/BaseLoadingCurtain";
import "./MultiplexesPage.css"
import AppContext from "../../../contexts/app.ctx";
import Multiplex from "../../../models/Multiplex";
import showCoverPlaceholder from "../../../assets/images/show-placeholder.png";
import {useHistory} from "react-router-dom";
import ShowCardCalendar from "../../Shows/ShowCardCalendar/ShowCardCalendar";

export const multiplexesPageRoute = "/multiplexes";

interface ContainerProps {}

const MultiplexesPage: React.FC<ContainerProps> = (props) => {
  const history = useHistory();
  const appCtx = useContext(AppContext);

  return (
    <div className="multiplexes-page">
      <PageHeader title="Mes multiplexes" />
      {appCtx.isLoading ? <BaseLoadingCurtain /> : null}
      <PageContent>
        <div className="multiplexes-page__multiplexes-grid">
          {appCtx.allMultiplexes?.map((multiplex: Multiplex) => {
            return (
              <div
                className="multiplex-card"
                key={multiplex.id}
                style={{
                  backgroundImage: `url(${
                  multiplex.logoUrl ? multiplex.logoUrl : showCoverPlaceholder})`,
                }}
                onClick={() => {
                  appCtx.loadCurrentMultiplex(multiplex.id);
                  history.push(`/${multiplex.id}/edit-multiplex-infos`);
                }}
              >
                <div className="multiplex-card__content">
                  <div className="multiplex-card__content__cal-container">
                    <ShowCardCalendar date={multiplex.startDate} />
                  </div>
                  <div className="multiplex-card__content__infos-container">
                    <div className="multiplex-card__content__infos-container__title">
                      {multiplex.name}
                    </div>
                    <div className="multiplex-card__content__infos-container__subtitle">
                      {multiplex.subtitle}
                    </div>
                  </div>
                </div>
              </div>
            );
          })}

          {appCtx.allMultiplexes.length >= 10 && (
            <div
              onClick={() => appCtx.increaseMultiplexesFetchLimit()}
              className="multiplexes-page__multiplexes-grid__more-multiplexes"
            >
              <span>Charger plus</span>
            </div>
          )}
        </div>
      </PageContent>
    </div>
  );
}

export default MultiplexesPage
