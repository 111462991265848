// NOTE: Auth system is entirely dependent of Firebase Auth System
import React, { useEffect, useState } from "react";
import { $firebaseAuth } from "../services/firebase-service";

export enum AuthStates {
  "AUTH",
  "NOT_AUTH",
  "NOT_INIT",
}

const AuthContext = React.createContext({
  authState: AuthStates.NOT_INIT,
  role: "",
  userId: "",
  userEmail: "",
  signOutHandler: () => {},
});

export const AuthContextProvider: React.FC = (props) => {
  const [authState, setAuthState] = useState(AuthStates.NOT_INIT);
  const [role, setRole] = useState("");
  const [userId, setUserId] = useState("");
  const [userEmail, setUserEmail] = useState("");

  useEffect(() => {
    const authStateChangedUnsubscribe = $firebaseAuth.onAuthStateChanged(
      (user) => {
        if (!user) {
          setAuthState(AuthStates.NOT_AUTH);
          setRole("");
          setUserId("");
          setUserEmail("");
        } else {
          user.getIdTokenResult().then((token) => {

            setAuthState(AuthStates.AUTH);
            setRole(token.claims.role);
            setUserId(user.uid);
            setUserEmail(user.email || "");
          });
        }
      }
    );
    return () => authStateChangedUnsubscribe();
  }, []);

  const signOutHandler = () => {
    console.log("LOGOUT");
    $firebaseAuth.signOut();
  };

  return (
    <AuthContext.Provider
      value={{
        authState,
        role,
        userId,
        userEmail,
        signOutHandler,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
