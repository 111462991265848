import React, { useContext } from "react";
import AppContext from "../../../contexts/app.ctx";
import BaseCard from "../../Base/BaseCard/BaseCard";
import "./LiveStats.css";

interface ContainerProps {
  gridId: string;
}

const LiveStats: React.FC<ContainerProps> = (props) => {
  const appCtx = useContext(AppContext);

  return (
    <BaseCard id={props.gridId} className="live-stats" data-testid="live-stats">
      <span style={{ opacity: 0.5 }}>
        En Ligne : <span>-</span>
      </span>
      <span>
        Inscriptions : <span>{appCtx.currentShow.totalUsersCount}</span>
      </span>
      <span>
        Don Moyen :{" "}
        <span>
          {isNaN(appCtx.currentShow.averagePledgesAmount / 100)
            ? "0 " + appCtx.currentShowCurrencySymbol
            : (appCtx.currentShow.averagePledgesAmount / 100).toLocaleString(
                undefined,
                { maximumFractionDigits: 0 }
              ) +
              " " +
              appCtx.currentShowCurrencySymbol}
        </span>
      </span>
      <span>
        Total :{" "}
        <span>
          {(appCtx.currentShow.totalPledgesAmount / 100).toLocaleString(
            undefined,
            { maximumFractionDigits: 0 }
          ) +
            " " +
            appCtx.currentShowCurrencySymbol}
        </span>
      </span>
    </BaseCard>
  );
};

export default LiveStats;
