import baseLogo from "../../../assets/logo/base.png";
import "./BaseLogo.css";

interface ContainerProps {}

const BaseLogo: React.FC<ContainerProps> = (props) => {
  return (
    <img
      className="base-logo"
      data-testid="base-logo"
      alt="obole-logo"
      src={baseLogo}
    />
  );
};

export default BaseLogo;
