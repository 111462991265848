import React, {useCallback, useContext, useEffect, useState} from "react";
import AppContext from "../../../contexts/app.ctx";
import EditMultiplexDashboardsContext, {
  DashboardProject,
  MultiplexDashboard
} from "../../../contexts/edit-multiplex-dashboards.ctx";
import classNames from "classnames";
import BaseCard from "../../Base/BaseCard/BaseCard";
import BaseLabel from "../../Base/BaseLabel/BaseLabel";
import BaseButton from "../../Base/BaseButton/BaseButton";
import {faEdit, faMinusCircle, faPlus, faTrash} from "@fortawesome/free-solid-svg-icons";
import {IconProp} from "@fortawesome/fontawesome-svg-core";
import BaseImageInput from "../../Base/BaseImageInput/BaseImageInput";
import BaseInput from "../../Base/BaseInput/BaseInput";
import "./DashboardsConfigurator.css"
import BaseSelectInput from "../../Base/BaseSelectInput/BaseSelectInput";
import BaseIcon from "../../Base/BaseIcon/BaseIcon";
import API from "../../../services";
import {ShowProject} from "../../../contexts/edit-show-projects.ctx";
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";

interface ContainerProps {
  gridId: string
}

enum ConfiguratorMode {
  "LIST",
  "UPDATE",
  "CREATE"
}

const DashboardsConfigurator: React.FC<ContainerProps> = (props) => {
  const appCtx = useContext(AppContext);
  const editMultiplexDashboardsCtx = useContext(EditMultiplexDashboardsContext);
  const [configuratorMode, setConfiguratorMode] = useState(
    ConfiguratorMode.LIST
  );

  useEffect(() => {
    setConfiguratorMode(ConfiguratorMode.LIST);
  }, [editMultiplexDashboardsCtx.triggerListMode]);

  useEffect(() => {
    editMultiplexDashboardsCtx.dashboardsListSetter(appCtx.currentMultiplexDashboards);
  }, [editMultiplexDashboardsCtx, appCtx.currentMultiplexDashboards]);

  const openDashboardUpdater = (dashboard: MultiplexDashboard) => {
    editMultiplexDashboardsCtx.pendingDashboardSetter(dashboard);
    editMultiplexDashboardsCtx.pendingDashboardNameInputHandler(dashboard.name);
    editMultiplexDashboardsCtx.pendingDashboardLogoUrlSetter(dashboard.logoUrl);
    editMultiplexDashboardsCtx.pendingDashboardProjectsHandler(dashboard.selectedProjects)
    setConfiguratorMode(ConfiguratorMode.UPDATE);
  };

  const askForDashboardDeletion = (dashboard: MultiplexDashboard) => {
    const deletionConfirmation = window.confirm(
      "Souhaitez vous réellement supprimer le compteur ?"
    );
    if (deletionConfirmation) {
      editMultiplexDashboardsCtx.deleteDashboard(dashboard);
    }
  };

  const resetDashboardEditorContext = () => {
    editMultiplexDashboardsCtx.pendingDashboardSetter({} as MultiplexDashboard);
    editMultiplexDashboardsCtx.pendingDashboardNameInputHandler("");
    editMultiplexDashboardsCtx.pendingDashboardLogoUrlSetter("");
    editMultiplexDashboardsCtx.pendingDashboardPendingLogoFileInputHandler(
      new File([], " ")
    );
    editMultiplexDashboardsCtx.pendingDashboardProjectsHandler([])
  };

  const [projectsList, setProjectsList] = useState([] as ShowProject[])

  useEffect(() => {
    API.getMultiplexProjectsList(
      appCtx.currentMultiplex.participatingShows,
      (showProjects: ShowProject[]) => {
        setProjectsList(showProjects)
      })
  }, [appCtx.currentMultiplex.participatingShows]);

  useEffect(() => {
    editMultiplexDashboardsCtx.pendingDashboardGoalHandler(
      editMultiplexDashboardsCtx.pendingDashboardProjects.reduce((a, b) => {
        return a + b["goal"]
      }, 0)
    )
  }, [editMultiplexDashboardsCtx])

  const handleRemoveEvent = (target: DashboardProject) => {
    editMultiplexDashboardsCtx.removedDashboardProjectsHandler(
      [...editMultiplexDashboardsCtx.removedDashboardProjects, target]
    )
    editMultiplexDashboardsCtx.pendingDashboardProjectsHandler(
      editMultiplexDashboardsCtx.pendingDashboardProjects.filter(item => item.id !== target.id)
    );
  };

  const onDragEnd = useCallback(
    (result) => {
      if (!result?.destination) {
        return;
      }
      const updatedArray = [...editMultiplexDashboardsCtx.dashboardsList].sort((a, b) =>
        a.position > b.position ? 1 : -1
      );
      const [removed] = updatedArray.splice(result.source.index, 1);
      updatedArray.splice(result.destination.index, 0, removed);
      const updatedList = updatedArray.map((item, index) => {
        item.position = index;
        return item;
      });
      editMultiplexDashboardsCtx.dashboardsListSetter(updatedList);
    },
    [editMultiplexDashboardsCtx]
  );

  return (
    <BaseCard
      id={props.gridId}
      className={classNames({
        "dashboards-configurator": true,
        "dashboards-configurator--editor":
          configuratorMode === ConfiguratorMode.CREATE ||
          configuratorMode === ConfiguratorMode.UPDATE
      })}
    >
      <div className="dashboards-configurator__header">
        <BaseLabel>
          {configuratorMode === ConfiguratorMode.LIST
            ? "Liste des compteurs"
            : configuratorMode === ConfiguratorMode.CREATE
              ? "Ajouter compteur"
              : "Modifier compteur"}
        </BaseLabel>
        {configuratorMode === ConfiguratorMode.LIST && (
          <BaseButton
            mobileCondensed
            name="Ajouter"
            onClick={() => {
              resetDashboardEditorContext();
              setConfiguratorMode(ConfiguratorMode.CREATE);
            }}
            icon={faPlus as IconProp}
            color="#848AAE"
          />
        )}
      </div>

      {configuratorMode === ConfiguratorMode.CREATE ||
      configuratorMode === ConfiguratorMode.UPDATE ? (
        <div className="dashboards-configurator__editor">
          <div
            id="dashboard-logo-editor"
            className="dashboards-configurator__editor__logo-editor"
          >
            <BaseLabel small>Logo du compteur</BaseLabel>
            <BaseImageInput
              presetValue={editMultiplexDashboardsCtx.pendingDashboardLogoUrl}
              onImageSelected={(file: File) =>
                editMultiplexDashboardsCtx.pendingDashboardPendingLogoFileInputHandler(
                  file
                )
              }
              value={editMultiplexDashboardsCtx.pendingDashboardPendingLogoFile}
              onImageDelete={() => editMultiplexDashboardsCtx.pendingDashboardLogoUrlSetter("")}
            />
          </div>

          <div
            id="dashboard-editor-form"
            className="dashboards-configurator__editor__form"
          >
            <div className="dashboards-configurator__editor__form__input">
              <BaseInput
                onValueChange={(value: string) =>
                  editMultiplexDashboardsCtx.pendingDashboardNameInputHandler(value)
                }
                value={editMultiplexDashboardsCtx.pendingDashboardName}
                label="Nom du compteur"
              />
            </div>
            <div className="dashboards-configurator__editor__form__input">
              <BaseSelectInput
                label="Projets"
                value={""}
                onChangeValue={(v: string) => {
                  if (editMultiplexDashboardsCtx.pendingDashboardProjects.filter(project => project.id === v).length === 0) {
                    const target = projectsList.find(project => project.id === v)
                    if (target) {
                      const addedProject = {
                        id: target.id,
                        name: target.name,
                        parentShowId: target.parentShowId as string,
                        place: target.place as string,
                        goal: target.goal,
                      }
                      editMultiplexDashboardsCtx.pendingDashboardProjectsHandler(
                        [...editMultiplexDashboardsCtx.pendingDashboardProjects, addedProject]
                      )
                      editMultiplexDashboardsCtx.removedDashboardProjectsHandler(
                        editMultiplexDashboardsCtx.removedDashboardProjects.filter(item => item.id !== target.id)
                      );
                    }
                  }
                }}
                options={projectsList.filter(project =>
                  editMultiplexDashboardsCtx.pendingDashboardProjects.find(item => item.id === project.id) === undefined)
                  .map(project => {
                  return {name: `[ ${project.place} ] ${project.name}`, value: project.id}
                })}
                default=" -- sélectionez un projet pour l'ajouter -- "
              />
              <div className="dashboards-configurator__list">
                {editMultiplexDashboardsCtx.pendingDashboardProjects.map(pendingProject => {
                  const target = projectsList.find(project => project.id === pendingProject.id)
                  if (target) {
                    return (
                      <div key={pendingProject.id} className="dashboards-configurator__project" >
                        <button className="dashboards-configurator__project__remove"
                                onClick={() => handleRemoveEvent(pendingProject)}
                        >
                          <BaseIcon icon={faMinusCircle as IconProp} size={"20px"} />
                        </button>
                        <span className="dashboards-configurator__project__title">
                          <b>[ {target.place} ]</b> {target.name}
                      </span>
                      </div>
                    )
                  }
                  return <></>
                })
                }
              </div>
            </div>
            <div className="dashboards-configurator__editor__form__actions">
              <BaseButton
                name="Annuler"
                color="#CE193D"
                onClick={() => {
                  resetDashboardEditorContext();
                  setConfiguratorMode(ConfiguratorMode.LIST);
                }}
              />
              <BaseButton
                margin="0 0 0 16px"
                name="Enregistrer"
                color="#2CA795"
                onClick={() => {
                  editMultiplexDashboardsCtx.submitDashboardWrite(
                    configuratorMode === ConfiguratorMode.UPDATE,
                    editMultiplexDashboardsCtx.dashboardsList.length
                  )
                  editMultiplexDashboardsCtx.pendingDashboardProjectsHandler([])
                }}
                isLoading={editMultiplexDashboardsCtx.pendingDashboardIsLoading}
              />
            </div>
          </div>
        </div>
      ) : (
        <div className="dashboards-configurator__configurator">
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppable">
              {(provided, snapshot) => (
                <div {...provided.droppableProps} ref={provided.innerRef}>
                  {[...editMultiplexDashboardsCtx.dashboardsList]
                    .sort((a, b) => (a.position > b.position ? 1 : -1))
                    .map((dashboard, index) => {
                      return (
                        <Draggable
                          key={dashboard.id}
                          draggableId={dashboard.id}
                          index={index}
                        >
                          {(provided, snapshot) => (
                            <div
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              className="dashboards-configurator__configurator__item"
                              key={dashboard.id}
                              ref={provided.innerRef}
                            >
                              <div className="projects-configurator__configurator__item__heading">
                                <div
                                  className="projects-configurator__configurator__item__heading__logo"
                                  style={{
                                    backgroundImage: `url(${dashboard.logoUrl})`
                                  }}
                                ></div>
                                <div className="projects-configurator__configurator__item__heading__infos">
                                  <span>{dashboard.name}</span>
                                  <span>
                                    Objectif :{" "}
                                    <span>
                                      {(dashboard.goal / 100).toLocaleString(
                                          undefined,
                                          { maximumFractionDigits: 0 }
                                        ) +
                                        " " +
                                        appCtx.currentMultiplexCurrencySymbol}
                                    </span>
                                  </span>
                                </div>
                              </div>
                              <div className="projects-configurator__configurator__item__actions">
                                <BaseButton
                                  mobileCondensed
                                  name="Supprimer"
                                  onClick={() => askForDashboardDeletion(dashboard)}
                                  icon={faTrash as IconProp}
                                  color="#CE193D"
                                  margin="0 8px 0 0"
                                />
                                <BaseButton
                                  mobileCondensed
                                  name="Modifier"
                                  onClick={() => openDashboardUpdater(dashboard)}
                                  icon={faEdit as IconProp}
                                  color="cornflowerblue"
                                />
                              </div>
                            </div>
                          )}
                        </Draggable>
                      );
                    })}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </div>
      )}
    </BaseCard>
  )
}

export default DashboardsConfigurator