import { faSave } from "@fortawesome/free-solid-svg-icons";
import React, { useContext, useEffect, useRef } from "react";
import PageContent from "../../Layout/PageContent/PageContent";
import PageHeader from "../../Layout/PageHeader/PageHeader";
// import NoProjectsSelector from "../../ShowProjects/NoProjectsSelector/NoProjectsSelector";
import ProjectsConfigurator from "../../ShowProjects/ProjectsConfigurator/ProjectsConfigurator";

import "./EditShowProjectsPage.css";
import { useParams } from "react-router-dom";
import AppContext from "../../../contexts/app.ctx";
import BaseLoadingCurtain from "../../Base/BaseLoadingCurtain/BaseLoadingCurtain";
import EditShowProjectsContext from "../../../contexts/edit-show-projects.ctx";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

interface ContainerProps {}

export const editShowProjectsPageRoute = "/:showId/edit-show-projects";

const EditShowProjectsPage: React.FC<ContainerProps> = (props) => {
  const appCtx = useContext(AppContext);
  const appCtxRef = useRef(useContext(AppContext));
  const editShowProjectsCtx = useContext(EditShowProjectsContext);
  const { showId } = useParams<Record<string, string>>();

  useEffect(() => {
    appCtxRef.current.loadCurrentShow(showId);
  }, [showId]);

  return (
    <>
      {appCtx.isLoading ? <BaseLoadingCurtain /> : null}
      <div
        className="edit-show-projects-page"
        data-testid="edit-show-projects-page"
      >
        <PageHeader
          title="Projets de l'événement"
          actions={[
            {
              name: "Enregistrer l'ordre",
              action: () => editShowProjectsCtx.submitUpdate(),
              color: "#2CA795",
              icon: faSave as IconProp,
              isLoading: editShowProjectsCtx.isLoading
            }
          ]}
        />
        <PageContent>
          <div className="edit-show-projects-page__grid">
            {/* <NoProjectsSelector gridId="no-projects-selector" /> */}
            <ProjectsConfigurator gridId="projects-configurator" />
          </div>
        </PageContent>
      </div>
    </>
  );
};

export default EditShowProjectsPage;
