import { IconProp } from "@fortawesome/fontawesome-svg-core";
import {
  faCheckCircle,
  faTimesCircle
} from "@fortawesome/free-solid-svg-icons";
import classNames from "classnames";
import React from "react";
import BaseCard from "../BaseCard/BaseCard";
import BaseIcon from "../BaseIcon/BaseIcon";
import "./BaseNotification.css";

interface ContainerProps {
  isActive: boolean;
  type: "success" | "error";
  content: string;
}

const BaseNotification: React.FC<ContainerProps> = (props) => {
  return (
    <div
      className={classNames({
        "base-notification": true,
        "base-notification--is-active": props.isActive,
        "base-notification--success": props.type === "success",
        "base-notification--error": props.type === "error"
      })}
      data-testid="base-notification"
    >
      <BaseCard className="base-notification__notification">
        <BaseIcon
          icon={
            props.type === "success"
              ? (faCheckCircle as IconProp)
              : (faTimesCircle as IconProp)
          }
          color={props.type === "success" ? "#2CA795" : "#ce193d"}
          size="36px"
        />
        <div className="base-notification__notification__content">
          {props.content}
        </div>
      </BaseCard>
    </div>
  );
};

export default BaseNotification;
