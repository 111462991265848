import { IconProp } from "@fortawesome/fontawesome-svg-core";
import classNames from "classnames";
import BaseIcon from "../BaseIcon/BaseIcon";
import BaseLoadingCircle from "../BaseLoadingCircle/BaseLoadingCircle";
import "./BaseButton.css";

interface ContainerProps {
  name: string;
  onClick: Function;
  color?: string;
  margin?: string;
  icon?: IconProp;
  mobileCondensed?: boolean;
  extended?: boolean;
  isLoading?: boolean;
  disabled?: boolean;
}

const BaseButton: React.FC<ContainerProps> = (props) => {
  return (
    <button
      className={classNames({
        "base-button": true,
        "base-button--mobile-condensed": props.mobileCondensed && props.icon,
        "base-button--disabled": props.disabled || props.isLoading,
        "base-button--is-loading": props.isLoading,
      })}
      style={{
        backgroundColor: props.color || "cornflowerblue",
        color: "white",
        margin: props.margin || "0",
        width: props.extended ? "100%" : undefined,
      }}
      onClick={() => props.onClick()}
      disabled={props.disabled || props.isLoading}
    >
      <div className="base-button__content">
        {props.icon && (
          <span className="base-button__content__icon">
            <BaseIcon icon={props.icon} size="14px" />
          </span>
        )}
        {props.isLoading && (
          <div className="base-button__content__loading">
            <BaseLoadingCircle color="white" mini />
          </div>
        )}
        <span className="base-button__content__text">{props.name}</span>
      </div>
    </button>
  );
};

export default BaseButton;
