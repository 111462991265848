import React, { useContext, useEffect, useState } from "react";
import AppContext from "./app.ctx";
import API from "../services";
import Multiplex from "../models/Multiplex";

const EditMultiplexInfosContext = React.createContext({
  editedMultiplex: {} as Multiplex,
  isLoading: false,
  resetContext: () => {},
  name: "",
  nameInputHandler: (value: string) => {},
  subtitle: "",
  subtitleInputHandler: (value: string) => {},
  startDate: new Date(),
  startDateInputHandler: (value: Date) => {},
  existingLogoUrl: "",
  existingLogoUrlSetter: (value: string) => {},
  pendingLogoFile: new File([], ""),
  pendingLogoFileInputHandler: (file: File) => {},
  participatingShows: [] as string[],
  participatingShowsInputHandler: (value: string[]) => {},
  removedShows: [] as string [],
  removedShowsInputHandler: (value: string[]) => {},
  pledgesCurrency: "",
  pledgesCurrencyInputHandler: (value: string) => {},
  backgroundColorDark: "#00112D",
  backgroundColorDarkHandler: (value: string) => {},
  backgroundColorLight: "#0146FC",
  backgroundColorLightHandler: (value: string) => {},
  fontColor: "#fff",
  fontColorHandler: (value: string) => {},
  fontColorHighlight: "#FF4700",
  fontColorHighlightHandler: (value: string) => {},
  globalMultiplexGoal: 0,
  globalMultiplexGoalHandler: (value: number) => {},
  initialShowsList: [] as string [],
  submitUpdate: () => {}
});

export const EditMultiplexInfosContextProvider: React.FC = (props) => {
  const [editedMultiplex, setEditedMultiplex] = useState({} as Multiplex)
  const [isLoading, setIsLoading] = useState(false)
  const [name, setName] = useState("")
  const [subtitle, setSubtitle] = useState("")
  const [startDate, setStartDate] = useState(new Date());
  const [existingLogoUrl, setExistingLogoUrl] = useState("");
  const [pendingLogoFile, setPendingLogoFile] = useState<File>(
    new File([], "")
  );
  const [participatingShows, setParticipatingShows] = useState<string[]>([])
  const [removedShows, setRemovedShows] = useState<string[]>([])
  const [pledgesCurrency, setPledgesCurrency] = useState("EUR")
  const [backgroundColorDark, setBackgroundColorDark] = useState("")
  const [backgroundColorLight, setBackgroundColorLight] = useState("")
  const [fontColor, setFontColor] = useState("")
  const [fontColorHighlight, setFontColorHighlight] = useState("")
  const [globalMultiplexGoal, setGlobalMultiplexGoal] = useState(0)

  const [initialShowsList, setInitialShowsList] = useState<string[]>([])

  const appCtx = useContext(AppContext);

  const resetContext = () => {
    setIsLoading(false)
    setName("")
    setSubtitle("")
    setStartDate(new Date())
    setExistingLogoUrl("")
    setPendingLogoFile(new File([], ""))
    setParticipatingShows([])
    setRemovedShows([])
    setBackgroundColorDark("#00112D")
    setBackgroundColorLight("#0146FC")
    setFontColor("#fff")
    setFontColorHighlight("#FF4700")
    setInitialShowsList([])
    setGlobalMultiplexGoal(0)
  }

  useEffect(() => {
    if (appCtx.currentMultiplex.id) {
      setEditedMultiplex(appCtx.currentMultiplex)
      setIsLoading(false)
      setName(appCtx.currentMultiplex.name)
      setSubtitle(appCtx.currentMultiplex.subtitle)
      setStartDate(appCtx.currentMultiplex.startDate);
      setExistingLogoUrl(appCtx.currentMultiplex.logoUrl)
      setPendingLogoFile(new File([], ""))
      setParticipatingShows(appCtx.currentMultiplex.participatingShows)
      setPledgesCurrency(appCtx.currentMultiplex.pledgesCurrency)
      setBackgroundColorDark(appCtx.currentMultiplex.backgroundColorDark)
      setBackgroundColorLight(appCtx.currentMultiplex.backgroundColorLight)
      setFontColor(appCtx.currentMultiplex.fontColor)
      setFontColorHighlight(appCtx.currentMultiplex.fontColorHighlight)
      setInitialShowsList(appCtx.currentMultiplex.participatingShows)
      setGlobalMultiplexGoal(0)
    }
    return () => resetContext();
  }, [appCtx.currentMultiplex]);

  const nameInputHandler = (value: string) => {
    setName(value)
  };
  const subtitleInputHandler = (value: string) => {
    setSubtitle(value)
  };
  const startDateInputHandler = (value: Date) => {
    setStartDate(value)
  }
  const existingLogoUrlSetter = (value: string) => {
    setExistingLogoUrl(value);
  };
  const pendingLogoFileInputHandler = (file: File) => {
    setPendingLogoFile(file);
  };
  const participatingShowsInputHandler = (value: string[]) => {
    setParticipatingShows(value)
  };
  const removedShowsInputHandler = (value: string[]) => {
    setRemovedShows(value)
  }
  const pledgesCurrencyInputHandler = (value: string) => {
    setPledgesCurrency(value);
  };

  const backgroundColorDarkHandler = (value: string) => {
    setBackgroundColorDark(value)
  }
  const backgroundColorLightHandler = (value: string) => {
    setBackgroundColorLight(value)
  }
  const fontColorHandler = (value: string) => {
    setFontColor(value)
  }
  const fontColorHighlightHandler = (value: string) => {
    setFontColorHighlight(value)
  }
  const globalMultiplexGoalHandler = (value: number) => {
    setGlobalMultiplexGoal(value)
  }

  const submitUpdate = () => {
    setIsLoading(true);
    API.updateMultiplexInfos(
      editedMultiplex.id,
      name,
      subtitle,
      startDate,
      existingLogoUrl,
      pendingLogoFile,
      participatingShows,
      pledgesCurrency,
      backgroundColorDark,
      backgroundColorLight,
      fontColor,
      fontColorHighlight,
      globalMultiplexGoal
    )
      .then((status) => {
        setIsLoading(false);
        appCtx.toggleNotification({
          type: "success",
          content: "Mise à jour de l'événement réussie."
        });
      })
      .catch((e) => {
        setIsLoading(false);
        appCtx.toggleNotification({
          type: "error",
          content: "Une erreur est survenue."
        });
      });
  };

  return (
    <EditMultiplexInfosContext.Provider
      value={{
        editedMultiplex,
        isLoading,
        resetContext,
        name,
        nameInputHandler,
        subtitle,
        subtitleInputHandler,
        startDate,
        startDateInputHandler,
        existingLogoUrl,
        existingLogoUrlSetter,
        pendingLogoFile,
        pendingLogoFileInputHandler,
        participatingShows,
        participatingShowsInputHandler,
        removedShows,
        removedShowsInputHandler,
        pledgesCurrency,
        pledgesCurrencyInputHandler,
        backgroundColorDark,
        backgroundColorDarkHandler,
        backgroundColorLight,
        backgroundColorLightHandler,
        fontColor,
        fontColorHandler,
        fontColorHighlight,
        fontColorHighlightHandler,
        globalMultiplexGoal,
        globalMultiplexGoalHandler,
        initialShowsList,
        submitUpdate
      }}
    >
      {props.children}
    </EditMultiplexInfosContext.Provider>
  )
}

export default EditMultiplexInfosContext;