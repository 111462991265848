import React, { useContext, useState } from "react";
import BaseCard from "../../Base/BaseCard/BaseCard";
import BaseLabel from "../../Base/BaseLabel/BaseLabel";
import BaseToggle from "../../Base/BaseToggle/BaseToggle";
import API from "../../../services";
import "./LiveClosingAction.css";
import AppContext from "../../../contexts/app.ctx";

interface ContainerProps {
  gridId: string;
}

const LiveClosingAction: React.FC<ContainerProps> = (props) => {
  const appCtx = useContext(AppContext);
  const [isLoading, setIsLoading] = useState(false);

  const handleClosingStatusChange = () => {
    if (!isLoading) {
      const closingConfirmation = window.confirm(
        appCtx.currentShow.isClosed
          ? "Souhaitez vous réellement ouvrir l'événement ?"
          : "Souhaitez vous réellement fermer l'événement ?"
      );
      if (closingConfirmation) {
        setIsLoading(true);
        API.changeShowClosingStatus(
          appCtx.currentShow.id,
          !appCtx.currentShow.isClosed
        )
          .then((status) => {
            appCtx.toggleNotification({
              type: "success",
              content: "Evénement fermé !",
            });
            setIsLoading(false);
          })
          .catch((e) => {
            appCtx.toggleNotification({
              type: "error",
              content: "Une erreur est survenue.",
            });
            setIsLoading(false);
          });
      }
    }
  };

  return (
    <BaseCard
      id={props.gridId}
      className="live-closing-action"
      data-testid="live-closing-action"
    >
      <BaseLabel>
        <span style={{ color: "#CE193D" }}>Fermer l'événement</span>
      </BaseLabel>
      <BaseToggle
        value={appCtx.currentShow.isClosed}
        onClick={() => handleClosingStatusChange()}
      />
    </BaseCard>
  );
};

export default LiveClosingAction;
