import React, { useContext } from "react";
import EditShowRegistrationsContext from "../../../contexts/edit-show-registrations.ctx";
import BaseCard from "../../Base/BaseCard/BaseCard";
import BaseLabel from "../../Base/BaseLabel/BaseLabel";
import BaseToggle from "../../Base/BaseToggle/BaseToggle";
import "./RegistrationsPresetSelector.css";

interface ContainerProps {
  gridId: string;
}

const RegistrationsPresetSelector: React.FC<ContainerProps> = (props) => {
  const editShowRegistrationsCtx = useContext(EditShowRegistrationsContext);

  return (
    <BaseCard
      id={props.gridId}
      className="registrations-preset-selector"
      data-testid="registrations-preset-selector"
    >
      <BaseLabel>Champs prédéfinis</BaseLabel>
      <div className="registrations-preset-selector__toggle-labels">
        <div className="registrations-preset-selector__toggle-labels__labels">
          <span>Activé</span>
          <span>Obligatoire</span>
        </div>
      </div>
      <div className="registrations-preset-selector__form">
        <div className="registrations-preset-selector__form__toggle">
          <BaseLabel small>Prénom</BaseLabel>
          <div className="registrations-preset-selector__form__toggle__toggles">
            <BaseToggle
              value={editShowRegistrationsCtx.isFirstnamePresetOn}
              onClick={() => {
                editShowRegistrationsCtx.isFirstnamePresetOnInputHandler(
                  !editShowRegistrationsCtx.isFirstnamePresetOn
                );

                editShowRegistrationsCtx.isFirstnamePresetRequiredInputHandler(
                  !editShowRegistrationsCtx.isFirstnamePresetOn ? true : false
                );
              }}
            />
            <BaseToggle
              value={editShowRegistrationsCtx.isFirstnamePresetRequired}
              onClick={() => {
                editShowRegistrationsCtx.isFirstnamePresetRequiredInputHandler(
                  !editShowRegistrationsCtx.isFirstnamePresetRequired
                );
                !editShowRegistrationsCtx.isFirstnamePresetOn &&
                  editShowRegistrationsCtx.isFirstnamePresetOnInputHandler(
                    true
                  );
              }}
            />
          </div>
        </div>
        <div className="registrations-preset-selector__form__toggle">
          <BaseLabel small>Nom</BaseLabel>
          <div className="registrations-preset-selector__form__toggle__toggles">
            <BaseToggle
              value={editShowRegistrationsCtx.isLastnamePresetOn}
              onClick={() => {
                editShowRegistrationsCtx.isLastnamePresetOnInputHandler(
                  !editShowRegistrationsCtx.isLastnamePresetOn
                );

                editShowRegistrationsCtx.isLastnamePresetRequiredInputHandler(
                  !editShowRegistrationsCtx.isLastnamePresetOn ? true : false
                );
              }}
            />
            <BaseToggle
              value={editShowRegistrationsCtx.isLastnamePresetRequired}
              onClick={() => {
                editShowRegistrationsCtx.isLastnamePresetRequiredInputHandler(
                  !editShowRegistrationsCtx.isLastnamePresetRequired
                );
                !editShowRegistrationsCtx.isLastnamePresetOn &&
                  editShowRegistrationsCtx.isLastnamePresetOnInputHandler(true);
              }}
            />
          </div>
        </div>
        <div className="registrations-preset-selector__form__toggle">
          <BaseLabel small>Pseudo (chat)</BaseLabel>
          <div className="registrations-preset-selector__form__toggle__toggles">
            <BaseToggle
              value={editShowRegistrationsCtx.isPseudoPresetOn}
              onClick={() => {
                editShowRegistrationsCtx.isPseudoPresetOnInputHandler(
                  !editShowRegistrationsCtx.isPseudoPresetOn
                );

                editShowRegistrationsCtx.isPseudoPresetRequiredInputHandler(
                  !editShowRegistrationsCtx.isPseudoPresetOn ? true : false
                );
              }}
            />
            <BaseToggle
              value={editShowRegistrationsCtx.isPseudoPresetRequired}
              onClick={() => {
                editShowRegistrationsCtx.isPseudoPresetRequiredInputHandler(
                  !editShowRegistrationsCtx.isPseudoPresetRequired
                );
                !editShowRegistrationsCtx.isPseudoPresetOn &&
                  editShowRegistrationsCtx.isPseudoPresetOnInputHandler(true);
              }}
            />
          </div>
        </div>
        <div className="registrations-preset-selector__form__toggle">
          <BaseLabel small>Téléphone</BaseLabel>
          <div className="registrations-preset-selector__form__toggle__toggles">
            <BaseToggle
              value={editShowRegistrationsCtx.isPhonePresetOn}
              onClick={() => {
                editShowRegistrationsCtx.isPhonePresetOnInputHandler(
                  !editShowRegistrationsCtx.isPhonePresetOn
                );

                editShowRegistrationsCtx.isPhonePresetRequiredInputHandler(
                  !editShowRegistrationsCtx.isPhonePresetOn ? true : false
                );
              }}
            />
            <BaseToggle
              value={editShowRegistrationsCtx.isPhonePresetRequired}
              onClick={() => {
                editShowRegistrationsCtx.isPhonePresetRequiredInputHandler(
                  !editShowRegistrationsCtx.isPhonePresetRequired
                );
                !editShowRegistrationsCtx.isPhonePresetOn &&
                  editShowRegistrationsCtx.isPhonePresetOnInputHandler(true);
              }}
            />
          </div>
        </div>
        <div className="registrations-preset-selector__form__toggle">
          <BaseLabel small>Adresse 1</BaseLabel>
          <div className="registrations-preset-selector__form__toggle__toggles">
            <BaseToggle
              value={editShowRegistrationsCtx.isAddressFirstLinePresetOn}
              onClick={() => {
                editShowRegistrationsCtx.isAddressFirstLinePresetOnInputHandler(
                  !editShowRegistrationsCtx.isAddressFirstLinePresetOn
                );

                editShowRegistrationsCtx.isAddressFirstLinePresetRequiredInputHandler(
                  !editShowRegistrationsCtx.isAddressFirstLinePresetOn
                    ? true
                    : false
                );
              }}
            />
            <BaseToggle
              value={editShowRegistrationsCtx.isAddressFirstLinePresetRequired}
              onClick={() => {
                editShowRegistrationsCtx.isAddressFirstLinePresetRequiredInputHandler(
                  !editShowRegistrationsCtx.isAddressFirstLinePresetRequired
                );
                !editShowRegistrationsCtx.isAddressFirstLinePresetOn &&
                  editShowRegistrationsCtx.isAddressFirstLinePresetOnInputHandler(
                    true
                  );
              }}
            />
          </div>
        </div>
        <div className="registrations-preset-selector__form__toggle">
          <BaseLabel small>Adresse 2</BaseLabel>
          <div className="registrations-preset-selector__form__toggle__toggles">
            <BaseToggle
              value={editShowRegistrationsCtx.isAddressSecondLinePresetOn}
              onClick={() => {
                editShowRegistrationsCtx.isAddressSecondLinePresetOnInputHandler(
                  !editShowRegistrationsCtx.isAddressSecondLinePresetOn
                );

                editShowRegistrationsCtx.isAddressSecondLinePresetRequiredInputHandler(
                  !editShowRegistrationsCtx.isAddressSecondLinePresetOn
                    ? true
                    : false
                );
              }}
            />
            <BaseToggle
              value={editShowRegistrationsCtx.isAddressSecondLinePresetRequired}
              onClick={() => {
                editShowRegistrationsCtx.isAddressSecondLinePresetRequiredInputHandler(
                  !editShowRegistrationsCtx.isAddressSecondLinePresetRequired
                );
                !editShowRegistrationsCtx.isAddressSecondLinePresetOn &&
                  editShowRegistrationsCtx.isAddressSecondLinePresetOnInputHandler(
                    true
                  );
              }}
            />
          </div>
        </div>
        <div className="registrations-preset-selector__form__toggle">
          <BaseLabel small>Code Postal</BaseLabel>
          <div className="registrations-preset-selector__form__toggle__toggles">
            <BaseToggle
              value={editShowRegistrationsCtx.isZipcodePresetOn}
              onClick={() => {
                editShowRegistrationsCtx.isZipcodePresetOnInputHandler(
                  !editShowRegistrationsCtx.isZipcodePresetOn
                );

                editShowRegistrationsCtx.isZipcodePresetRequiredInputHandler(
                  !editShowRegistrationsCtx.isZipcodePresetOn ? true : false
                );
              }}
            />
            <BaseToggle
              value={editShowRegistrationsCtx.isZipcodePresetRequired}
              onClick={() => {
                editShowRegistrationsCtx.isZipcodePresetRequiredInputHandler(
                  !editShowRegistrationsCtx.isZipcodePresetRequired
                );
                !editShowRegistrationsCtx.isZipcodePresetOn &&
                  editShowRegistrationsCtx.isZipcodePresetOnInputHandler(true);
              }}
            />
          </div>
        </div>
        <div className="registrations-preset-selector__form__toggle">
          <BaseLabel small>Ville</BaseLabel>
          <div className="registrations-preset-selector__form__toggle__toggles">
            <BaseToggle
              value={editShowRegistrationsCtx.isCityPresetOn}
              onClick={() => {
                editShowRegistrationsCtx.isCityPresetOnInputHandler(
                  !editShowRegistrationsCtx.isCityPresetOn
                );

                editShowRegistrationsCtx.isCityPresetRequiredInputHandler(
                  !editShowRegistrationsCtx.isCityPresetOn ? true : false
                );
              }}
            />
            <BaseToggle
              value={editShowRegistrationsCtx.isCityPresetRequired}
              onClick={() => {
                editShowRegistrationsCtx.isCityPresetRequiredInputHandler(
                  !editShowRegistrationsCtx.isCityPresetRequired
                );
                !editShowRegistrationsCtx.isCityPresetOn &&
                  editShowRegistrationsCtx.isCityPresetOnInputHandler(true);
              }}
            />
          </div>
        </div>
        <div className="registrations-preset-selector__form__toggle">
          <BaseLabel small>Pays</BaseLabel>
          <div className="registrations-preset-selector__form__toggle__toggles">
            <BaseToggle
              value={editShowRegistrationsCtx.isCountryPresetOn}
              onClick={() => {
                editShowRegistrationsCtx.isCountryPresetOnInputHandler(
                  !editShowRegistrationsCtx.isCountryPresetOn
                );

                editShowRegistrationsCtx.isCountryPresetRequiredInputHandler(
                  !editShowRegistrationsCtx.isCountryPresetOn ? true : false
                );
              }}
            />
            <BaseToggle
              value={editShowRegistrationsCtx.isCountryPresetRequired}
              onClick={() => {
                editShowRegistrationsCtx.isCountryPresetRequiredInputHandler(
                  !editShowRegistrationsCtx.isCountryPresetRequired
                );
                !editShowRegistrationsCtx.isCountryPresetOn &&
                  editShowRegistrationsCtx.isCountryPresetOnInputHandler(true);
              }}
            />
          </div>
        </div>
      </div>
    </BaseCard>
  );
};

export default RegistrationsPresetSelector;
