import React, { useContext } from "react";
import EditShowInfosContext from "../../../contexts/edit-show-infos.ctx";
import BaseCard from "../../Base/BaseCard/BaseCard";

import "./ShowColorPledgeForm.css";
import BaseInputColor from "../../Base/BaseInputColor/BaseInputColor";

interface ContainerProps {
  gridId: string;
}

const ShowColorPledgeForm: React.FC<ContainerProps> = (props) => {
  const editShowInfosCtx = useContext(EditShowInfosContext);

  return (
    <BaseCard
      id={props.gridId}
      className="show-color-pledge-form"
      data-testid="show-color-pledge-form"
    >
      <BaseInputColor
        label="Couleur principal jauge"
        value={editShowInfosCtx.colorPledge}
        onValueChange={(value: string) =>
          editShowInfosCtx.colorPledgeHandler(value)
        }
      />
      <BaseInputColor
        label="Couleur de fond jauge"
        value={editShowInfosCtx.backgroundColorPledge}
        onValueChange={(value: string) =>
          editShowInfosCtx.backgroundColorPledgeHandler(value)
        }
      />
      <BaseInputColor
        label="Couleur +100% jauge"
        value={editShowInfosCtx.backgroundColorPledgeBeyond}
        onValueChange={(value: string) =>
          editShowInfosCtx.backgroundColorPledgeBeyondHandler(value)
        }
      />
    </BaseCard>
  );
};

export default ShowColorPledgeForm;
