import dayjs from "dayjs";
import { useEffect, useState } from "react";
import Show from "../../../models/Show";
import getSymbolFromCurrency from "../../../helpers/getSymbolFromCurrency";
import "./ShowCardInfosIndicator.css";

interface ContainerProps {
  show: Show;
}

const ShowCardInfosIndicator: React.FC<ContainerProps> = (props) => {
  const [countdownDate, setCountdownDate] = useState("");
  const [hasBegun, setHasBegun] = useState(false);

  useEffect(() => {
    const updateCountdown = () => {
      if (countdownDate === "00:00:00") {
        setHasBegun(true);
        return;
      }
      setCountdownDate(
        dayjs(dayjs(props.show.startDate).diff(dayjs()))
          .subtract(1, "hour")
          .format("HH[:]mm[:]ss")
      );
    };
    if (!countdownDate) {
      if (dayjs(props.show.startDate).isAfter(dayjs())) {
        updateCountdown();
      } else if (
        dayjs(props.show.startDate).isBefore(dayjs()) ||
        dayjs(props.show.startDate).isSame(dayjs())
      ) {
        setHasBegun(true);
      }
    }
    const timer = setTimeout(() => {
      if (dayjs(props.show.startDate).isAfter(dayjs())) {
        updateCountdown();
      } else if (
        dayjs(props.show.startDate).isBefore(dayjs()) ||
        dayjs(props.show.startDate).isSame(dayjs())
      ) {
        setHasBegun(true);
      }
      return () => clearTimeout(timer);
    }, 1000);
    return () => clearTimeout(timer);
  }, [countdownDate, props.show.startDate]);

  return (
    <div
      className="show-card-infos-indicator"
      data-testid="show-card-infos-indicator"
    >
      {hasBegun && !props.show.isClosed ? (
        <>
          <div className="show-card-infos-indicator__red-circle" />
          <span>EN COURS</span>
        </>
      ) : props.show.isClosed ? (
        <span>
          {(props.show.forcedEndingAmount
            ? props.show.forcedEndingAmount / 100
            : props.show.totalPledgesAmount / 100
          )?.toLocaleString(undefined, {
            maximumFractionDigits: 0,
          }) +
            " " +
            (getSymbolFromCurrency(props.show.pledgesCurrency) || "€")}
        </span>
      ) : dayjs().date() !== dayjs(props.show.startDate).date() ? (
        <>
          <span>{dayjs(props.show.startDate).format("HH[H]mm")}</span>
        </>
      ) : (
        <>
          <span>{countdownDate ? countdownDate : "--:--:--"}</span>
        </>
      )}
    </div>
  );
};

export default ShowCardInfosIndicator;
