import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faSave } from "@fortawesome/free-solid-svg-icons";
import React, { useContext, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import AppContext from "../../../contexts/app.ctx";
import EditShowMediasContext from "../../../contexts/edit-show-medias.ctx";
import BaseLoadingCurtain from "../../Base/BaseLoadingCurtain/BaseLoadingCurtain";
import PageContent from "../../Layout/PageContent/PageContent";
import PageHeader from "../../Layout/PageHeader/PageHeader";
import CoverSelector from "../../ShowMedias/CoverSelector/CoverSelector";
import LogoSelector from "../../ShowMedias/LogoSelector/LogoSelector";
import StreamConfigurator from "../../ShowMedias/StreamConfigurator/StreamConfigurator";
import "./EditShowMediasPage.css";

interface ContainerProps {}

export const editShowMediasPageRoute = "/:showId/edit-show-medias";

const EditShowMediasPage: React.FC<ContainerProps> = (props) => {
  const { showId } = useParams<Record<string, string>>();
  const appCtx = useContext(AppContext);
  const appCtxRef = useRef(useContext(AppContext));
  const editShowMediasCtx = useContext(EditShowMediasContext);

  useEffect(() => {
    appCtxRef.current.loadCurrentShow(showId);
  }, [showId]);

  return (
    <>
      {appCtx.isLoading ? <BaseLoadingCurtain /> : null}
      <div
        className="edit-show-medias-page"
        data-testid="edit-show-medias-page"
      >
        <PageHeader
          title="Médias de l'événement"
          actions={[
            {
              name: "Enregistrer",
              action: () => editShowMediasCtx.submitUpdate(),
              color: "#2CA795",
              icon: faSave as IconProp,
              isLoading: editShowMediasCtx.isLoading
            }
          ]}
        />
        <PageContent>
          <div className="edit-show-medias-page__grid">
            <CoverSelector gridId="cover-selector" />
            <LogoSelector gridId="logo-selector" />
            <StreamConfigurator gridId="stream-configurator" />
          </div>
        </PageContent>
      </div>
    </>
  );
};

export default EditShowMediasPage;
