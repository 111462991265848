import React, { useContext, useEffect, useState } from "react";
import AppContext from "./app.ctx";
import API from "../services";
import Show from "../models/Show";

const EditShowInfosContext = React.createContext({
  editedShow: {} as Show,
  isLoading: false,
  resetContext: () => {},
  isPublished: false,
  isPublishedInputHandler: (value: boolean) => {},
  isOnPlace: true,
  isOnPlaceInputHandler: (value: boolean) => {},
  isOnRemote: true,
  isOnRemoteInputHandler: (value: boolean) => {},
  name: "",
  nameInputHandler: (value: string) => {},
  place: "",
  placeInputHandler: (value: string) => {},
  subtitle: "",
  subtitleInputHandler: (value: string) => {},
  urlSlug: "",
  urlSlugInputHandler: (value: string) => {},
  startDate: new Date(),
  startDateInputHandler: (value: Date) => {},
  endDate: new Date(),
  endDateInputHandler: (value: Date) => {},
  description: "",
  descriptionInputHandler: (value: string) => {},
  endMessage: "",
  endMessageInputHandler: (value: string) => {},
  endButtonText: "",
  endButtonTextInputHandler: (value: string) => {},
  endButtonUrl: "",
  endButtonUrlInputHandler: (value: string) => {},
  comingButtonText: "",
  comingButtonTextInputHandler: (value: string) => {},
  comingButtonUrl: "",
  comingButtonUrlInputHandler: (value: string) => {},
  pastButtonText: "",
  pastButtonTextInputHandler: (value: string) => {},
  pastButtonUrl: "",
  pastButtonUrlInputHandler: (value: string) => {},
  globalShowGoal: "",
  globalShowGoalInputHandler: (value: string) => {},
  forcedEndingAmount: "",
  forcedEndingAmountInputHandler: (value: string) => {},
  isLnbc: false,
  backgroundColorHandler: (value: string) => {},
  backgroundColor: "",
  fontColorHandler: (value: string) => {},
  fontColor: "",
  colorPledgeHandler: (value: string) => {},
  colorPledge: "",
  backgroundColorPledgeHandler: (value: string) => {},
  backgroundColorPledge: "",
  backgroundColorPledgeBeyondHandler: (value: string) => {},
  backgroundColorPledgeBeyond: "",
  isLnbcInputHandler: (value: boolean) => {},
  submitUpdate: () => {}
});

export const EditShowInfosContextProvider: React.FC = (props) => {
  const [editedShow, setEditedShow] = useState({} as Show);
  const [isLoading, setIsLoading] = useState(false);
  const [isPublished, setIsPublished] = useState(false);
  const [isOnPlace, setIsOnPlace] = useState(true);
  const [isOnRemote, setIsOnRemote] = useState(true);
  const [name, setName] = useState("");
  const [place, setPlace] = useState("");
  const [subtitle, setSubtitile] = useState("");
  const [urlSlug, setUrlSlug] = useState("");
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [description, setDescription] = useState("");
  const [endMessage, setEndMessage] = useState("");
  const [endButtonText, setEndButtonText] = useState("");
  const [endButtonUrl, setEndButtonUrl] = useState("");
  const [comingButtonText, setComingButtonText] = useState("");
  const [comingButtonUrl, setComingButtonUrl] = useState("");
  const [pastButtonText, setPastButtonText] = useState("");
  const [pastButtonUrl, setPastButtonUrl] = useState("");
  const [globalShowGoal, setGlobalShowGoal] = useState("");
  const [forcedEndingAmount, setForcedEndingAmount] = useState("");
  const [isLnbc, setIsLnbc] = useState(false);
  const [backgroundColor, setBackgroundColor] = useState("");
  const [fontColor, setFontColor] = useState("");
  const [colorPledge, setColorPledge] = useState("");
  const [backgroundColorPledge, setBackgroundColorPledge] = useState("");
  const [backgroundColorPledgeBeyond, setBackgroundColorPledgeBeyond] =
    useState("");
  const appCtx = useContext(AppContext);

  const resetContext = () => {
    setEditedShow({} as Show);
    setIsLoading(false);
    setIsPublished(false);
    setIsOnPlace(false);
    setIsOnRemote(false);
    setName("");
    setPlace("");
    setSubtitile("");
    setUrlSlug("");
    setStartDate(new Date());
    setEndDate(new Date());
    setDescription("");
    setEndMessage("");
    setEndButtonText("");
    setEndButtonUrl("");
    setComingButtonText("");
    setComingButtonUrl("");
    setPastButtonText("");
    setPastButtonUrl("");
    setGlobalShowGoal("");
    setForcedEndingAmount("");
    setIsLnbc(false);
    setBackgroundColor("");
    setFontColor("");
    setColorPledge("");
    setBackgroundColorPledge("");
    setBackgroundColorPledgeBeyond("");
  };

  useEffect(() => {
    if (appCtx.currentShow.id) {
      setEditedShow(appCtx.currentShow);
      setIsLoading(false);
      setIsPublished(appCtx.currentShow.isPublished);
      setIsOnPlace(appCtx.currentShow.isOnPlace);
      setIsOnRemote(appCtx.currentShow.isOnRemote);
      setName(appCtx.currentShow.name);
      setPlace(appCtx.currentShow.place);
      setSubtitile(appCtx.currentShow.subtitle);
      setUrlSlug(appCtx.currentShow.urlSlug);
      setStartDate(appCtx.currentShow.startDate);
      setEndDate(appCtx.currentShow.endDate);
      setDescription(appCtx.currentShow.description);
      setEndMessage(appCtx.currentShow.endMessage);
      setEndButtonText(appCtx.currentShow.endButtonText);
      setEndButtonUrl(appCtx.currentShow.endButtonUrl);
      setComingButtonText(appCtx.currentShow.comingButtonText);
      setComingButtonUrl(appCtx.currentShow.comingButtonUrl);
      setPastButtonText(appCtx.currentShow.pastButtonText);
      setPastButtonUrl(appCtx.currentShow.pastButtonUrl);
      setGlobalShowGoal((appCtx.currentShow.globalShowGoal / 100).toString());
      setForcedEndingAmount(
        (appCtx.currentShow.forcedEndingAmount / 100).toString()
      );
      setIsLnbc(appCtx.currentShow.isLnbc);
      setBackgroundColor(appCtx.currentShow.backgroundColor);
      setFontColor(appCtx.currentShow.fontColor);
      setColorPledge(appCtx.currentShow.colorPledge);
      setBackgroundColorPledge(appCtx.currentShow.backgroundColorPledge);
      setBackgroundColorPledgeBeyond(
        appCtx.currentShow.backgroundColorPledgeBeyond
      );
    }
    return () => resetContext();
  }, [appCtx.currentShow]);

  const isPublishedInputHandler = (value: boolean) => {
    setIsPublished(value);
  };

  const isLnbcInputHandler = (value: boolean) => {
    setIsLnbc(value);
  };

  const isOnPlaceInputHandler = (value: boolean) => {
    if (value === false && isOnRemote === false) {
      setIsOnRemote(true);
    }
    setIsOnPlace(value);
  };

  const isOnRemoteInputHandler = (value: boolean) => {
    if (value === false && isOnPlace === false) {
      setIsOnPlace(true);
    }
    setIsOnRemote(value);
  };

  const nameInputHandler = (value: string) => {
    setName(value);
  };

  const placeInputHandler = (value: string) => {
    setPlace(value);
  };

  const subtitleInputHandler = (value: string) => {
    setSubtitile(value);
  };

  const urlSlugInputHandler = (value: string) => {
    setUrlSlug(value);
  };

  const startDateInputHandler = (value: Date) => {
    setStartDate(value);
  };

  const endDateInputHandler = (value: Date) => {
    setEndDate(value);
  };

  const descriptionInputHandler = (value: string) => {
    setDescription(value);
  };

  const endMessageInputHandler = (value: string) => {
    setEndMessage(value);
  };

  const endButtonTextInputHandler = (value: string) => {
    setEndButtonText(value);
  };

  const endButtonUrlInputHandler = (value: string) => {
    setEndButtonUrl(value);
  };

  const comingButtonTextInputHandler = (value: string) => {
    setComingButtonText(value);
  };

  const comingButtonUrlInputHandler = (value: string) => {
    setComingButtonUrl(value);
  };

  const pastButtonTextInputHandler = (value: string) => {
    setPastButtonText(value);
  };

  const pastButtonUrlInputHandler = (value: string) => {
    setPastButtonUrl(value);
  };

  const globalShowGoalInputHandler = (value: string) => {
    setGlobalShowGoal(value);
  };

  const forcedEndingAmountInputHandler = (value: string) => {
    setForcedEndingAmount(value);
  };

  const backgroundColorHandler = (value: string) => {
    setBackgroundColor(value);
  };
  const fontColorHandler = (value: string) => {
    setFontColor(value);
  };
  const colorPledgeHandler = (value: string) => {
    setColorPledge(value);
  };
  const backgroundColorPledgeHandler = (value: string) => {
    setBackgroundColorPledge(value);
  };
  const backgroundColorPledgeBeyondHandler = (value: string) => {
    setBackgroundColorPledgeBeyond(value);
  };
  const submitUpdate = () => {
    setIsLoading(true);
    API.updateShowInfos(editedShow.id, {
      isPublished: isPublished,
      isOnPlace: isOnPlace,
      isOnRemote: isOnRemote,
      name: name,
      place: place,
      subtitle: subtitle,
      urlSlug: urlSlug,
      startDate: startDate,
      endDate: endDate,
      description: description,
      endMessage: endMessage,
      endButtonText: endButtonText,
      endButtonUrl: endButtonUrl,
      comingButtonText: comingButtonText,
      comingButtonUrl: comingButtonUrl,
      pastButtonText: pastButtonText,
      pastButtonUrl: pastButtonUrl,
      forcedEndingAmount: forcedEndingAmount,
      globalShowGoal: globalShowGoal,
      isLnbc: isLnbc,
      backgroundColor: backgroundColor,
      fontColor: fontColor,
      colorPledge: colorPledge,
      backgroundColorPledge: backgroundColorPledge,
      backgroundColorPledgeBeyond: backgroundColorPledgeBeyond
    })
      .then((status) => {
        setIsLoading(false);
        appCtx.toggleNotification({
          type: "success",
          content: "Mise à jour de l'événement réussie."
        });
      })
      .catch((e) => {
        setIsLoading(false);
        appCtx.toggleNotification({
          type: "error",
          content: "Une erreur est survenue."
        });
      });
  };

  return (
    <EditShowInfosContext.Provider
      value={{
        editedShow,
        isLoading,
        resetContext,
        isPublished,
        isPublishedInputHandler,
        isOnPlace,
        isOnPlaceInputHandler,
        isOnRemote,
        isOnRemoteInputHandler,
        name,
        nameInputHandler,
        place,
        placeInputHandler,
        subtitle,
        subtitleInputHandler,
        urlSlug,
        urlSlugInputHandler,
        startDate,
        startDateInputHandler,
        endDate,
        endDateInputHandler,
        description,
        descriptionInputHandler,
        endMessage,
        endMessageInputHandler,
        endButtonText,
        endButtonTextInputHandler,
        endButtonUrl,
        endButtonUrlInputHandler,
        comingButtonText,
        comingButtonTextInputHandler,
        comingButtonUrl,
        comingButtonUrlInputHandler,
        pastButtonText,
        pastButtonTextInputHandler,
        pastButtonUrl,
        pastButtonUrlInputHandler,
        globalShowGoal,
        globalShowGoalInputHandler,
        forcedEndingAmount,
        forcedEndingAmountInputHandler,
        isLnbc,
        backgroundColorHandler,
        backgroundColor,
        fontColorHandler,
        fontColor,
        colorPledgeHandler,
        colorPledge,
        backgroundColorPledgeHandler,
        backgroundColorPledge,
        backgroundColorPledgeBeyondHandler,
        backgroundColorPledgeBeyond,
        isLnbcInputHandler,
        submitUpdate
      }}
    >
      {props.children}
    </EditShowInfosContext.Provider>
  );
};
export default EditShowInfosContext;
