import {
  faCheckCircle,
  faMinusCircle,
} from "@fortawesome/free-solid-svg-icons";
import classNames from "classnames";
import React, { useContext, useEffect, useRef, useState } from "react";
import BaseButton from "../../Base/BaseButton/BaseButton";
import BaseCard from "../../Base/BaseCard/BaseCard";
import BaseIcon from "../../Base/BaseIcon/BaseIcon";
import BaseInput from "../../Base/BaseInput/BaseInput";
import BaseLabel from "../../Base/BaseLabel/BaseLabel";
import BaseTextArea from "../../Base/BaseTextArea/BaseTextArea";
import API from "../../../services";
import "./LiveChatMonitor.css";
import AppContext from "../../../contexts/app.ctx";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

interface ContainerProps {
  gridId: string;
}

export type ChatMessage = {
  id: string;
  username: string;
  content: string;
  date: Date;
  isAdmin: boolean;
  isDisabled: boolean;
  pledgeAmount: string;
};

const LiveChatMonitor: React.FC<ContainerProps> = (props) => {
  const appCtx = useContext(AppContext);
  const [chatMessages, setChatMessages] = useState([] as ChatMessage[]);
  const [adminUsername, setAdminUsername] = useState("");
  const [messageContent, setMessageContent] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [statusIsChanging, setStatusIsChanging] = useState(false);
  const messageZoneRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const listener = API.listenToShowChatMessages(
      appCtx.currentShow.id,
      (chatMessages: ChatMessage[]) => {
        setChatMessages(chatMessages);
      }
    );
    return () => listener();
  }, [appCtx.currentShow.id]);

  const scrollToChatBottom = () => {
    if (messageZoneRef.current != null) {
      messageZoneRef.current.scrollTo({
        top: messageZoneRef.current.scrollHeight
      });
    }
  };

  useEffect(() => {
    scrollToChatBottom();
  }, [chatMessages]);

  const sendAdminMessage = () => {
    setIsLoading(true);
    API.createAdminChatMessage(
      appCtx.currentShow.id,
      adminUsername,
      messageContent,
      new Date(),
      true,
      false
    )
      .then((status) => {
        appCtx.toggleNotification({
          type: "success",
          content: "Message envoyé !"
        });
        setMessageContent("");
        setIsLoading(false);
      })
      .catch((e) => {
        appCtx.toggleNotification({
          type: "error",
          content: "Une erreur est survenue."
        });
        setIsLoading(false);
      });
  };

  const handleChatMessageStatusChange = (message: ChatMessage) => {
    if (!statusIsChanging) {
      setStatusIsChanging(true);
      API.changeChatMessageStatus(
        appCtx.currentShow.id,
        message.id,
        !message.isDisabled
      )
        .then((status) => {
          appCtx.toggleNotification({
            type: "success",
            content: `Message ${
              !message.isDisabled ? "désactivé !" : "activé !"
            }`
          });
          setMessageContent("");
          setStatusIsChanging(false);
        })
        .catch((e) => {
          appCtx.toggleNotification({
            type: "error",
            content: "Une erreur est survenue."
          });
          setStatusIsChanging(false);
        });
    }
  };

  return (
    <BaseCard
      id={props.gridId}
      className="live-chat-monitor"
      data-testid="live-chat-monitor"
      tight
    >
      <BaseLabel>Live Chat</BaseLabel>
      <div className="live-chat-monitor__messages-list" ref={messageZoneRef}>
        {[...chatMessages]
          .sort((a, b) => (a.date > b.date ? 1 : -1))
          .map((message) => (
            <div
              key={message.id}
              className={classNames({
                "live-chat-monitor__messages-list__item": true,
                "live-chat-monitor__messages-list__item--disabled":
                  message.isDisabled
              })}
            >
              <div className="live-chat-monitor__messages-list__item__top">
                <span
                  style={{ color: message.isAdmin ? "#ec4899" : "#2CA795" }}
                >
                  {message.username}{" "}
                  {message.pledgeAmount
                    ? ` (${(+message.pledgeAmount / 100).toLocaleString(
                        undefined,
                        { maximumFractionDigits: 0 }
                      )} ${appCtx.currentShowCurrencySymbol})`
                    : ""}
                </span>
                <span
                  onClick={() => handleChatMessageStatusChange(message)}
                  className="live-chat-monitor__messages-list__item__top__action"
                >
                  <BaseIcon
                    icon={
                      message.isDisabled
                        ? (faCheckCircle as IconProp)
                        : (faMinusCircle as IconProp)
                    }
                    size="16px"
                    color={message.isDisabled ? "#2CA795" : "#CE193D"}
                  />
                </span>
              </div>
              <div className="live-chat-monitor__messages-list__item__content">
                {message.content}
              </div>
            </div>
          ))}
      </div>
      <div className="live-chat-monitor__messenger">
        <div className="live-chat-monitor__messenger__input">
          <BaseInput
            label="Pseudo Organisateur"
            value={adminUsername}
            onValueChange={(value: string) => setAdminUsername(value)}
          />
        </div>
        <div className="live-chat-monitor__messenger__textarea">
          <BaseTextArea
            onValueChange={(value: string) => setMessageContent(value)}
            value={messageContent}
          />
        </div>

        <BaseButton
          name="Envoyer"
          onClick={() => sendAdminMessage()}
          color="#848AAE"
          extended
          disabled={!adminUsername || !messageContent}
          isLoading={isLoading}
        />
      </div>
    </BaseCard>
  );
};

export default LiveChatMonitor;
