import { faSave } from "@fortawesome/free-solid-svg-icons";
import React, { useContext, useEffect, useRef } from "react";
import PageContent from "../../Layout/PageContent/PageContent";
import PageHeader from "../../Layout/PageHeader/PageHeader";
import PledgesConfigurationForm from "../../ShowPledges/PledgesConfigurationForm/PledgesConfigurationForm";
import PledgesImportModule from "../../ShowPledges/PledgesImportModule/PledgesImportModule";
import PledgesPresetsForm from "../../ShowPledges/PledgesPresetsForm/PledgesPresetsForm";

import "./EditShowPledgesPage.css";
import { useParams } from "react-router-dom";
import AppContext from "../../../contexts/app.ctx";
import BaseLoadingCurtain from "../../Base/BaseLoadingCurtain/BaseLoadingCurtain";
import EditShowPledgesContext from "../../../contexts/edit-show-pledges.ctx";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

interface ContainerProps {}

export const editShowPledgesPageRoute = "/:showId/edit-show-pledges";

const EditShowPledgesPage: React.FC<ContainerProps> = (props) => {
  const { showId } = useParams<Record<string, string>>();
  const appCtx = useContext(AppContext);
  const appCtxRef = useRef(useContext(AppContext));
  const editShowPledgesCtx = useContext(EditShowPledgesContext);

  useEffect(() => {
    appCtxRef.current.loadCurrentShow(showId);
  }, [showId]);

  return (
    <>
      {appCtx.isLoading ? <BaseLoadingCurtain /> : null}
      <div
        className="edit-show-pledges-page"
        data-testid="edit-show-pledges-page"
      >
        <PageHeader
          title="Configuration des promesses"
          actions={[
            {
              name: "Enregistrer",
              action: () => editShowPledgesCtx.submitUpdate(),
              color: "#2CA795",
              icon: faSave as IconProp,
              isLoading: editShowPledgesCtx.isLoading
            }
          ]}
        />
        <PageContent>
          <div className="edit-show-pledges-page__grid">
            <PledgesPresetsForm gridId="pledges-presets-form" />
            <PledgesConfigurationForm gridId="pledges-configuration-form" />
            <PledgesImportModule gridId="pledges-import-module" />
          </div>
        </PageContent>
      </div>
    </>
  );
};

export default EditShowPledgesPage;
