import React, { useContext, useEffect, useState } from "react";
import Show from "../models/Show";
import API from "../services";
import AppContext from "./app.ctx";

export type CustomRegistration = {
  id: string;
  name: string;
  required: boolean;
  type: "TEXT" | "NUMBER" | "CHECKBOX";
};

const EditShowRegistrationsContext = React.createContext({
  editedShow: {} as Show,
  isLoading: false,
  resetContext: () => {},

  isFirstnamePresetOn: false,
  isFirstnamePresetOnInputHandler: (value: boolean) => {},
  isFirstnamePresetRequired: false,
  isFirstnamePresetRequiredInputHandler: (value: boolean) => {},

  isLastnamePresetOn: false,
  isLastnamePresetOnInputHandler: (value: boolean) => {},
  isLastnamePresetRequired: false,
  isLastnamePresetRequiredInputHandler: (value: boolean) => {},

  isPhonePresetOn: false,
  isPhonePresetOnInputHandler: (value: boolean) => {},
  isPhonePresetRequired: false,
  isPhonePresetRequiredInputHandler: (value: boolean) => {},

  isAddressFirstLinePresetOn: false,
  isAddressFirstLinePresetOnInputHandler: (value: boolean) => {},
  isAddressFirstLinePresetRequired: false,
  isAddressFirstLinePresetRequiredInputHandler: (value: boolean) => {},

  isAddressSecondLinePresetOn: false,
  isAddressSecondLinePresetOnInputHandler: (value: boolean) => {},
  isAddressSecondLinePresetRequired: false,
  isAddressSecondLinePresetRequiredInputHandler: (value: boolean) => {},

  isZipcodePresetOn: false,
  isZipcodePresetOnInputHandler: (value: boolean) => {},
  isZipcodePresetRequired: false,
  isZipcodePresetRequiredInputHandler: (value: boolean) => {},

  isCityPresetOn: false,
  isCityPresetOnInputHandler: (value: boolean) => {},
  isCityPresetRequired: false,
  isCityPresetRequiredInputHandler: (value: boolean) => {},

  isCountryPresetOn: false,
  isCountryPresetOnInputHandler: (value: boolean) => {},
  isCountryPresetRequired: false,
  isCountryPresetRequiredInputHandler: (value: boolean) => {},

  customRegistrationsList: [] as CustomRegistration[],
  customRegistrationsListSetter: (value: CustomRegistration[]) => {},

  pendingCustomRegistrationsList: [] as CustomRegistration[],
  pendingCustomRegistrationsListSetter: (value: CustomRegistration[]) => {},

  isPseudoPresetOn: false,
  isPseudoPresetOnInputHandler: (value: boolean) => {},
  isPseudoPresetRequired: false,
  isPseudoPresetRequiredInputHandler: (value: boolean) => {},
  submitUpdate: () => {}
});

export const EditShowRegistrationsContextProvider: React.FC = (props) => {
  const [editedShow, setEditedShow] = useState({} as Show);
  const [isLoading, setIsLoading] = useState(false);

  const [isFirstnamePresetOn, setIsFirstnamePresetOn] = useState(false);
  const [isFirstnamePresetRequired, setIsFirstnamePresetRequired] =
    useState(false);

  const [isLastnamePresetOn, setIsLastnamePresetOn] = useState(false);
  const [isLastnamePresetRequired, setIsLastnamePresetRequired] =
    useState(false);

  const [isPhonePresetOn, setIsPhonePresetOn] = useState(false);
  const [isPhonePresetRequired, setIsPhonePresetRequired] = useState(false);

  const [isAddressFirstLinePresetOn, setIsAddressFirstLinePresetOn] =
    useState(false);
  const [
    isAddressFirstLinePresetRequired,
    setIsAddressFirstLinePresetRequired,
  ] = useState(false);

  const [isAddressSecondLinePresetOn, setIsAddressSecondLinePresetOn] =
    useState(false);
  const [
    isAddressSecondLinePresetRequired,
    setIsAddressSecondLinePresetRequired,
  ] = useState(false);

  const [isZipcodePresetOn, setIsZipcodePresetOn] = useState(false);
  const [isZipcodePresetRequired, setIsZipcodePresetRequired] = useState(false);

  const [isCityPresetOn, setIsCityPresetOn] = useState(false);
  const [isCityPresetRequired, setIsCityPresetRequired] = useState(false);

  const [isCountryPresetOn, setIsCountryPresetOn] = useState(false);
  const [isCountryPresetRequired, setIsCountryPresetRequired] = useState(false);

  const [customRegistrationsList, setCustomRegistrationsList] = useState(
    [] as CustomRegistration[]
  );
  const [pendingCustomRegistrationsList, setPendingCustomRegistrationsList] =
    useState([] as CustomRegistration[]);
    const [isPseudoPresetOn, setIsPseudoPresetOn] = useState(false);
    const [isPseudoPresetRequired, setIsPseudoPresetRequired] = useState(false);
  const appCtx = useContext(AppContext);

  useEffect(() => {
    if (appCtx.currentShow.id) {
      setEditedShow(appCtx.currentShow);
      setIsLoading(false);
      setIsFirstnamePresetOn(appCtx.currentShow.isFirstnamePresetOn);
      setIsFirstnamePresetRequired(
        appCtx.currentShow.isFirstnamePresetRequired
      );
      setIsLastnamePresetOn(appCtx.currentShow.isLastnamePresetOn);
      setIsLastnamePresetRequired(appCtx.currentShow.isLastnamePresetRequired);
      setIsPhonePresetOn(appCtx.currentShow.isPhonePresetOn);
      setIsPhonePresetRequired(appCtx.currentShow.isPhonePresetRequired);
      setIsAddressFirstLinePresetOn(
        appCtx.currentShow.isAddressFirstLinePresetOn
      );
      setIsAddressFirstLinePresetRequired(
        appCtx.currentShow.isAddressFirstLinePresetRequired
      );
      setIsAddressSecondLinePresetOn(
        appCtx.currentShow.isAddressSecondLinePresetOn
      );
      setIsAddressSecondLinePresetRequired(
        appCtx.currentShow.isAddressSecondLinePresetRequired
      );
      setIsZipcodePresetOn(appCtx.currentShow.isZipcodePresetOn);
      setIsZipcodePresetRequired(appCtx.currentShow.isZipcodePresetRequired);
      setIsCityPresetOn(appCtx.currentShow.isCityPresetOn);
      setIsCityPresetRequired(appCtx.currentShow.isCityPresetRequired);
      setIsCountryPresetOn(appCtx.currentShow.isCountryPresetOn);
      setIsCountryPresetRequired(appCtx.currentShow.isCountryPresetRequired);
      setCustomRegistrationsList(appCtx.currentShow.customRegistrationsList);
      setIsPseudoPresetOn(appCtx.currentShow.isPseudoPresetOn);
      setIsPseudoPresetRequired(appCtx.currentShow.isPseudoPresetRequired);
    }
  }, [appCtx.currentShow]);

  const submitUpdate = () => {
    setIsLoading(true);
    API.updateShowRegistrations(
      editedShow.id,
      isFirstnamePresetOn,
      isFirstnamePresetRequired,
      isLastnamePresetOn,
      isLastnamePresetRequired,
      isPhonePresetOn,
      isPhonePresetRequired,
      isAddressFirstLinePresetOn,
      isAddressFirstLinePresetRequired,
      isAddressSecondLinePresetOn,
      isAddressSecondLinePresetRequired,
      isZipcodePresetOn,
      isZipcodePresetRequired,
      isCityPresetOn,
      isCityPresetRequired,
      isCountryPresetOn,
      isCountryPresetRequired,
      pendingCustomRegistrationsList,
      isPseudoPresetOn,
      isPseudoPresetRequired
    )
      .then((status) => {
        setIsLoading(false);
        appCtx.toggleNotification({
          type: "success",
          content: "Mise à jour des infos réussie."
        });
      })
      .catch((e) => {
        setIsLoading(false);
        appCtx.toggleNotification({
          type: "error",
          content: "Une erreur est survenue."
        });
      });
  };

  const resetContext = () => {
    setEditedShow({} as Show);
    setIsLoading(false);

    setIsFirstnamePresetOn(false);
    setIsFirstnamePresetRequired(false);

    setIsLastnamePresetOn(false);
    setIsLastnamePresetRequired(false);

    setIsPhonePresetOn(false);
    setIsPhonePresetRequired(false);

    setIsAddressFirstLinePresetOn(false);
    setIsAddressFirstLinePresetRequired(false);

    setIsAddressSecondLinePresetOn(false);
    setIsAddressSecondLinePresetRequired(false);

    setIsZipcodePresetOn(false);
    setIsZipcodePresetRequired(false);

    setIsCityPresetOn(false);
    setIsCityPresetRequired(false);

    setIsCountryPresetOn(false);
    setIsCountryPresetRequired(false);

    setCustomRegistrationsList([] as CustomRegistration[]);
    setIsPseudoPresetOn(false);
    setIsPseudoPresetRequired(false);
  };

  const isFirstnamePresetOnInputHandler = (value: boolean) => {
    setIsFirstnamePresetOn(value);
  };
  const isFirstnamePresetRequiredInputHandler = (value: boolean) => {
    setIsFirstnamePresetRequired(value);
  };

  const isLastnamePresetOnInputHandler = (value: boolean) => {
    setIsLastnamePresetOn(value);
  };
  const isLastnamePresetRequiredInputHandler = (value: boolean) => {
    setIsLastnamePresetRequired(value);
  };

  const isPhonePresetOnInputHandler = (value: boolean) => {
    setIsPhonePresetOn(value);
  };

  const isPhonePresetRequiredInputHandler = (value: boolean) => {
    setIsPhonePresetRequired(value);
  };

  const isAddressFirstLinePresetOnInputHandler = (value: boolean) => {
    setIsAddressFirstLinePresetOn(value);
  };

  const isAddressFirstLinePresetRequiredInputHandler = (value: boolean) => {
    setIsAddressFirstLinePresetRequired(value);
  };

  const isAddressSecondLinePresetOnInputHandler = (value: boolean) => {
    setIsAddressSecondLinePresetOn(value);
  };

  const isAddressSecondLinePresetRequiredInputHandler = (value: boolean) => {
    setIsAddressSecondLinePresetRequired(value);
  };

  const isZipcodePresetOnInputHandler = (value: boolean) => {
    setIsZipcodePresetOn(value);
  };

  const isZipcodePresetRequiredInputHandler = (value: boolean) => {
    setIsZipcodePresetRequired(value);
  };

  const isCityPresetOnInputHandler = (value: boolean) => {
    setIsCityPresetOn(value);
  };

  const isCityPresetRequiredInputHandler = (value: boolean) => {
    setIsCityPresetRequired(value);
  };

  const isCountryPresetOnInputHandler = (value: boolean) => {
    setIsCountryPresetOn(value);
  };

  const isCountryPresetRequiredInputHandler = (value: boolean) => {
    setIsCountryPresetRequired(value);
  };

  const customRegistrationsListSetter = (value: CustomRegistration[]) => {
    setCustomRegistrationsList(value);
  };

  const pendingCustomRegistrationsListSetter = (
    value: CustomRegistration[]
  ) => {
    setPendingCustomRegistrationsList(value);
  };

  const isPseudoPresetOnInputHandler = (value: boolean) => {
    setIsPseudoPresetOn(value);
  };
  const isPseudoPresetRequiredInputHandler = (value: boolean) => {
    setIsPseudoPresetRequired(value);
  };

  return (
    <EditShowRegistrationsContext.Provider
      value={{
        editedShow,
        isLoading,
        resetContext,
        isFirstnamePresetOn,
        isFirstnamePresetOnInputHandler,
        isFirstnamePresetRequired,
        isFirstnamePresetRequiredInputHandler,
        isLastnamePresetOn,
        isLastnamePresetOnInputHandler,
        isLastnamePresetRequired,
        isLastnamePresetRequiredInputHandler,
        isPhonePresetOn,
        isPhonePresetOnInputHandler,
        isPhonePresetRequired,
        isPhonePresetRequiredInputHandler,
        isAddressFirstLinePresetOn,
        isAddressFirstLinePresetOnInputHandler,
        isAddressFirstLinePresetRequired,
        isAddressFirstLinePresetRequiredInputHandler,
        isAddressSecondLinePresetOn,
        isAddressSecondLinePresetOnInputHandler,
        isAddressSecondLinePresetRequired,
        isAddressSecondLinePresetRequiredInputHandler,
        isZipcodePresetOn,
        isZipcodePresetOnInputHandler,
        isZipcodePresetRequired,
        isZipcodePresetRequiredInputHandler,
        isCityPresetOn,
        isCityPresetOnInputHandler,
        isCityPresetRequired,
        isCityPresetRequiredInputHandler,
        isCountryPresetOn,
        isCountryPresetOnInputHandler,
        isCountryPresetRequired,
        isCountryPresetRequiredInputHandler,
        customRegistrationsList,
        customRegistrationsListSetter,
        pendingCustomRegistrationsList,
        pendingCustomRegistrationsListSetter,
        isPseudoPresetOn,
        isPseudoPresetOnInputHandler,
        isPseudoPresetRequired,
        isPseudoPresetRequiredInputHandler,
        submitUpdate
      }}
    >
      {props.children}
    </EditShowRegistrationsContext.Provider>
  );
};
export default EditShowRegistrationsContext;
