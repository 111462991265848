import React, {useContext, useState} from "react";

import "./NewMultiplexPage.css"
import AppContext from "../../../contexts/app.ctx";
import {faSave} from "@fortawesome/free-solid-svg-icons";
import PageHeader from "../../Layout/PageHeader/PageHeader";
import {IconProp} from "@fortawesome/fontawesome-svg-core";
import BaseCard from "../../Base/BaseCard/BaseCard";
import BaseInput from "../../Base/BaseInput/BaseInput";
import PageContent from "../../Layout/PageContent/PageContent";
import API from "../../../services";
import {useHistory} from "react-router";

export const newMultiplexPageRoute = "/new-multiplex";

const NewMultiplexePage = () => {
  const [newMultiplexName, setNewMultiplexName] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const appCtx = useContext(AppContext);

  const history = useHistory();

  return (
    <div className="new-multiplex-page">
      <PageHeader
        title="Créer un Multiplex"
        actions={[
          {
            name: "Créer",
            action: () => {
              setIsLoading(true);
              API.createMultiplex(newMultiplexName)
                .then((res) => {
                  setIsLoading(false);
                  appCtx.toggleNotification({
                    type: "success",
                    content: "Le multiplex a été créé !"
                  });
                  history.push(`/multiplexes`);
                })
                .catch((e) => console.log(e));
            },
            color: "#2CA795",
            icon: faSave as IconProp,
            isLoading: isLoading
          },
        ]}
      />
      <PageContent>
        <div className="new-multiplex-page__content">
          <BaseCard>
            <div className="new-multiplex-page__content__input">
              <BaseInput
                label="Nom du multiplex"
                value={newMultiplexName}
                onValueChange={(value: string) => setNewMultiplexName(value)}
              />
            </div>
          </BaseCard>
        </div>
      </PageContent>
    </div>
  )
}

export default NewMultiplexePage