import React from "react";
import "./BaseTextArea.css";

interface ContainerProps {
  label?: string;
  value: string;
  onValueChange: Function;
}

const BaseTextArea: React.FC<ContainerProps> = (props) => {
  return (
    <div className="base-text-area" data-testid="base-text-area">
      {props.label && <label>{props.label + " :"}</label>}
      <textarea
        rows={4}
        value={props.value}
        onChange={(event) => props.onValueChange(event.target.value)}
      />
    </div>
  );
};

export default BaseTextArea;
