import React, { useContext, useEffect, useState } from "react";
import AppContext from "../../../contexts/app.ctx";
import BaseButton from "../../Base/BaseButton/BaseButton";
import BaseCard from "../../Base/BaseCard/BaseCard";
import BaseInput from "../../Base/BaseInput/BaseInput";
import BaseLabel from "../../Base/BaseLabel/BaseLabel";
import { $firebaseAuth } from "../../../services/firebase-service";
import "./LoginPage.css";
import AuthContext, { AuthStates } from "../../../contexts/auth.ctx";
import { useHistory } from "react-router";

interface ContainerProps {}

const LoginPage: React.FC<ContainerProps> = (props) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const appCtx = useContext(AppContext);
  const authCtx = useContext(AuthContext);
  const history = useHistory();

  useEffect(() => {
    if (authCtx.authState === AuthStates.AUTH) {
      history.push("/");
    }
  }, [history, authCtx.authState]);

  const handleLogin = () => {
    if (!email || !password) {
      appCtx.toggleNotification({
        type: "error",
        content: "Veuillez renseigner le formulaire.",
      });
    } else {
      $firebaseAuth
        .signInWithEmailAndPassword(email, password)
        .then(async (data) => {
          appCtx.toggleNotification({
            type: "success",
            content: "Connexion réussie."
          });
        })
        .catch((e) => {
          if (e.code === "auth/user-not-found") {
            appCtx.toggleNotification({
              type: "error",
              content: "Erreur de compte."
            });
          } else if (e.code === "auth/wrong-password") {
            appCtx.toggleNotification({
              type: "error",
              content: "Erreur de mot de passe."
            });
          }
          appCtx.toggleNotification({
            type: "error",
            content: "Erreur de connexion."
          });
        });
    }
  };

  const handlePasswordReset = () => {
    if (!email) {
      appCtx.toggleNotification({
        type: "error",
        content: "Veuillez renseigner votre email.",
      });
    } else {
      $firebaseAuth
        .sendPasswordResetEmail(email)
        .then(() => {
          appCtx.toggleNotification({
            type: "success",
            content: "Mail envoyé.",
          });
        })
        .catch((err) => {
          appCtx.toggleNotification({
            type: "error",
            content: "Compte inconnu.",
          });
        });
    }
  };

  return authCtx.authState === AuthStates.NOT_AUTH ? (
    <div className="login-page" data-testid="login-page">
      <BaseCard>
        <BaseLabel>Connexion</BaseLabel>
        <div className="login-page__login-form">
          <BaseInput
            label="Email"
            value={email}
            onValueChange={(value: string) => setEmail(value)}
          />
          <BaseInput
            label="Mot de passe"
            value={password}
            onValueChange={(value: string) => setPassword(value)}
            type="password"
          />
          <span
            onClick={() => handlePasswordReset()}
            className="login-page__login-form__password-reset"
          >
            Réinitialiser le mot de passe
          </span>
        </div>
        <div className="login-page__submit">
          <BaseButton
            color="#848aae"
            extended
            name="Valider"
            onClick={() => handleLogin()}
          />
        </div>
      </BaseCard>
    </div>
  ) : (
    <div />
  );
};

export default LoginPage;
