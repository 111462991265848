import React from "react";
import "./BaseCard.css";

interface ContainerProps {
  className?: string;
  id?: string;
  tight?: boolean;
}

const BaseCard: React.FC<ContainerProps> = (props) => {
  const getClassNames = () => {
    const composite = ["base-card"];
    if (props.className) {
      composite.push(props.className);
    }
    if (props.tight) {
      composite.push("base-card--tight");
    }
    return composite.join(" ");
  };

  return (
    <div className={getClassNames()} id={props.id} data-testid="base-card">
      {props.children}
    </div>
  );
};

export default BaseCard;
