import React, { useContext } from "react";
import EditShowInfosContext from "../../../contexts/edit-show-infos.ctx";
import BaseCard from "../../Base/BaseCard/BaseCard";
import BaseToggle from "../../Base/BaseToggle/BaseToggle";
import BaseLabel from "../../Base/BaseLabel/BaseLabel";
import "./PublishedSelector.css";

interface ContainerProps {
  gridId: string;
}

const PublishedSelector: React.FC<ContainerProps> = (props) => {
  const editShowInfosCtx = useContext(EditShowInfosContext);

  return (
    <BaseCard id={props.gridId} className="published-selector">
      <BaseLabel>Publié</BaseLabel>
      <BaseToggle
        value={editShowInfosCtx.isPublished}
        onClick={() =>
          editShowInfosCtx.isPublishedInputHandler(
            !editShowInfosCtx.isPublished
          )
        }
      />
    </BaseCard>
  );
};

export default PublishedSelector;
