import classNames from "classnames";
import React, { useContext } from "react";
import EditShowPledgesContext from "../../../contexts/edit-show-pledges.ctx";
import BaseCard from "../../Base/BaseCard/BaseCard";
import BaseInput from "../../Base/BaseInput/BaseInput";
import BaseLabel from "../../Base/BaseLabel/BaseLabel";
import BaseToggle from "../../Base/BaseToggle/BaseToggle";
import "./PledgesPresetsForm.css";

interface ContainerProps {
  gridId: string;
}

const PledgesPresetsForm: React.FC<ContainerProps> = (props) => {
  const editShowPledgesCtx = useContext(EditShowPledgesContext);

  return (
    <BaseCard
      id={props.gridId}
      className="pledges-presets-form"
      data-testid="pledges-presets-form"
    >
      <BaseLabel>Promesses prédéfinies</BaseLabel>
      <div className="pledges-presets-form__form">
        <div className="pledges-presets-form__form__presets-activator">
          <BaseLabel small>Activer</BaseLabel>
          <BaseToggle
            value={editShowPledgesCtx.hasPledgesPresets}
            onClick={() =>
              editShowPledgesCtx.hasPledgesPresetsInputHandler(
                !editShowPledgesCtx.hasPledgesPresets
              )
            }
          />
        </div>
        <div
          className={classNames({
            "pledges-presets-form__form__presets-grid": true,
            "actions-disabled": true,
          })}
        >
          <div id="first-pledge-preset">
            <BaseInput
              label="Montant 1"
              value={editShowPledgesCtx.firstPledgeAmount}
              type="number"
              onValueChange={(value: string) =>
                editShowPledgesCtx.firstPledgeAmountInputHandler(value)
              }
            />
          </div>
          <div id="second-pledge-preset">
            <BaseInput
              label="Montant 2"
              value={editShowPledgesCtx.secondPledgeAmount}
              type="number"
              onValueChange={(value: string) =>
                editShowPledgesCtx.secondPledgeAmountInputHandler(value)
              }
            />
          </div>
          <div id="third-pledge-preset">
            <BaseInput
              label="Montant 3"
              value={editShowPledgesCtx.thirdPledgeAmount}
              type="number"
              onValueChange={(value: string) =>
                editShowPledgesCtx.thirdPledgeAmountInputHandler(value)
              }
            />
          </div>
          <div id="fourth-pledge-preset">
            <BaseInput
              label="Montant 4"
              value={editShowPledgesCtx.fourthPledgeAmount}
              type="number"
              onValueChange={(value: string) =>
                editShowPledgesCtx.fourthPledgeAmountInputHandler(value)
              }
            />
          </div>
        </div>
      </div>
    </BaseCard>
  );
};

export default PledgesPresetsForm;
