import React, { useContext } from "react";
import EditShowInfosContext from "../../../contexts/edit-show-infos.ctx";
import BaseCard from "../../Base/BaseCard/BaseCard";

import "./PreviewCounter.css";

interface ContainerProps {
  gridId: string;
}

const PreviewCounter: React.FC<ContainerProps> = (props) => {
  const editShowInfosCtx = useContext(EditShowInfosContext);

  return (
    <BaseCard
      id={props.gridId}
      className="preview-counter"
      data-testid="preview-counter"
    >
      <main className="counter-page__main">
        <div
          className="counter-page__main__counter"
          style={{
            backgroundColor: editShowInfosCtx.backgroundColor
          }}
        >
          <div className="counter-page__main__counter__show-total-pledges">
            <span style={{ color: editShowInfosCtx.fontColor }}>
              Titre de l'événement
            </span>
            <span style={{ color: editShowInfosCtx.fontColor }}>100 000€</span>
          </div>

          <div className="counter-page__main__counter__project-counter">
            <div
              className="counter-page__main__counter__project-counter__logo"
              style={{
                backgroundColor: "white"
              }}
            ></div>
            <span
              style={{ color: editShowInfosCtx.fontColor }}
              className="counter-page__main__counter__project-counter__current-project-name"
            >
              Titre du projet
            </span>
            <div
              style={{
                color: editShowInfosCtx.fontColor
              }}
              className="counter-page__main__counter__project-counter__amount"
            >
              23 000 €
            </div>
          </div>
          <div className="counter-page__main__counter__project-counter__progress-bar">
            <div className="counter-page__main__counter__project-counter__progress-bar__percentage">
              <div
                style={{
                  color: editShowInfosCtx.fontColor,
                  marginLeft: "47%"
                }}
              >
                50 %
              </div>
            </div>
            <div
              style={{
                backgroundColor: editShowInfosCtx.backgroundColorPledge
              }}
              className="counter-page__main__counter__project-counter__progress-bar__bar"
            >
              <div
                style={{
                  backgroundColor: editShowInfosCtx.colorPledge,
                  width: "50%"
                }}
                className="counter-page__main__counter__project-counter__progress-bar__bar__inner"
              ></div>
            </div>
            <div className="counter-page__main__counter__project-counter__progress-bar__target">
              <span style={{ color: "white" }}>11 500 €</span>
              <span
                style={{
                  color: editShowInfosCtx.backgroundColorPledgeBeyond
                }}
              >
                test
              </span>
            </div>
          </div>
        </div>
      </main>
    </BaseCard>
  );
};

export default PreviewCounter;
