import React, { useContext } from "react";
import PageHeader from "../../Layout/PageHeader/PageHeader";
import PageContent from "../../Layout/PageContent/PageContent";
import ShowCard from "../../Shows/ShowCard/ShowCard";
import BaseLoadingCurtain from "../../Base/BaseLoadingCurtain/BaseLoadingCurtain";
import "./HomePage.css";
import AppContext from "../../../contexts/app.ctx";

interface ContainerProps {}

export const homePageRoute = "/";

const HomePage: React.FC<ContainerProps> = (props) => {
  const appCtx = useContext(AppContext);

  return (
    <div className="home-page" data-testid="home-page">
      <PageHeader title="Mes événements" />
      {appCtx.isLoading ? <BaseLoadingCurtain /> : null}
      <PageContent>
        <div className="home-page__shows-grid">
          {appCtx.allShows?.map((show) => {
            return <ShowCard show={show} key={"show" + show.id} />;
          })}
          {appCtx.allShows.length >= 10 && (
            <div
              onClick={() => appCtx.increaseShowsFetchLimit()}
              className="home-page__shows-grid__more-show"
            >
              <span>Charger plus</span>
            </div>
          )}
        </div>
      </PageContent>
    </div>
  );
};

export default HomePage;
