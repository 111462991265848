import React, { useContext } from "react";
import EditShowPledgesContext from "../../../contexts/edit-show-pledges.ctx";
import BaseCard from "../../Base/BaseCard/BaseCard";
import BaseLabel from "../../Base/BaseLabel/BaseLabel";
import BaseSelectInput from "../../Base/BaseSelectInput/BaseSelectInput";
import BaseToggle from "../../Base/BaseToggle/BaseToggle";
import currencies from "../../../configs/currencies.json";
import "./PledgesConfigurationForm.css";

interface ContainerProps {
  gridId: string;
}

const PledgesConfigurationForm: React.FC<ContainerProps> = (props) => {
  const editShowPledgesCtx = useContext(EditShowPledgesContext);

  return (
    <BaseCard
      id={props.gridId}
      className="pledges-configuration-form"
      data-testid="pledges-configuration-form"
    >
      <BaseLabel>Paramètres des promesses</BaseLabel>
      <div className="pledges-configuration-form__form">
        <BaseSelectInput
          onChangeValue={editShowPledgesCtx.pledgesCurrencyInputHandler}
          value={editShowPledgesCtx.pledgesCurrency}
          label="Devise"
          options={[...currencies].map((c) => {
            return {
              name: c.name + " - " + c.displayName,
              value: c.value,
            };
          })}
        />
        <div className="pledges-configuration-form__form__toggle">
          <BaseLabel small>Montant libre</BaseLabel>
          <BaseToggle
            value={editShowPledgesCtx.hasFreePledgeAmount}
            onClick={() =>
              editShowPledgesCtx.hasFreePledgeAmountInputHandler(
                !editShowPledgesCtx.hasFreePledgeAmount
              )
            }
          />
        </div>
        <div className="pledges-configuration-form__form__toggle">
          <BaseLabel small>Reçu fiscal demandé</BaseLabel>
          <BaseToggle
            value={editShowPledgesCtx.hasTaxReceiptAvailable}
            onClick={() =>
              editShowPledgesCtx.hasTaxReceiptAvailableInputHandler(
                !editShowPledgesCtx.hasTaxReceiptAvailable
              )
            }
          />
        </div>
      </div>
    </BaseCard>
  );
};

export default PledgesConfigurationForm;
