import React, {useContext, useEffect, useState} from "react";
import AppContext from "../../../contexts/app.ctx";
import API from "../../../services";
import BaseCard from "../../Base/BaseCard/BaseCard";
import classNames from "classnames";
import BaseLabel from "../../Base/BaseLabel/BaseLabel";
import getSymbolFromCurrency from "../../../helpers/getSymbolFromCurrency";

interface ContainerProps {
  gridId: string;
}

export type DashboardLocalCount = {
  id: string
  place: string
  totalPledgesAmount: number
  selected: boolean
}

const MultiplexCurrentProjectsSelector: React.FC<ContainerProps> = (props) => {
  const appCtx = useContext(AppContext);
  const selectedDashboard = appCtx.currentMultiplexDashboards.find(dashboard =>
    dashboard.id === appCtx.activeDashboardId
  )
  const [localitiesList, setLocalitiesList] = useState([] as DashboardLocalCount[])

  useEffect(() => {
    if (selectedDashboard?.id) {
      API.listenToSelectedDashboardLocalities(
        appCtx.currentMultiplex.id,
          selectedDashboard.id,
        setLocalitiesList
      )
    }
  }, [appCtx.activeDashboardId])

  const limitReachNotification = () => {
    appCtx.toggleNotification({
      type: "error",
      content: "Maximum sélectionnés (3)"
    })
  }

  const setCurrentDashboardLocalities = (clickedLocality: DashboardLocalCount) => {
    let selectionSize = localitiesList.filter(locality => locality.selected === true).length

    if (selectedDashboard) {
      API.toggleSelectedDashboardLocalities(
        appCtx.currentMultiplex.id,
        selectedDashboard.id,
        clickedLocality.id,
        selectionSize,
        limitReachNotification
      )
    }
  };

  return (
    <BaseCard
      id={props.gridId}
      className={classNames({
        "multiplex-current-dashboard-selector": true
      })}
      tight
    >
      <BaseLabel>Shows mis en avant</BaseLabel>
      <div className="multiplex-current-dashboard__list">
        {selectedDashboard && localitiesList.map((locality, index) => (
          <div
            key={locality.id}
            className={classNames({
              "multiplex-current-dashboard-selector__list__item": true,
              "multiplex-current-dashboard-selector__list__item--active":
                locality.selected === true
            })}
            onClick={() => setCurrentDashboardLocalities(locality)}
          >
            <div className="multiplex-current-dashboard-selector__list__item__infos">
              <div className="multiplex-current-dashboard-project-selector__list__item__infos__name">
                <span>[ {locality.place} ] </span>
                {locality.totalPledgesAmount / 100}  {getSymbolFromCurrency(appCtx.currentShow.pledgesCurrency)}
              </div>
            </div>
          </div>
        ))}
      </div>
    </BaseCard>
  );
};

export default MultiplexCurrentProjectsSelector;
