import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import React, { useContext } from "react";
import { useHistory } from "react-router";
import AppContext from "../../../contexts/app.ctx";
import BaseButton from "../../Base/BaseButton/BaseButton";
import BaseCard from "../../Base/BaseCard/BaseCard";
import BaseLabel from "../../Base/BaseLabel/BaseLabel";
import "./DeleteShowAction.css";

interface ContainerProps {
  gridId: string;
}

const DeleteShowAction: React.FC<ContainerProps> = (props) => {
  const appCtx = useContext(AppContext);
  const history = useHistory();

  const deleteShow = () => {
    const deletionConfirmation = window.confirm(
      "ATTENTION : Souhaitez vous réellement supprimer cet événement ?"
    );
    if (deletionConfirmation) {
      const secondDeletionConfirmation = window.confirm(
        "ATTENTION : Vraiment ? Toutes les promesses, messages et configurations vont-être effacés !"
      );
      if (secondDeletionConfirmation) {
        appCtx.deleteCurrentShow();
        history.push("/");
      }
    }
  };

  return (
    <BaseCard
      id={props.gridId}
      className="delete-show-action"
      data-testid="delete-show-action"
    >
      <BaseLabel>
        <span style={{ color: "#CE193D" }}>Supprimer l'événement</span>
      </BaseLabel>
      <BaseButton
        name="Supprimer"
        onClick={() => deleteShow()}
        color="#CE193D"
        mobileCondensed
        icon={faTrash as IconProp}
      />
    </BaseCard>
  );
};

export default DeleteShowAction;
