import React from "react";

import "./BaseInput.css";

interface ContainerProps {
  label?: string;
  value: string;
  onValueChange: Function;
  type?: string;
  disabled?: boolean;
  placeholder?: string;
}

const BaseInput: React.FC<ContainerProps> = (props) => {
  return (
    <div className="base-input" data-testid="base-input">
      {props.label && <label>{props.label + " :"}</label>}
      <input
        disabled={props.disabled}
        placeholder={props.placeholder}
        type={props.type || undefined}
        value={props.disabled ? "" : props.value}
        onChange={(event) => props.onValueChange(event.target.value)}
      />
    </div>
  );
};

export default BaseInput;
