import BaseLoadingCurtain from "../../Base/BaseLoadingCurtain/BaseLoadingCurtain";
import React, {useContext} from "react";
import AppContext from "../../../contexts/app.ctx";
import PageHeader from "../../Layout/PageHeader/PageHeader";
import {faLink, faTh} from "@fortawesome/free-solid-svg-icons";
import {IconProp} from "@fortawesome/fontawesome-svg-core";
import PageContent from "../../Layout/PageContent/PageContent";
import BaseCard from "../../Base/BaseCard/BaseCard";
import API from "../../../services";

import "./MultiplexeManagerPage.css"
import BaseLabel from "../../Base/BaseLabel/BaseLabel";
import MultiplexCurrentDashboardSelector
  from "../../MultiplexManager/MultiplexCurrentDashboardSelector/MultiplexCurrentDasboardSelector";
import MultiplexCurrentProjectsSelector
  from "../../MultiplexManager/MultiplexCurrentProjectsSelector/MultiplexCurrentProjectsSelector";
import LiveCurrentProjectSelector from "../../LiveManager/LiveCurrentProjectSelector/LiveCurrentProjectSelector";
import ClientUrlConfig from "../../../configs/dons-live-client.config";
import useDocumentTitle from "../../../helpers/changeTabName";
import BaseToggle from "../../Base/BaseToggle/BaseToggle";

export const multiplexManagerPageRoute = "/:showId/multiplex-manager";

const MultiplexeManagerPage = () => {
  const appCtx = useContext(AppContext);
  const isMultiplex = ("multiplexId" in appCtx.currentShow && typeof appCtx.currentShow.multiplexId === 'string')
  const baseUrl = ClientUrlConfig()

  const handleOverviewOpening = () => {
    window.open(
      `${baseUrl}${appCtx.currentShow.urlSlug}/multiplexoverview`
    );
  };
  const handleDashboardOpening = () => {
    window.open(
      `${baseUrl}${appCtx.currentShow.urlSlug}/multiplex`
    );
  };
  const handleEndScreenOpening = () => {
    window.open(
      `${baseUrl}${appCtx.currentShow.urlSlug}/multiplexendscreen`
    );
  };

  useDocumentTitle("Régie Multiplex - Dons.live")

  return (
    <>
      {appCtx.isLoading ? <BaseLoadingCurtain/> : null}
      <div className="multiplex-manager" data-testid="multiplex-manager">
        <PageHeader
          title="Régie Multiplex"
          actions={isMultiplex ? [
            {
              name: "Overview",
              action: handleOverviewOpening,
              color: "#848AAE",
              icon: faLink as IconProp
            },
            {
              name: "Dashboard",
              action: handleDashboardOpening,
              color: "#848AAE",
              icon: faLink as IconProp
            },
            {
              name: "Cloture",
              action: handleEndScreenOpening,
              color: "#848AAE",
              icon: faTh as IconProp
            },
          ] : []}
        />
        <PageContent>
          <div className="multiplex-manager__grid">
            {isMultiplex && (
              <>
                <BaseCard id="multiplex-manager__infos" className="multiplex-manager__infos">
                  <BaseLabel>
                    <span>{appCtx.currentMultiplex.name}</span> - {appCtx.currentShow.name} ( {appCtx.currentShow.place} )
                  </BaseLabel>
                  <div className="multiplex-manager__toggle">
                    <span className="multiplex-manager__toggle-label">
                      Overview
                    </span>
                    <BaseToggle
                      value={appCtx.currentMultiplex.displayProgressBar || false}
                      onClick={() =>
                          API.toggleMultiplexDisplay(appCtx.currentMultiplex.id,
                          (appCtx.currentMultiplex.displayProgressBar ? appCtx.currentMultiplex.displayProgressBar : false)
                          )}
                    />
                    <span className="multiplex-manager__toggle-label">
                      Jauge
                    </span>
                  </div>
                </BaseCard>

                <BaseCard id="multiplex-manager__count" className="multiplex-manager__count">
                  <div></div>
                  <BaseLabel>
                    Don Moyen :{" "}
                    <span>
                    {isNaN(appCtx.currentMultiplex.averagePledgesAmount / 100)
                      ? "0 " + appCtx.currentMultiplexCurrencySymbol
                      : (appCtx.currentMultiplex.averagePledgesAmount / 100).toLocaleString(
                        undefined,
                        {maximumFractionDigits: 0}
                      ) +
                      " " +
                      appCtx.currentShowCurrencySymbol}
                  </span>
                  </BaseLabel>
                  <BaseLabel>
                    Total :{" "}
                    <span>
                    {(appCtx.currentMultiplex.totalPledgesAmount / 100).toLocaleString(
                        undefined,
                        {maximumFractionDigits: 0}
                      ) +
                      " " +
                      appCtx.currentShowCurrencySymbol}
                  </span>
                  </BaseLabel>
                </BaseCard>

                <LiveCurrentProjectSelector gridId="live-current-project-selector" />

                <MultiplexCurrentDashboardSelector gridId="multiplex-manager__dashboard-list" />

                <MultiplexCurrentProjectsSelector gridId="multiplex-manager__show-list" />
              </>
            )}
            {(!appCtx.currentShow.multiplexId && (
              <BaseCard>
                <BaseLabel>Cet événement ne fait pas parti d'un multiplex</BaseLabel>
              </BaseCard>
            ))}
          </div>
        </PageContent>
      </div>
    </>
  )
}

export default MultiplexeManagerPage