import { faTimes } from "@fortawesome/free-solid-svg-icons";
import classNames from "classnames";
import React, { useContext, useEffect, useRef, useState } from "react";
import EditShowRegistrationsContext from "../../../contexts/edit-show-registrations.ctx";
import BaseButton from "../../Base/BaseButton/BaseButton";
import BaseCard from "../../Base/BaseCard/BaseCard";
import BaseIcon from "../../Base/BaseIcon/BaseIcon";
import BaseInput from "../../Base/BaseInput/BaseInput";
import BaseLabel from "../../Base/BaseLabel/BaseLabel";
import BaseToggle from "../../Base/BaseToggle/BaseToggle";
import { v4 as uuidv4 } from "uuid";
import "./RegistrationsCustomConfigurator.css";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

interface ContainerProps {
  gridId: string;
}

const RegistrationsCustomConfigurator: React.FC<ContainerProps> = (props) => {
  const editShowRegistrationsCtx = useContext(EditShowRegistrationsContext);
  const editShowRegistrationsCtxRef = useRef(
    useContext(EditShowRegistrationsContext)
  );

  const [customRegistrations, setCustomRegistrations] = useState(
    [] as {
      id: string;
      name: string;
      required: boolean;
      type: "TEXT" | "NUMBER" | "CHECKBOX";
    }[]
  );

  useEffect(() => {
    setCustomRegistrations(
      editShowRegistrationsCtx.customRegistrationsList as {
        id: string;
        name: string;
        required: boolean;
        type: "TEXT" | "NUMBER" | "CHECKBOX";
      }[]
    );
  }, [editShowRegistrationsCtx.customRegistrationsList]);

  useEffect(() => {
    editShowRegistrationsCtxRef.current.pendingCustomRegistrationsListSetter(
      customRegistrations
    );
  }, [customRegistrations]);

  const createNewRegistration = () => {
    setCustomRegistrations([
      ...customRegistrations,
      {
        id: uuidv4(),
        name: "",
        required: true,
        type: "TEXT"
      }
    ]);
  };

  const removeRegistration = (index: number) => {
    const updatedRegistrations = [...customRegistrations];
    updatedRegistrations.splice(index, 1);
    setCustomRegistrations(updatedRegistrations);
  };

  const registrationNameInputHandler = (value: string, index: number) => {
    const updatedRegistrations = [...customRegistrations];
    updatedRegistrations[index].name = value;
    setCustomRegistrations(updatedRegistrations);
  };

  const registrationRequiredInputHandler = (value: boolean, index: number) => {
    const updatedRegistrations = [...customRegistrations];
    updatedRegistrations[index].required = value;
    setCustomRegistrations(updatedRegistrations);
  };

  const registrationTypeInputHandler = (
    value: "TEXT" | "NUMBER" | "CHECKBOX",
    index: number
  ) => {
    const updatedRegistrations = [...customRegistrations];
    updatedRegistrations[index].type = value;
    setCustomRegistrations(updatedRegistrations);
  };

  return (
    <BaseCard
      id={props.gridId}
      className="registrations-custom-configurator"
      data-testid="registrations-custom-configurator"
    >
      <BaseLabel>Champs personnalisés</BaseLabel>
      <div className="registrations-custom-configurator__new-action">
        <BaseButton
          name="Ajouter un champs"
          onClick={() => createNewRegistration()}
          extended
          color="#848AAE"
        />
      </div>
      <div className="registrations-custom-configurator__configurator">
        {customRegistrations.map((registration, index) => {
          return (
            <div
              key={registration.id}
              className="registrations-custom-configurator__configurator__item"
            >
              <div className="registrations-custom-configurator__configurator__item__header">
                <div className="registrations-custom-configurator__configurator__item__header__input">
                  <BaseInput
                    value={registration.name}
                    onValueChange={(value: string) =>
                      registrationNameInputHandler(value, index)
                    }
                  />
                </div>
                <div className="registrations-custom-configurator__configurator__item__header__required">
                  <span>Obligatoire</span>
                  <BaseToggle
                    value={registration.required}
                    onClick={() =>
                      registrationRequiredInputHandler(
                        !registration.required,
                        index
                      )
                    }
                  />
                </div>
                <div
                  onClick={() => removeRegistration(index)}
                  className="registrations-custom-configurator__configurator__item__header__delete"
                >
                  <BaseIcon color="#CE193D" icon={faTimes as IconProp} />
                </div>
              </div>
              <div className="registrations-custom-configurator__configurator__item__type-selector">
                <div
                  onClick={() => registrationTypeInputHandler("TEXT", index)}
                  className={classNames({
                    "registrations-custom-configurator__configurator__item__type-selector__selector":
                      true,
                    "registrations-custom-configurator__configurator__item__type-selector__selector--active":
                      registration.type === "TEXT"
                  })}
                >
                  Texte
                </div>
                <div
                  onClick={() => registrationTypeInputHandler("NUMBER", index)}
                  className={classNames({
                    "registrations-custom-configurator__configurator__item__type-selector__selector":
                      true,
                    "registrations-custom-configurator__configurator__item__type-selector__selector--active":
                      registration.type === "NUMBER"
                  })}
                >
                  Nombres
                </div>
                <div
                  onClick={() =>
                    registrationTypeInputHandler("CHECKBOX", index)
                  }
                  className={classNames({
                    "registrations-custom-configurator__configurator__item__type-selector__selector":
                      true,
                    "registrations-custom-configurator__configurator__item__type-selector__selector--active":
                      registration.type === "CHECKBOX"
                  })}
                >
                  Checkbox
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </BaseCard>
  );
};

export default RegistrationsCustomConfigurator;
