import React from "react";
import "./BaseDatetimePicker.css";
const DateTimePicker = require("react-datetime-picker").default;

interface ContainerProps {
  value: Date;
  onValueChange: Function;
  label?: string;
}

const BaseDatetimePicker: React.FC<ContainerProps> = (props) => {
  return (
    <div className="base-datetime-picker" data-testid="base-datetime-picker">
      {props.label && <label>{props.label + " :"}</label>}
      <DateTimePicker
        disableCalendar
        disableClock
        clearIcon={null}
        className="base-datetime-picker"
        value={props.value}
        onChange={props.onValueChange}
      />
    </div>
  );
};

export default BaseDatetimePicker;
