import { faEdit, faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";
import React, { useCallback, useContext, useEffect, useState } from "react";
import BaseButton from "../../Base/BaseButton/BaseButton";
import BaseCard from "../../Base/BaseCard/BaseCard";
import BaseLabel from "../../Base/BaseLabel/BaseLabel";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import "./ProjectsConfigurator.css";
import EditShowProjectsContext, {
  ShowProject,
} from "../../../contexts/edit-show-projects.ctx";
import BaseImageInput from "../../Base/BaseImageInput/BaseImageInput";
import BaseInput from "../../Base/BaseInput/BaseInput";
import classNames from "classnames";
import AppContext from "../../../contexts/app.ctx";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import BaseInputColor from "../../Base/BaseInputColor/BaseInputColor";

interface ContainerProps {
  gridId: string;
}

enum ConfiguratorMode {
  "LIST",
  "UPDATE",
  "CREATE"
}

const ProjectsConfigurator: React.FC<ContainerProps> = (props) => {
  const appCtx = useContext(AppContext);
  const editShowProjectsCtx = useContext(EditShowProjectsContext);
  const [configuratorMode, setConfiguratorMode] = useState(
    ConfiguratorMode.LIST
  );

  useEffect(() => {
    setConfiguratorMode(ConfiguratorMode.LIST);
  }, [editShowProjectsCtx.triggerListMode]);

  const onDragEnd = useCallback(
    (result) => {
      if (!result?.destination) {
        return;
      }
      const updatedArray = [...editShowProjectsCtx.projectsList].sort((a, b) =>
        a.position > b.position ? 1 : -1
      );
      const [removed] = updatedArray.splice(result.source.index, 1);
      updatedArray.splice(result.destination.index, 0, removed);
      const updatedList = updatedArray.map((item, index) => {
        item.position = index;
        return item;
      });
      editShowProjectsCtx.projectsListSetter(updatedList);
    },
    [editShowProjectsCtx]
  );

  useEffect(() => {
    editShowProjectsCtx.projectsListSetter(appCtx.currentShowProjects);
  }, [editShowProjectsCtx, appCtx.currentShowProjects]);

  const resetProjectEditorContext = () => {
    editShowProjectsCtx.pendingProjectSetter({} as ShowProject);
    editShowProjectsCtx.pendingProjectNameInputHandler("");
    editShowProjectsCtx.pendingProjectGoalInputHandler("");
    editShowProjectsCtx.pendingProjectPositionSetter(
      editShowProjectsCtx.projectsList.length
    );
    editShowProjectsCtx.pendingProjectLogoUrlSetter("");
    editShowProjectsCtx.pendingProjectPendingLogoFileInputHandler(
      new File([], " ")
    );
    editShowProjectsCtx.pendingProjectTotalPledgesAmountSetter(0);
    editShowProjectsCtx.backgroundColorLogoHandler("");
  };

  const openProjectUpdater = (project: ShowProject) => {
    editShowProjectsCtx.pendingProjectSetter(project);
    editShowProjectsCtx.pendingProjectNameInputHandler(project.name);
    editShowProjectsCtx.pendingProjectGoalInputHandler(
      (project.goal / 100).toString()
    );
    editShowProjectsCtx.pendingProjectPositionSetter(project.position);
    editShowProjectsCtx.pendingProjectLogoUrlSetter(project.logoUrl);
    editShowProjectsCtx.pendingProjectTotalPledgesAmountSetter(
      project.totalPledgesAmount
    );
    editShowProjectsCtx.backgroundColorLogoHandler(project.backgroundColorLogo);
    setConfiguratorMode(ConfiguratorMode.UPDATE);
  };

  const askForProjectDeletion = (project: ShowProject) => {
    const deletionConfirmation = window.confirm(
      "Souhaitez vous réellement supprimer le projet ?"
    );
    if (deletionConfirmation) {
      editShowProjectsCtx.deleteProject(project);
    }
  };

  return (
    <BaseCard
      id={props.gridId}
      className={classNames({
        "projects-configurator": true,
        "projects-configurator--editor":
          configuratorMode === ConfiguratorMode.CREATE ||
          configuratorMode === ConfiguratorMode.UPDATE
      })}
      data-testid="projects-configurator"
    >
      <div className="projects-configurator__header">
        <BaseLabel>
          {configuratorMode === ConfiguratorMode.LIST
            ? "Liste des projets"
            : configuratorMode === ConfiguratorMode.CREATE
            ? "Ajouter projet"
            : "Modifier projet"}
        </BaseLabel>
        {configuratorMode === ConfiguratorMode.LIST && (
          <BaseButton
            mobileCondensed
            name="Ajouter"
            onClick={() => {
              resetProjectEditorContext();
              setConfiguratorMode(ConfiguratorMode.CREATE);
            }}
            icon={faPlus as IconProp}
            color="#848AAE"
          />
        )}
      </div>

      {configuratorMode === ConfiguratorMode.CREATE ||
      configuratorMode === ConfiguratorMode.UPDATE ? (
        <div className="projects-configurator__editor">
          <div
            id="project-logo-editor"
            className="projects-configurator__editor__logo-editor"
          >
            <BaseLabel small>Logo du projet</BaseLabel>
            <BaseImageInput
              presetValue={editShowProjectsCtx.pendingProjectLogoUrl}
              onImageSelected={(file: File) =>
                editShowProjectsCtx.pendingProjectPendingLogoFileInputHandler(
                  file
                )
              }
              value={editShowProjectsCtx.pendingProjectPendingLogoFile}
            />
          </div>
          <div
            id="project-editor-color"
            className="projects-configurator__editor__color"
          >
            <BaseInputColor
              label="Couleur de fond logo"
              value={editShowProjectsCtx.backgroundColorLogo}
              onValueChange={(value: string) =>
                editShowProjectsCtx.backgroundColorLogoHandler(value)
              }
            />
          </div>

          <div
            id="project-editor-form"
            className="projects-configurator__editor__form"
          >
            <div className="projects-configurator__editor__form__input">
              <BaseInput
                onValueChange={(value: string) =>
                  editShowProjectsCtx.pendingProjectNameInputHandler(value)
                }
                value={editShowProjectsCtx.pendingProjectName}
                label="Nom du projet"
              />
            </div>
            <div className="projects-configurator__editor__form__input">
              <BaseInput
                onValueChange={(value: string) => {
                  editShowProjectsCtx.pendingProjectGoalInputHandler(value);
                }}
                value={editShowProjectsCtx.pendingProjectGoal}
                label="Objectif du projet"
                type="number"
              />
            </div>
            <div className="projects-configurator__editor__form__actions">
              <BaseButton
                name="Annuler"
                color="#CE193D"
                onClick={() => {
                  resetProjectEditorContext();
                  setConfiguratorMode(ConfiguratorMode.LIST);
                }}
              />
              <BaseButton
                margin="0 0 0 16px"
                name="Enregistrer"
                color="#2CA795"
                onClick={() => editShowProjectsCtx.submitProjectWrite()}
                isLoading={editShowProjectsCtx.pendingProjectIsLoading}
              />
            </div>
          </div>
        </div>
      ) : (
        <div className="projects-configurator__configurator">
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppable">
              {(provided, snapshot) => (
                <div {...provided.droppableProps} ref={provided.innerRef}>
                  {[...editShowProjectsCtx.projectsList]
                    .sort((a, b) => (a.position > b.position ? 1 : -1))
                    .map((project, index) => {
                      return (
                        <Draggable
                          key={project.id}
                          draggableId={project.id}
                          index={index}
                        >
                          {(provided, snapshot) => (
                            <div
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              className="projects-configurator__configurator__item"
                              key={project.id}
                              ref={provided.innerRef}
                            >
                              <div className="projects-configurator__configurator__item__heading">
                                <div
                                  className="projects-configurator__configurator__item__heading__logo"
                                  style={{
                                    backgroundImage: `url(${project.logoUrl})`
                                  }}
                                ></div>
                                <div className="projects-configurator__configurator__item__heading__infos">
                                  <span>{project.name}</span>
                                  <span>
                                    Objectif :{" "}
                                    <span>
                                      {(project.goal / 100).toLocaleString(
                                        undefined,
                                        { maximumFractionDigits: 0 }
                                      ) +
                                        " " +
                                        appCtx.currentShowCurrencySymbol}
                                    </span>
                                  </span>
                                </div>
                              </div>
                              <div className="projects-configurator__configurator__item__actions">
                                <BaseButton
                                  mobileCondensed
                                  name="Supprimer"
                                  onClick={() => askForProjectDeletion(project)}
                                  icon={faTrash as IconProp}
                                  color="#CE193D"
                                  margin="0 8px 0 0"
                                />
                                <BaseButton
                                  mobileCondensed
                                  name="Modifier"
                                  onClick={() => openProjectUpdater(project)}
                                  icon={faEdit as IconProp}
                                  color="cornflowerblue"
                                />
                              </div>
                            </div>
                          )}
                        </Draggable>
                      );
                    })}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </div>
      )}
    </BaseCard>
  );
};

export default ProjectsConfigurator;
