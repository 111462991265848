import { faTimes } from "@fortawesome/free-solid-svg-icons";
import React, { useContext, useEffect, useMemo, useState } from "react";
import AppContext from "../../../contexts/app.ctx";
import BaseButton from "../../Base/BaseButton/BaseButton";
import BaseCard from "../../Base/BaseCard/BaseCard";
import BaseIcon from "../../Base/BaseIcon/BaseIcon";
import BaseInput from "../../Base/BaseInput/BaseInput";
import BaseLabel from "../../Base/BaseLabel/BaseLabel";
import BaseSelectInput from "../../Base/BaseSelectInput/BaseSelectInput";
import API from "../../../services";
import "./LivePledgesMonitor.css";
import classNames from "classnames";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FixedSizeList as List, ListChildComponentProps } from "react-window";

interface ContainerProps {
  gridId: string;
}

export type ProjectPledge = {
  id: string;
  projectId: string;
  email: string;
  amount: number;
  date: Date;
  isAdmin: false;
  additionalInformations?: Record<string, any>;
};

const LivePledgesMonitor: React.FC<ContainerProps> = (props) => {
  const appCtx = useContext(AppContext);
  const [freeAmount, setFreeAmount] = useState("");
  const [selectedProjectId, setSelectedProjectId] = useState(
      appCtx.currentShow.currentProjectId ? appCtx.currentShow.currentProjectId : "");
  const [pledges, setPledges] = useState([] as ProjectPledge[]);
  // Disabled for perf
  // const [isLoading, setIsLoading] = useState(false);
  const [listHeight, setListHeight] = useState<number>(0);

  // React window requires a numerical value for list
  useEffect(() => {
    const calculateHeight = () => {
      setListHeight(window.innerHeight - 486);
    };
    calculateHeight();
    window.addEventListener("resize", calculateHeight);

    return () => window.removeEventListener("resize", calculateHeight);
  }, []);

  useEffect(() => {
    const listener = API.listenToShowPledges(
      appCtx.currentShow.id,
      (pledges: ProjectPledge[]) => {
        setPledges(pledges);
      }
    );
    return () => listener();
  }, [appCtx.currentShow.id]);

  useEffect(() => {
    if (!appCtx.currentShow.hasNoProjects && !selectedProjectId) {
      setSelectedProjectId(appCtx.currentShowProjects[0]?.id);
    }
  }, [
    appCtx.currentShowProjects,
    appCtx.currentShow.hasNoProjects,
    selectedProjectId,
  ]);

  const filteredAndSortedPledges = useMemo(() => {
    return [...pledges]
      .filter((pledge) => pledge.projectId === selectedProjectId)
      .sort((a, b) => (a.date > b.date ? 1 : -1))
      .reverse();
  }, [pledges, selectedProjectId]);

  const createAdminPledge = (amount: number) => {
    // setIsLoading(true);
    API.createAdminPledge(appCtx.currentShow.id, selectedProjectId, amount)
      // Removed to increase perf
      .then((status) => {
        // appCtx.toggleNotification({
        //   type: "success",
        //   content: "Promesse ajoutée !",
        // });
        // setIsLoading(false);
      })
      .catch((e) => {
        appCtx.toggleNotification({
          type: "error",
          content: "Une erreur est survenue.",
        });
        console.log(`error : amount ${amount}`)
        // setIsLoading(false);
      });
  };

  const askForPledgeDeletion = (pledge: ProjectPledge) => {
    const deletionConfirmation = window.confirm(
      "Souhaitez vous réellement supprimer cette promesse ?"
    );
    if (deletionConfirmation) {
      API.deletePledge(appCtx.currentShow.id, pledge.id);
    }
  };

  const Row = ({ index, style }: ListChildComponentProps) => {
    const pledge = filteredAndSortedPledges[index];
    return (
      <div style={{
        ...style,
        padding: '0 8px 8px 0',
      }}>
        <div
          key={pledge.id}
          className="live-pledges-monitor__pledges-list__item"
        >
          <div className="live-pledges-monitor__pledges-list__item__top">
            <span
              style={{
                color: pledge.isAdmin ? "#2CA795" : undefined,
                fontWeight: pledge.isAdmin ? "bold" : undefined,
              }}
            >
              {pledge.isAdmin ? "Régie" : pledge.email}
            </span>
            <span
              onClick={() => {
                askForPledgeDeletion(pledge);
              }}
              className="live-pledges-monitor__pledges-list__item__top__delete"
            >
              <BaseIcon icon={faTimes as IconProp} color="#CE193D" />
            </span>
          </div>
          <div className="live-pledges-monitor__pledges-list__item__bottom">
            <span>{pledge.date.toLocaleString()}</span>
            <span>
              {(pledge.amount / 100).toLocaleString(undefined, {
                  maximumFractionDigits: 0,
                }) +
                " " +
                appCtx.currentShowCurrencySymbol}
            </span>
          </div>
        </div>
      </div>
    );
  };

  return (
    <BaseCard
      id={props.gridId}
      className="live-pledges-monitor"
      data-testid="live-pledges-monitor"
      tight
    >
      <BaseLabel>Promesses</BaseLabel>
      {!appCtx.currentShow.hasNoProjects && (
        <div className="live-pledges-monitor__project-selector">
          <BaseSelectInput
            label="Projet"
            options={[...appCtx.currentShowProjects].map((project) => {
              return { name: project.name, value: project.id };
            })}
            onChangeValue={(value: string) => setSelectedProjectId(value)}
            value={selectedProjectId}
          />
        </div>
      )}
      <div
        className={classNames({
          "live-pledges-monitor__pledges-list": true,
          "live-pledges-monitor__pledges-list--no-projects":
            appCtx.currentShow.hasNoProjects
        })}
      >
        <List
          height={listHeight}
          itemCount={filteredAndSortedPledges.length}
          itemSize={78}
          width="100%"
        >
          {Row}
        </List>
      </div>
      <div className="live-pledges-monitor__actions">
        <div className="live-pledges-monitor__actions__amounts-grid">
          <BaseButton
            name={
              (appCtx.currentShow.firstPledgeAmount / 100).toLocaleString(
                undefined,
                { maximumFractionDigits: 0 }
              ) +
              " " +
              appCtx.currentShowCurrencySymbol
            }
            onClick={() =>
              createAdminPledge(appCtx.currentShow.firstPledgeAmount)
            }
            color="#848AAE"
            extended
            // isLoading={isLoading}
            disabled={appCtx.currentShow.fourthPledgeAmount === 0}
          />
          <BaseButton
            name={
              (appCtx.currentShow.secondPledgeAmount / 100).toLocaleString(
                undefined,
                { maximumFractionDigits: 0 }
              ) +
              " " +
              appCtx.currentShowCurrencySymbol
            }
            onClick={() =>
              createAdminPledge(appCtx.currentShow.secondPledgeAmount)
            }
            color="#848AAE"
            extended
            // isLoading={isLoading}
            disabled={appCtx.currentShow.fourthPledgeAmount === 0}
          />
          <BaseButton
            name={
              (appCtx.currentShow.thirdPledgeAmount / 100).toLocaleString(
                undefined,
                { maximumFractionDigits: 0 }
              ) +
              " " +
              appCtx.currentShowCurrencySymbol
            }
            onClick={() =>
              createAdminPledge(appCtx.currentShow.thirdPledgeAmount)
            }
            color="#848AAE"
            extended
            // isLoading={isLoading}
            disabled={appCtx.currentShow.fourthPledgeAmount === 0}
          />
          <BaseButton
            name={
              (appCtx.currentShow.fourthPledgeAmount / 100).toLocaleString(
                undefined,
                { maximumFractionDigits: 0 }
              ) +
              " " +
              appCtx.currentShowCurrencySymbol
            }
            onClick={() =>
              createAdminPledge(appCtx.currentShow.fourthPledgeAmount)
            }
            color="#848AAE"
            extended
            // isLoading={isLoading}
            disabled={appCtx.currentShow.fourthPledgeAmount === 0}
          />
        </div>
        <div className="live-pledges-monitor__actions__free-amount">
          <div className="live-pledges-monitor__actions__free-amount__input">
            <BaseInput
              placeholder="Montant libre"
              onValueChange={(value: string) => setFreeAmount(value)}
              value={freeAmount}
              type="number"
            />
          </div>
          <div className="live-pledges-monitor__actions__free-amount__action">
            <BaseButton
              name="Ajouter Libre"
              onClick={
                freeAmount
                  ? () => createAdminPledge(+freeAmount * 100)
                  : () => {}
              }
              color="#848AAE"
              extended
              disabled={!freeAmount}
              // isLoading={isLoading}
            />
          </div>
        </div>
      </div>
    </BaseCard>
  );
};

export default LivePledgesMonitor;
