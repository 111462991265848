import React, {useContext, useEffect, useState} from "react";
import EditMultiplexInfosContext from "../../../contexts/edit-multiplex-infos.ctx";
import BaseCard from "../../Base/BaseCard/BaseCard";
import BaseInput from "../../Base/BaseInput/BaseInput";

import "./MultiplexInfosForm.css"
import BaseSelectInput from "../../Base/BaseSelectInput/BaseSelectInput";
import BaseIcon from "../../Base/BaseIcon/BaseIcon";
import {faMinusCircle} from "@fortawesome/free-solid-svg-icons";
import {IconProp} from "@fortawesome/fontawesome-svg-core";
import BaseLabel from "../../Base/BaseLabel/BaseLabel";
import BaseImageInput from "../../Base/BaseImageInput/BaseImageInput";
import BaseDatetimePicker from "../../Base/BaseDatetimePicker/BaseDatetimePicker";
import {useHistory} from "react-router-dom";
import currencies from "../../../configs/currencies.json";
import BaseInputColor from "../../Base/BaseInputColor/BaseInputColor";
import API from "../../../services";

interface ContainerProps {
  gridId: string;
}

interface ShowBasicInfos {
  id: string
  name: string
  place: string
}

const MultiplexInfosForm: React.FC<ContainerProps> = (props) => {
  const editMultiplexInfosCtx = useContext(EditMultiplexInfosContext);
  const history = useHistory();
  const [showsList, setShowsList] = useState([] as ShowBasicInfos[])

  useEffect(() => {
    API.getAllShowsBasicInfos(
      (shows: ShowBasicInfos[]) => {
        setShowsList(shows)
      })
  }, []);

  const handleRemoveEvent = (targetId: string) => {
    editMultiplexInfosCtx.removedShowsInputHandler(
      [...editMultiplexInfosCtx.removedShows, targetId]
    )
    editMultiplexInfosCtx.participatingShowsInputHandler(
      editMultiplexInfosCtx.participatingShows.filter(item => item !== targetId)
    );
  };

  return (
    <>
      <BaseCard
        id={props.gridId}
        className="multiplex-infos-form"
      >
        <BaseInput
          label="Nom du multiplex"
          value={editMultiplexInfosCtx.name}
          onValueChange={(value: string) =>
            editMultiplexInfosCtx.nameInputHandler(value)
          }
        />
        <BaseInput
          label="Sous-titre"
          value={editMultiplexInfosCtx.subtitle}
          onValueChange={(value: string) =>
            editMultiplexInfosCtx.subtitleInputHandler(value.slice(0, 50))
          }
          placeholder="Maximum 50 caractères"
        />
        <BaseDatetimePicker
          label="Date et heure de début"
          value={editMultiplexInfosCtx.startDate}
          onValueChange={(value: Date) =>
            editMultiplexInfosCtx.startDateInputHandler(value)
          }
        />
        <BaseSelectInput
          onChangeValue={editMultiplexInfosCtx.pledgesCurrencyInputHandler}
          value={editMultiplexInfosCtx.pledgesCurrency}
          label="Devise"
          options={[...currencies].map((c) => {
            return {
              name: c.name + " - " + c.displayName,
              value: c.value,
            };
          })}
        />
      </BaseCard>
      <BaseCard
        className="multiplex-logo-selector"
      >
        <BaseLabel>Logo du Multiplex</BaseLabel>
        <BaseImageInput
          presetValue={editMultiplexInfosCtx.existingLogoUrl}
          value={editMultiplexInfosCtx.pendingLogoFile}
          onImageSelected={editMultiplexInfosCtx.pendingLogoFileInputHandler}
          onImageDelete={() => editMultiplexInfosCtx.existingLogoUrlSetter("")}
        />
      </BaseCard>
      <BaseCard>
        <BaseSelectInput
          label="Evènements"
          value={""}
          onChangeValue={(v: string) => {
            if (!editMultiplexInfosCtx.participatingShows.includes(v)) {
              editMultiplexInfosCtx.participatingShowsInputHandler([...editMultiplexInfosCtx.participatingShows, v])
            }
          }}
          options={showsList.filter(show =>
            editMultiplexInfosCtx.participatingShows.indexOf(show.id) === -1).map(show => {
            return {name: `[ ${show.place} ] ${show.name}`, value: show.id}
          })}
          default=" -- sélectionez un évènement pour l'ajouter -- "
        />
        <div className="multiplex-shows-list">
          {editMultiplexInfosCtx.participatingShows.map(eventId => {
            const target = showsList.find(o => o.id === eventId)
            if (target) {
              return (
                <div key={eventId} className="multiplex-shows__event" >
                  <button className="multiplex-shows__event__remove"
                          onClick={() => handleRemoveEvent(eventId)}
                  >
                    <BaseIcon icon={faMinusCircle as IconProp} size={"20px"} />
                  </button>
                  <span
                    className="multiplex-shows__event__link"
                    onClick={() => history.push(`/${target.id}/edit-show-infos`)}
                  >
                    <b>[ {target.place} ]</b> {target.name}
                  </span>
                </div>
              )
            }
            return <></>
          })}
        </div>
      </BaseCard>
      <BaseCard className="multiplex-color-picker" >
        <BaseInputColor
          label="Couleur gradient exterieur"
          value={editMultiplexInfosCtx.backgroundColorDark}
          onValueChange={(value: string) =>
            editMultiplexInfosCtx.backgroundColorDarkHandler(value)
          }
        />
        <BaseInputColor
          label="Couleur gradient interieur"
          value={editMultiplexInfosCtx.backgroundColorLight}
          onValueChange={(value: string) =>
            editMultiplexInfosCtx.backgroundColorLightHandler(value)
          }
        />
        <BaseInputColor
          label="Couleur de texte"
          value={editMultiplexInfosCtx.fontColor}
          onValueChange={(value: string) =>
            editMultiplexInfosCtx.fontColorHandler(value)
          }
        />
        <BaseInputColor
          label="Couleur de texte accentué"
          value={editMultiplexInfosCtx.fontColorHighlight}
          onValueChange={(value: string) =>
            editMultiplexInfosCtx.fontColorHighlightHandler(value)
          }
        />
      </BaseCard>
    </>
  );
};

export default MultiplexInfosForm;